import Modal from "react-modal";
import { Formik, Form, Field } from "formik";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { base_url } from "../api/Axios";
import axios from "axios";
import CryptoJS from "crypto-js";

import { registorCompany } from "../utils/Validation";
import { useNavigate } from "react-router-dom";
import Logout from "../pages/Logout";
const customStyles = {
	overlay: {
		backgroundColor: "rgba(0, 0, 0, 0.5)",
	},
	content: {
		top: "52%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "20px",
		borderRadius: "8px",
		boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
		maxWidth: "1400px",
		width: "100%",
		maxHeight: "90vh",
		overflowY: "auto",
	},
	closeButton: {
		position: "absolute",
		top: "5px",
		right: "2px",
		background: "none",
		border: "none",
		cursor: "pointer",
		fontSize: "15px",
		// color: "red",
	},
};

const legal = [
	{
		value: 1,
		display_name: "Private",
	},
	{
		value: 2,
		display_name: "Private Limited Company",
	},
	{
		value: 3,
		display_name: "Share Company",
	},
	{
		value: 4,
		display_name: "Commercial Representative",
	},
	{
		value: 5,
		display_name: "Public Enterprise",
	},
	{
		value: 6,
		display_name: "Partnership",
	},
	{
		value: 7,
		display_name: "Cooperatives Association",
	},
	{
		value: 9,
		display_name: "Trade Sectoral Association",
	},
	{
		value: 10,
		display_name: "Non-Public Enterprise",
	},
	{
		value: 11,
		display_name: "NGO",
	},
	{
		value: 12,
		display_name: "Branch of A Foreign Chamber of Commerce",
	},
	{
		value: 13,
		display_name: "Holding Company",
	},
	{
		value: 14,
		display_name: "Franchising",
	},
	{
		value: 15,
		display_name: "Border Trade",
	},
	{
		value: 19,
		display_name: "International Bid Winners Foreign Companies",
	},
	{
		value: 21,
		display_name: "One Man Private Limited Company",
	},
];
const zones = [
	{
		value: 2,
		display_name: "Addis Ketema, Addis Abeba",
	},
	{
		value: 5,
		display_name: "Akaky Kaliti, Addis Abeba",
	},
	{
		value: 1,
		display_name: "Arada, Addis Abeba",
	},
	{
		value: 3,
		display_name: "Bole, Addis Abeba",
	},
	{
		value: 6,
		display_name: "Gullele, Addis Abeba",
	},
	{
		value: 7,
		display_name: "Kirkos, Addis Abeba",
	},
	{
		value: 8,
		display_name: "Kolfe Keranio, Addis Abeba",
	},
	{
		value: 11,
		display_name: "Lemi Kura, Addis Abeba",
	},
	{
		value: 9,
		display_name: "Lideta, Addis Abeba",
	},
	{
		value: 4,
		display_name: "Nifas Silk Lafto, Addis Abeba",
	},
	{
		value: 10,
		display_name: "Yeka, Addis Abeba",
	},
	{
		value: 75,
		display_name: "Dire Dawa City Administration, Dire Dawa",
	},
	{
		value: 12,
		display_name: "Central Tigray, Tigray",
	},
	{
		value: 14,
		display_name: "Eastern Tigray, Tigray",
	},
	{
		value: 18,
		display_name: "Mekelle City, Tigray",
	},
	{
		value: 15,
		display_name: "North Western Tigray, Tigray",
	},
	{
		value: 13,
		display_name: "South Eastern Tigray, Tigray",
	},
	{
		value: 17,
		display_name: "Southern Tigray, Tigray",
	},
	{
		value: 16,
		display_name: "Western Tigray, Tigray",
	},
	{
		value: 43,
		display_name: "Agew Awi Zone, Amhara",
	},
	{
		value: 52,
		display_name: "Bahir Dar Special Zone, Amhara",
	},
	{
		value: 76,
		display_name: "Central Gondar Zone, Amhara",
	},
	{
		value: 53,
		display_name: "Defunct Districts, Amhara",
	},
	{
		value: 44,
		display_name: "East Gojjam Zone, Amhara",
	},
	{
		value: 45,
		display_name: "North Gondar Zone, Amhara",
	},
	{
		value: 46,
		display_name: "North Shewa Zone, Amhara",
	},
	{
		value: 47,
		display_name: "North Wollo Zone, Amhara",
	},
	{
		value: 79,
		display_name: "Oromia Zone, Amhara",
	},
	{
		value: 49,
		display_name: "South Gondar Zone, Amhara",
	},
	{
		value: 48,
		display_name: "South Wollo Zone, Amhara",
	},
	{
		value: 50,
		display_name: "Wag Hemra Zone, Amhara",
	},
	{
		value: 51,
		display_name: "West Gojjam Zone, Amhara",
	},
	{
		value: 78,
		display_name: "West Gondar Zone, Amhara",
	},
	{
		value: 19,
		display_name: "Zone 1 (Awsi Rasu), Afar",
	},
	{
		value: 20,
		display_name: "Zone 2 (Kilbet Rasu), Afar",
	},
	{
		value: 21,
		display_name: "Zone 3 (Gabi Rasu), Afar",
	},
	{
		value: 22,
		display_name: "Zone 4 (Fantena Rasu), Afar",
	},
	{
		value: 23,
		display_name: "Zone 5 (Hari Rasu), Afar",
	},
	{
		value: 80,
		display_name: "Zone 6 (Mahi Rasu), Afar",
	},
	{
		value: 82,
		display_name: "Zone 7 (Argobba, special woreda), Afar",
	},
	{
		value: 70,
		display_name: "Adama City Administration, Oromia",
	},
	{
		value: 54,
		display_name: "Arsi Zone, Oromia",
	},
	{
		value: 55,
		display_name: "Bale Zone, Oromia",
	},
	{
		value: 56,
		display_name: "Borena Zone, Oromia",
	},
	{
		value: 89,
		display_name: "Buno Bedele Zone, Oromia",
	},
	{
		value: 73,
		display_name: "Defunct Districts, Oromia",
	},
	{
		value: 83,
		display_name: "East Arsi Zone, Oromia",
	},
	{
		value: 84,
		display_name: "East Bale Zone, Oromia",
	},
	{
		value: 86,
		display_name: "East Guji zone, Oromia",
	},
	{
		value: 57,
		display_name: "East Hararghe Zone, Oromia",
	},
	{
		value: 58,
		display_name: "East Shewa Zone, Oromia",
	},
	{
		value: 59,
		display_name: "East Wollega Zone, Oromia",
	},
	{
		value: 60,
		display_name: "Guji Zone, Oromia",
	},
	{
		value: 61,
		display_name: "Horo Guduru Welega Zone, Oromia",
	},
	{
		value: 88,
		display_name: "Illu ababora zone, Oromia",
	},
	{
		value: 71,
		display_name: "Jimma City Administration, Oromia",
	},
	{
		value: 62,
		display_name: "Jimma Zone, Oromia",
	},
	{
		value: 63,
		display_name: "Kelam Welega Zone, Oromia",
	},
	{
		value: 64,
		display_name: "North Shewa Zone, Oromia",
	},
	{
		value: 72,
		display_name: "Oromia Special Zone Surrounding Finfinne, Oromia",
	},
	{
		value: 65,
		display_name: "South West Shewa Zone, Oromia",
	},
	{
		value: 66,
		display_name: "West Arsi Zone, Oromia",
	},
	{
		value: 85,
		display_name: "West Bale zone, Oromia",
	},
	{
		value: 87,
		display_name: "West Guji, Oromia",
	},
	{
		value: 67,
		display_name: "West Hararghe Zone, Oromia",
	},
	{
		value: 68,
		display_name: "West Shewa Zone, Oromia",
	},
	{
		value: 69,
		display_name: "West Welega Zone, Oromia",
	},
	{
		value: 38,
		display_name: "Asosa Zone, Benishangul-Gumuz",
	},
	{
		value: 39,
		display_name: "Kamashi Zone, Benishangul-Gumuz",
	},
	{
		value: 41,
		display_name: "Mao-Komo (Special Woreda), Benishangul-Gumuz",
	},
	{
		value: 40,
		display_name: "Metekel Zone, Benishangul-Gumuz",
	},
	{
		value: 42,
		display_name: "Pawe  (Special Woreda), Benishangul-Gumuz",
	},
	{
		value: 24,
		display_name: "Anuak Zone, Gambela",
	},
	{
		value: 27,
		display_name: "Itang (Special Woreda), Gambela",
	},
	{
		value: 26,
		display_name: "Mezhenger, Gambela",
	},
	{
		value: 25,
		display_name: "Nuer Zone, Gambela",
	},
	{
		value: 74,
		display_name: "Harari, Harari",
	},
	{
		value: 118,
		display_name: "Aleta Chuko Zone, Sidama",
	},
	{
		value: 119,
		display_name: "Aleta Wendo Zone, Sidama",
	},
	{
		value: 120,
		display_name: "Arbegona Zone, Sidama",
	},
	{
		value: 121,
		display_name: "Aroresa Zone, Sidama",
	},
	{
		value: 123,
		display_name: "Bensa Zone, Sidama",
	},
	{
		value: 124,
		display_name: "Bona Zuria Zone, Sidama",
	},
	{
		value: 125,
		display_name: "Boricha Zone, Sidama",
	},
	{
		value: 126,
		display_name: "Bursa Zone, Sidama",
	},
	{
		value: 127,
		display_name: "Chere Zone, Sidama",
	},
	{
		value: 128,
		display_name: "Dale Zone, Sidama",
	},
	{
		value: 129,
		display_name: "Dara Zone, Sidama",
	},
	{
		value: 130,
		display_name: "Gorche Zone, Sidama",
	},
	{
		value: 131,
		display_name: "Hawassa city, Sidama",
	},
	{
		value: 122,
		display_name: "Hawassa Zuria Zone, Sidama",
	},
	{
		value: 132,
		display_name: "Hula Zone, Sidama",
	},
	{
		value: 133,
		display_name: "Loko Abaya zone, Sidama",
	},
	{
		value: 134,
		display_name: "Malga zone, Sidama",
	},
	{
		value: 135,
		display_name: "Shebedino zone, Sidama",
	},
	{
		value: 136,
		display_name: "Wensho zone, Sidama",
	},
	{
		value: 137,
		display_name: "Wondo Genet zone, Sidama",
	},
	{
		value: 112,
		display_name: "Bench Maji Zone, South West Ethiopia Peoples",
	},
	{
		value: 113,
		display_name:
			"Dawro (formerly part of North Omo Zone), South West Ethiopia Peoples",
	},
	{
		value: 114,
		display_name:
			"Keffa (formerly part of Keficho Shekicho Zone), South West Ethiopia Peoples",
	},
	{
		value: 116,
		display_name:
			"Konta (special woreda, formerly part of North Omo Zone), South West Ethiopia Peoples",
	},
	{
		value: 115,
		display_name:
			"Sheka (formerly part of Keficho Shekicho Zone), South West Ethiopia Peoples",
	},
	{
		value: 117,
		display_name: "West Omo Zone, South West Ethiopia Peoples",
	},
	{
		value: 106,
		display_name: "Alaba Zone, Southern Nations, Nationalities, and Peoples",
	},
	{
		value: 107,
		display_name:
			"Amaro (special woreda), Southern Nations, Nationalities, and Peoples",
	},
	{
		value: 108,
		display_name:
			"Basketo (special woreda, formerly part of North Omo Zone), Southern Nations, Nationalities, and Peoples",
	},
	{
		value: 109,
		display_name:
			"Burji (special woreda), Southern Nations, Nationalities, and Peoples",
	},
	{
		value: 110,
		display_name:
			"Dirashe (special woreda), Southern Nations, Nationalities, and Peoples",
	},
	{
		value: 97,
		display_name:
			"Gamo Gofa Zone, Southern Nations, Nationalities, and Peoples",
	},
	{
		value: 98,
		display_name: "Gedeo Zone, Southern Nations, Nationalities, and Peoples",
	},
	{
		value: 99,
		display_name: "Gurage Zone, Southern Nations, Nationalities, and Peoples",
	},
	{
		value: 100,
		display_name: "Hadiya Zone, Southern Nations, Nationalities, and Peoples",
	},
	{
		value: 101,
		display_name:
			"Kembata Tembaro zone, Southern Nations, Nationalities, and Peoples",
	},
	{
		value: 104,
		display_name: "Konso Zone, Southern Nations, Nationalities, and Peoples",
	},
	{
		value: 102,
		display_name: "Silt'e Zone, Southern Nations, Nationalities, and Peoples",
	},
	{
		value: 103,
		display_name:
			"South Omo zone, Southern Nations, Nationalities, and Peoples",
	},
	{
		value: 105,
		display_name:
			"Wolayita (formerly part of North Omo Zone), Southern Nations, Nationalities, and Peoples",
	},
	{
		value: 111,
		display_name:
			"Yem (special woreda), Southern Nations, Nationalities, and Peoples",
	},
	{
		value: 29,
		display_name: "Afder Zone, Somali",
	},
	{
		value: 93,
		display_name: "Degehabur Special Zone, Somali",
	},
	{
		value: 90,
		display_name: "Dhawa Zone, Somali",
	},
	{
		value: 37,
		display_name: "Dollo Zone, Somali",
	},
	{
		value: 32,
		display_name: "Erer Zone, Somali",
	},
	{
		value: 34,
		display_name: "Faafan Zone, Somali",
	},
	{
		value: 94,
		display_name: "Gode Special Zone, Somali",
	},
	{
		value: 30,
		display_name: "Jarar Zone, Somali",
	},
	{
		value: 91,
		display_name: "Jigjiga Special Zone, Somali",
	},
	{
		value: 95,
		display_name: "Kebri Beyah Special Zone, Somali",
	},
	{
		value: 96,
		display_name: "Kebri Dahar Special Zone, Somali",
	},
	{
		value: 35,
		display_name: "Korahe Zone, Somali",
	},
	{
		value: 28,
		display_name: "liben zone (Lahey), Somali",
	},
	{
		value: 31,
		display_name: "Nogob Zone, Somali",
	},
	{
		value: 33,
		display_name: "Shabelle Zone, Somali",
	},
	{
		value: 36,
		display_name: "Sitti Zone, Somali",
	},
	{
		value: 92,
		display_name: "Tog Wajale Special Zone, Somali",
	},
];
const region = [
	{
		value: "AA",
		display_name: "Addis Ababa",
	},
	{
		value: "AF",
		display_name: "Afar Region",
	},
	{
		value: "AM",
		display_name: "Amhara Region",
	},
	{
		value: "BG",
		display_name: "Benishangul-Gumuz Region",
	},
	{
		value: "DR",
		display_name: "Dire Dawa",
	},
	{
		value: "GM",
		display_name: "Gambela Region",
	},
	{
		value: "HR",
		display_name: "Harari Region",
	},
	{
		value: "OR",
		display_name: "Oromia Region",
	},
	{
		value: "SD",
		display_name: "Sidama Region",
	},
	{
		value: "SM",
		display_name: "Somali Region",
	},
	{
		value: "SW",
		display_name: "South West Ethiopia Peoples' Region",
	},
	{
		value: "SN",
		display_name: "Southern Nations, Nationalities, and Peoples' Region",
	},
	{
		value: "TG",
		display_name: "Tigray Region",
	},
];

export const LendingModal = ({
	isOpen,
	closeModal,
	role,
	type,
	url,
	reviewData,
}) => {
	const cookies = new Cookies();

	const navigate = useNavigate();
	const token = cookies.get("token");
	const [status, setStatus] = useState();
	const [activate, setActivation] = useState();
	const [error, setError] = useState();
	const [success, setSuccess] = useState();
	const [detailData, setDetailData] = useState();
	const [closeModalAfterSubmit, setCloseModalAfterSubmit] = useState(false);
	const updatedUrl = url?.replace("http://", "https://");
	const parts = url?.split("/");
	const subUrl = parts?.slice(-3).join("/");
	const handleRegistration = async (value) => {
		console.log(value);
		try {
			const result = await axios.post(`${updatedUrl}`, value, {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
			});

			console.log(result);
			setSuccess("Thank You,You have Registered Successfully!");
			setTimeout(() => {
				setSuccess("");
				closeModal();
			}, 2000);
			setTimeout(() => {
				setDetailData("");
			}, 3000);
		} catch (error) {
			console.log(error);
			if (error?.response) {
				if (error?.response?.status === 401) {
					Logout();
					setError("Session expired. Please login again");
					setTimeout(() => {
						setError("");
					}, 2000);
					navigate("/login");
				} else {
					console.log("Error from catch", error);

					// Iterate over keys in 'data' to dynamically check for errors
					for (const field in error.response.data) {
						if (error.response.data.hasOwnProperty(field)) {
							const fieldErrors = error.response.data[field];
							if (Array.isArray(fieldErrors) && fieldErrors.length > 0) {
								setError(`${field} Error: ${fieldErrors[0]}`);
								setTimeout(() => {
									setError("");
								}, 2000);
								// Assuming you only want to display the first error for the first field with an error
								break;
							} else {
								setError("Please enter form values properly");
								setTimeout(() => {
									setError("");
								}, 2000);
							}
						}
					}
				}
			} else {
				if (error?.response?.status === 401) {
					Logout();
					setError(error?.response?.data[0]);
					setTimeout(() => {
						setError("");
					}, 2000);
					navigate("/login");
				}
			}
		}
	};

	const handleActivation = async (value) => {
		const result = await axios
			.post(`${base_url}${subUrl}authorize/`, value, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				console.log(response);
				setSuccess(
					response?.data?.status === "ACTIVE"
						? "It has been approved"
						: "It has been declined"
				);
				setTimeout(() => {
					window.location.reload();
					closeModal();
					setSuccess("");
				}, 2000);
			})
			.catch((error) => {
				if (error?.response?.status === 401) {
					Logout();
					setError(error?.response?.data[0]);
					setTimeout(() => {
						setError("");
					}, 2000);
					navigate("/login");
				}
				console.log(error);
				setError(error?.response?.data[0]);
				setTimeout(() => {
					setError("");
				}, 2000);
			});

		setActivation(value);
		console.log(value);
	};

	const handleModal = () => {
		setCloseModalAfterSubmit(true);
	};
	return (
		<Modal
			style={{ ...customStyles, marginTop: "50px" }}
			isOpen={isOpen}
			onRequestClose={closeModal}
			enter="ease-out duration-300"
			enterFrom="opacity-0 scale-95"
			enterTo="opacity-100 scale-100"
			leave="ease-in duration-200"
			leaveFrom="opacity-100 scale-100"
			leaveTo="opacity-0 scale-95"
		>
			<button style={customStyles.closeButton} onClick={closeModal}>
				<i className="x large icon" />
			</button>
			{type === "EMPLOYEE" ? (
				<>
					<h2 className="bg-kacha-orange-700 text-white p-4">
						Employee Details
					</h2>

					<div className="flex justify-between">
						<div className="flex flex-col text-left w-1/2 pr-4">
							<div className="pb-2">
								<span className="font-bold ">Full Name:</span>{" "}
								{reviewData?.first_name &&
									reviewData?.first_name.charAt(0).toUpperCase() +
										reviewData?.first_name.slice(1).toLowerCase()}{" "}
								{reviewData?.middle_name &&
									reviewData?.middle_name.charAt(0).toUpperCase() +
										reviewData?.middle_name.slice(1).toLowerCase()}{" "}
								{reviewData?.last_name &&
									reviewData?.last_name.charAt(0).toUpperCase() +
										reviewData?.last_name.slice(1).toLowerCase()}
							</div>

							<div className="pb-2 ">
								<span className="font-bold">Marital Status:</span>{" "}
								{reviewData?.martial_status &&
									(reviewData?.martial_status === "NOT_MARRIED"
										? "Single"
										: reviewData?.martial_status.charAt(0).toUpperCase() +
										  reviewData?.martial_status.slice(1).toLowerCase())}
							</div>
							<div className="pb-2">
								<span className="font-bold">Gender:</span>{" "}
								{reviewData?.gender &&
									reviewData?.gender.charAt(0).toUpperCase() +
										reviewData?.gender.slice(1).toLowerCase()}
							</div>

							<div className="pb-2 ">
								<span className="font-bold">Phone Number:</span> +
								{reviewData?.phone}
							</div>
							<div className="pb-2 ">
								<span className="font-bold">Employer ID:</span>{" "}
								{reviewData?.employer}
							</div>
							<div className="pb-2 ">
								<span className="font-bold"> Date of Employment:</span>{" "}
								{reviewData?.date_of_employment}
							</div>
						</div>
						<div className="flex flex-col text-left w-1/2 pl-4">
							<div className="pb-2 ">
								<span className="font-bold">Basic Salary:</span>{" "}
								{Number(reviewData?.gross_salary).toLocaleString("en-US", {
									style: "currency",
									currency: "ETB",
								})}
							</div>
							<div className="pb-2">
								<span className="font-bold">Net Salary:</span>{" "}
								{Number(reviewData?.net_salary).toLocaleString("en-US", {
									style: "currency",
									currency: "ETB",
								})}
							</div>
							<div className="pb-2">
								<span className="font-bold">Salary Account:</span>{" "}
								{reviewData?.account_number}
							</div>
							<div className="pb-2">
								<span className="font-bold">Position:</span>{" "}
								{reviewData?.position &&
									reviewData.position.charAt(0).toUpperCase() +
										reviewData.position.slice(1).toLowerCase()}
							</div>
							<div className="pb-2">
								<span className="font-bold">Total Work Experience:</span>{" "}
								{reviewData?.total_work_experience}
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<h2 className="bg-kacha-orange-700 p-4 flex justify-center">
						Company Details
					</h2>

					<div className="flex justify-between">
						<div className="flex flex-col text-left w-1/3 pr-4">
							<div className="flex flex-col text-left">
								<div className="pb-2">
									<span className="font-bold">TIN:</span>{" "}
									{reviewData?.tin_value}
								</div>
								<div className="pb-2">
									<span className="font-bold">Trade Name:</span>{" "}
									{reviewData?.name}
								</div>
								<div className="pb-2">
									<span className="font-bold">Mobile Phone:</span> +
									{reviewData?.phone}
								</div>

								<div className="pb-2">
									<span className="font-bold">Signed Contract:</span>{" "}
									{role === "CHECKER" && (
										<>
											{reviewData?.contract ? (
												<a
													href={reviewData.contract}
													target="_blank"
													rel="noopener noreferrer"
													className="text-kacha-orange-700"
												>
													Contract Image
												</a>
											) : (
												<div className="text-kacha-orange-700">
													Image not uploaded
												</div>
											)}
										</>
									)}
									{role === "MAKER" && reviewData?.contract?.name}
								</div>
								{/* <div>
                  <span className="font-bold">Has Supplier:</span>{" "}
                  {reviewData?.has_supplier.toString()}
                </div>
                <div>
                  <span className="font-bold">Is Supplier:</span>{" "}
                  {reviewData?.is_supplier.toString()}
                </div> */}

								<div className="pb-2">
									<span className="font-bold">Company Type:</span>{" "}
									{
										reviewData?.legal_condition &&
											legal.find(
												(item) =>
													item.value.toString() ===
													reviewData?.legal_condition.toString()
											)?.display_name
										// reviewData?.legal_condition?.charAt(0)?.toUpperCase() +
										// 	reviewData?.legal_condition?.slice(1)?.toLowerCase()
									}
								</div>
							</div>
						</div>
						<div className="flex flex-col text-left w-1/3 pl-4">
							<div className="flex flex-col text-left">
								<div className="pb-2">
									<span className="font-bold">Business License Document:</span>{" "}
									{role === "CHECKER" && (
										<>
											{reviewData?.license ? (
												<a
													href={reviewData.license}
													target="_blank"
													rel="noopener noreferrer"
													className="text-kacha-orange-700"
												>
													License Image
												</a>
											) : (
												<div className="text-kacha-orange-700">
													Image not uploaded
												</div>
											)}
										</>
									)}
									{role === "MAKER" && reviewData?.license?.name}
								</div>
								<div className="pb-2">
									<span className="font-bold">Capital:</span>{" "}
									{reviewData?.paid_up_capital &&
										Number(reviewData.paid_up_capital)?.toLocaleString(
											"en-US",
											{
												style: "currency",
												currency: "ETB",
											}
										)}
								</div>
								<div className="pb-2">
									<span className="font-bold">Region:</span>{" "}
									{
										reviewData?.region &&
											region.find(
												(item) =>
													item.value.toString() ===
													reviewData?.region.toString()
											)?.display_name
										// reviewData?.region
										// 	.split(" ")
										// 	.map(
										// 		(word) =>
										// 			word?.charAt(0)?.toUpperCase() +
										// 			word?.slice(1)?.toLowerCase()
										// 	)
										// 	.join(" ")}
									}
								</div>

								<div className="pb-2">
									<span className="font-bold">TIN Certificate:</span>{" "}
									{role === "CHECKER" && (
										<>
											{reviewData?.tin ? (
												<a
													href={reviewData.tin}
													target="_blank"
													rel="noopener noreferrer"
													className="text-kacha-orange-700"
												>
													TIN Image
												</a>
											) : (
												<div className="text-kacha-orange-700">
													Image not uploaded
												</div>
											)}
										</>
									)}
									{role === "MAKER" && reviewData?.tin?.name}
								</div>
								<div className="pb-2">
									<span className="font-bold">Zone:</span>{" "}
									{
										reviewData?.zone &&
											zones.find(
												(item) =>
													item.value.toString() === reviewData.zone.toString()
											)?.display_name
										// reviewData?.zone
										// 	.split(" ")
										// 	.map(
										// 		(word) =>
										// 			word?.charAt(0)?.toUpperCase() +
										// 			word?.slice(1)?.toLowerCase()
										// 	)
										// 	.join(" ")}
									}
								</div>
								<div className="pb-2">
									<span className="font-bold">business License No.:</span>{" "}
									{reviewData?.license_number}
								</div>
							</div>
						</div>
						<div className="flex flex-col text-left w-1/3 pl-4">
							<div className="flex flex-col text-left">
								<div className="pb-2">
									<span className="font-bold">Sectors:</span>{" "}
									{reviewData?.sectors
										.split(" ")
										.map(
											(word) =>
												word?.charAt(0)?.toUpperCase() +
												word?.slice(1)?.toLowerCase()
										)
										.join(" ")}
								</div>
								<div className="pb-2">
									<span className="font-bold">
										Salary Paying Account Number:
									</span>{" "}
									{reviewData?.account_number}
								</div>
								<div className="pb-2">
									<span className="font-bold">Pay Day:</span>{" "}
									{reviewData?.payday}
								</div>
								<div className="pb-2">
									<span className="font-bold">Renewal Status:</span>{" "}
									{reviewData?.renewal_status?.charAt(0).toUpperCase() +
										reviewData?.renewal_status?.slice(1)?.toLowerCase()}
								</div>
								<div className="pb-2">
									<span className="font-bold"> Date of Issuance:</span>{" "}
									{reviewData?.date_registered}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			<div className="flex flex-col align-center text-center">
				{role === "CHECKER" && (
					<Formik
						initialValues={{ status: reviewData?.status, remark: "" }}
						onSubmit={(values) => {
							handleActivation(values);
						}}
					>
						{(formik) => (
							<Form className="flex flex-col">
								<div className="mt-5 flex flex-col justify-center items-center">
									<label htmlFor="remark">Remark</label>
									<Field
										as="textarea"
										id="remark"
										name="remark"
										className="border border-gray-300 w-[360px] p-3 rounded-md text-kacha-text9"
									/>
									{formik.errors.remark && formik.touched.remark && (
										<div className="error text-red-500">
											{formik.errors.remark}
										</div>
									)}
								</div>
								{formik.errors.status && formik.touched.status && (
									<div className="error text-red-500">
										{formik.errors.status}
									</div>
								)}
								<div>
									{success && (
										<div className="text-center text-kacha-text7 p-2  z-50 bg-white">
											<p className="text-green-700">{success}</p>
										</div>
									)}
								</div>
								<div className="py-8 flex flex-col align-center justify-center items-center">
									<div className="flex mt-2">
										<button
											type="submit"
											className={`mr-8 border border-gray-300 p-3 rounded-md text-kacha-text9 w-[150px] ${
												formik.values.status === "ACTIVE"
													? "text-green-700"
													: ""
											}`}
											onClick={() => formik.setFieldValue("status", "ACTIVE")}
										>
											<span className="text-green-700"> ✔</span>Approve
										</button>
										<button
											type="submit"
											className={`border border-gray-300 p-3 rounded-md text-kacha-text9 w-[150px] ${
												formik.values.status === "DISAPPROVED"
													? " text-red-500"
													: ""
											}`}
											onClick={() =>
												formik.setFieldValue("status", "DISAPPROVED")
											}
											disabled={
												!!formik.errors.remark ||
												Object.keys(formik.touched).length <= 0
											}
										>
											❌ Decline
										</button>
									</div>

									{/* {formik.status && (
										<div
											className={`text-${formik.status.type}-500 text-center text-kacha-text7 p-2 z-50 bg-white`}
										>
											{formik.status.message}
										</div>
									)} */}
								</div>
								<div className="mt-5" />
								{/* <div
						  className="bg-kacha-orange-700 text-center cursor-pointer p-3 w-[300px] rounded-md text-lg mx-auto"
						  type="submit"
						>
						  <button
							className="text-center cursor-pointer text-kacha-text8 
							type="submit"
						  >
							Change Status
						  </button>
						</div> */}
							</Form>
						)}
					</Formik>
				)}
			</div>
			{role === "MAKER" && (
				<>
					<div className="mt-5" />
					{success && (
						<div className="text-green-700 text-center text-kacha-text7 p-2  z-50 bg-white">
							{success}
						</div>
					)}
					{error && (
						<div className="text-red-500 text-center text-kacha-text7 p-2  z-50 bg-white">
							Please Try Again. {error} !!
						</div>
					)}
					<div className="mt-5" />
					<div
						className="bg-kacha-orange-700 flex items-center justify-center p-3 max-w-[400px] rounded-md text-lg cursor-pointer mx-auto"
						type="submit"
					>
						<button
							onClick={() => handleRegistration(reviewData)}
							className="text-center cursor-pointer text-kacha-text8 text-white"
							type="submit"
						>
							Submit for Approval
						</button>
					</div>
				</>
			)}
		</Modal>
	);
};
export default LendingModal;
