import React, { createContext, useContext, useState } from "react";

// Create the context
const AuthContext = createContext();

// Create the provider
const AuthProvider = ({ children }) => {
	const [authUser, setAuthUser] = useState({
		branch: "",
		first_name: "",
		group: "",
		last_name: "",
		password_reset_required: false,
		phone: "",
		role: "",
		status: "",
		username: "",
	});
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const value = { authUser, setAuthUser, isLoggedIn, setIsLoggedIn };

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Create the custom hook
const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};

export { AuthProvider, useAuth };
