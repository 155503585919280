import axios from "axios";
import Cookies from "universal-cookie";
import React, { useState, useEffect } from "react";
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import moment from "moment";
import { base_url } from "../api/Axios";
import { LendingModal } from "../widgets/LendingModal";
import Loader from "./Loader";
import Logout from "./Logout";
import { useNavigate } from "react-router-dom";

export class SearchParams {
	first = 0;
	rows = 10;
	searchText = "";
	sortColumn = "";
	sortDirection = 1;
}

const ValidateEmployee = () => {
	const navigate = useNavigate();
	const cookies = new Cookies();
	const token = cookies.get("token");
	const [employee, setEmployee] = useState(null);
	const [employeeDetial, setEmployeeDetial] = useState(null);
	const [fetchEmployeeError, setFetchEmployeeError] = useState();
	const [fetchEmployeeErrorDetial, setFetchEmployeeErrorDetial] = useState();
	const [selectedEmployee, setSelectedEmployee] = useState(null);
	const [url, setUrl] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [searchParams, setSearchParams] = useState(new SearchParams());
	const [filterOption, setFilterOption] = useState("PENDING");
	const [searchResult, setSearchResult] = useState("");
	const [prevFilterOption, setPrevFilterOption] = useState("");
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(false);

	const openModal = (employeeName, url, employeeInfo) => {
		setSelectedEmployee(employeeName);
		const updatedUrl = url.replace("http://", "https://");
		setUrl(updatedUrl);
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
		//window.location.reload();
	};

	useEffect(() => {
		const fetchEmployeeDetial = async () => {
			try {
				const response = await axios.get(`${url}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setEmployeeDetial(response.data);
				console.log(employeeDetial);
			} catch (error) {
				if(error.response.status === 401){
					Logout();
					setFetchEmployeeError(error?.response?.data[0]);
					setTimeout(() => {
						setFetchEmployeeError("");
					}, 2000);
					navigate("/login");
				}
				setFetchEmployeeErrorDetial(error.response?.data[0]);
				console.error("Error fetching employee data:", fetchEmployeeError);
			}
		};
		fetchEmployeeDetial();
	}, [url]);

	useEffect(() => {
		const filterEmployeeByStatus = async () => {
			setEmployee(null);
			setLoading(true);
			if (prevFilterOption !== filterOption) {
				setSearchParams({
					...searchParams,
					first: 0,
				});
			}
			try {
				const response = await axios.get(
					`${base_url}employees/?f=${searchParams.first}&limit=${searchParams.rows}&offset=${searchParams.first}&status=${filterOption}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setEmployee(response.data);
				setCount(response.data.count);
				setLoading(false);
				console.log("Employee data:", response.data);
			} catch (error) {
				if(error.response.status === 401){
					Logout();
					setFetchEmployeeError(error?.response?.data[0]);
					setTimeout(() => {
						setFetchEmployeeError("");
					}, 2000);
					navigate("/login");
				}
				setFetchEmployeeError(error?.response?.data[0]);
				setLoading(false);
				console.error("Error fetching company data:", fetchEmployeeError);
			}
		};

		setPrevFilterOption(filterOption);
		filterEmployeeByStatus();
	}, [filterOption, searchParams, count]);

	useEffect(() => {
		const searchEmplyee = async () => {
			try {
				const response = await axios.get(
					`${base_url}employees/?search=${searchResult}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setEmployee(response.data);
				console.log("Employee data:", response.data);
			} catch (error) {
				if(error.response.status === 401){
					Logout();
					setFetchEmployeeError(error?.response?.data[0]);
					setTimeout(() => {
						setFetchEmployeeError("");
					}, 2000);
					navigate("/login");
				}
				setFetchEmployeeError(error?.response?.data[0]);
				console.error("Error fetching company data:", fetchEmployeeError);
			}
		};

		searchEmplyee();
	}, [searchResult]);

	const displayCompany =
		employee?.results?.map((employeeeList, index) => ({
			url: employeeeList?.url,
			name: `${employeeeList?.first_name} ${

					employeeeList?.middle_name } ${employeeeList?.last_name
			}`.trim(),
			phone: employeeeList?.phone,
			account_number: employeeeList?.account_number,
			position: employeeeList?.position,
			gross_salary: employeeeList?.gross_salary,
			created: employeeeList?.created_at,
			updated: employeeeList?.updated_at,
			status: employeeeList?.status,
		})) || null;

	const goNext = () => {
		if (searchParams.first + searchParams.rows < employee.count) {
			setSearchParams({
				...searchParams,
				first: searchParams.first + searchParams.rows,
			});
		}
	};

	const goBack = () => {
		if (searchParams.first > 0) {
			setSearchParams({
				...searchParams,
				first: searchParams.first - searchParams.rows,
			});
		}
	};
	return (
		<div className="min-h-[50vh] flex flex-col pt-8 px-4 justify-center items-center">
			{
				loading && (
					<Loader />
				)

			}
			{fetchEmployeeError ? (
				<div>Error fetching employee data. Please try Agian.</div>
			) : (
				<div className="overflow-x-auto w-full py-8 px-2 border-2 border-b-gray-400">
					<div className="flex items-center justify-end mr-8">
						<div className="mb-4 flex items-center justify-end mr-8">
							<input
								className="border border-gray-300 p-2 rounded-md"
								type="text"
								placeholder="Search..."
								value={searchResult}
								onChange={(e) => setSearchResult(e.target.value)}
							/>
						</div>
						<div className="mb-4 flex items-center justify-end mr-8">
							<label className="text-gray-600 mr-2">Filter by Status:</label>
							<select
								className="p-2 border border-gray-300 rounded-md"
								value={filterOption}
								onChange={(e) => setFilterOption(e.target.value)}
							>
								<option value="">------</option>
								<option value="PENDING">Pending</option>
								<option value="ACTIVE">Active</option>
								<option value="INACTIVE">Inactive</option>
								<option value="DISAPPROVED">Declined</option>
							</select>
						</div>
					</div>
					{employee?.count === 0 && (
						<div className="flex flex-col justify-center items-center">
						<div className="flex flex-col justify-center items-center">
							<ExclamationCircleIcon className="h-10 w-10 text-orange-500" />
							<div className="text-gray-500 text-lg font-bold">
								No Employee Found to Approve
							</div>
						</div>
					</div>
					)
					}
					{
					employee?.count > 0 && (
						<>
					<table className="min-w-full  border-gray-300">
						<thead>
							<tr className="text-left border-2 border-b-gray-400">
								<th className="py-2 px-4 border-b">No</th>
								<th className="py-2 px-4 border-b">Name</th>
								<th className="py-2 px-4 border-b">Account Number</th>
								<th className="py-2 px-4 border-b">Phone</th>
								<th className="py-2 px-4 border-b">Gross Salary</th>
								<th className="py-2 px-4 border-b">Last Update Date</th>
								<th className="py-2 px-4 border-b">Status</th>
								<th className="py-2 px-4 border-b">Decision</th>
							</tr>
						</thead>
						<tbody>
							{displayCompany?.map((employeeInfo, index) => (
								<tr
									key={index}
									className={index % 2 === 0 ? "bg-gray-100" : ""}
								>
									<td className="py-2 px-4 border-b">{searchParams.first + index + 1}</td>
									<td className=" py-2 px-4 border-b">
										{employeeInfo?.name
											.split(" ")
											.map(
												(word) =>
													word?.charAt(0)?.toUpperCase() +
													word?.slice(1)?.toLowerCase()
											)
											.join(" ")}
									</td>
									<td className="py-2 px-4 border-b">
										{employeeInfo?.account_number}
									</td>
									<td className="py-2 px-4 border-b">+{employeeInfo?.phone}</td>
									<td className="py-2 px-4 border-b">
										{Number(employeeInfo?.gross_salary).toLocaleString(
											"en-US",
											{ style: "currency", currency: "ETB" }
										)}
									</td>
									<td className="py-2 px-4 border-b">
										{employeeInfo?.updated
											? moment(employeeInfo?.updated).format("YYYY-MM-DD")
											: ""}
									</td>
									<td className="py-2 px-4 border-b">
										{employeeInfo?.status &&
											(employeeInfo.status === "DISAPPROVED"
												? "Declined"
												: employeeInfo.status.charAt(0) +
												  employeeInfo.status.slice(1).toLowerCase())}
									</td>
									<td className="py-2 px-4 border-b">
										<button
											onClick={() =>
												openModal(
													employeeInfo?.name ? employeeInfo?.name : "Anonymous",
													employeeInfo?.url
												)
											}
											className={`bg-kacha-orange-700 hover:bg-kacha-orange-600 text-white font-bold py-2 px-4 rounded w-[150px] ${
												employeeInfo.status === "ACTIVE" ||
												employeeInfo.status === "DISAPPROVED"
													? "opacity-50 cursor-not-allowed"
													: ""
											}${employeeInfo.status === "PENDING" ? "" : ""}`}
											{...(employeeInfo?.status === "ACTIVE" ||
											employeeInfo?.status === "DISAPPROVED"
												? { disabled: true }
												: {})}
										>
											{employeeInfo?.status === "ACTIVE"
												? "Approved"
												: employeeInfo?.status === "DISAPPROVED"
												? "Declined"
												: "  Approve/Decline "}
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					{selectedEmployee && (
						<LendingModal
							isOpen={modalIsOpen}
							closeModal={closeModal}
							reviewData={employeeDetial}
							url={url}
							role="CHECKER"
							type="EMPLOYEE"
						/>
					)}
						</>
						)
					}
				</div>
				
			)}
			{
				employee?.count > 0 && (
			<div className="flex items-center justify-between w-md ">
				<div className="flex items-center space-x-2">
					<span className="text-gray-600">Items per page: </span>
					<div className="relative inline-block">
						<select
							className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:bg-white"
							value={searchParams.rows}
							onChange={(e) => {
								searchParams.rows = Number(e.target.value);
								setSearchParams({ ...searchParams });
							}}
						>
							<option value="10"> 10</option>
							<option value="20">20</option>
							<option value="50">50</option>
						</select>
						<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
							<svg
								className="fill-current h-4 w-4"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
							>
								<path d="M10 12.5l-5-5 1.5-1.5L10 9.5l3.5-3.5L15 7z" />
							</svg>
						</div>
					</div>
				</div>

				<span className="text-gray-600 mx-auto px-10 ">
					{searchParams.first + 1} - {searchParams.first + searchParams.rows} of{" "}
					{employee?.count || 0}
				</span>

				<div className="flex items-center space-x-4">
					<div>
						<CiCircleChevLeft
							onClick={goBack}
							className={`${
								searchParams.first === 0 ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
							}  h-8 w-8  text-orange-700 font-bold`}
							size={30}
						/>

						{/* <svg
							xmlns="http://www.w3.org/2000/svg"
							className={`${
								searchParams.first === 0 ? "opacity-50 cursor-not-allowed" : ""
							}  h-8 w-8  text-orange-700 font-bold`}
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M15 19l-7-7 7-7"
							/>
						</svg> */}
						{" "}
					</div>
					<div>
						<CiCircleChevRight
							onClick={goNext}
							className={`${
								searchParams.first + searchParams.rows >= employee?.count
									? "opacity-50 cursor-not-allowed"
									: "cursor-pointer"
							}  h-8 w-8  text-orange-700 font-bold `}
							size={30}
							/>
						{/* <svg
							xmlns="http://www.w3.org/2000/svg"
							className={`${
								searchParams.first + searchParams.rows >= employee?.count
									? "opacity-50 cursor-not-allowed"
									: ""
							}  h-8 w-8  text-orange-700 font-bold`}
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M9 5l7 7-7 7"
							/>
						</svg> */}
					</div>
				</div>
			</div>
				)
			}
		</div>
	);
};

export default ValidateEmployee;
