import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import axios from "axios";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Cookies from "universal-cookie";
import { base_url } from "../api/Axios";
import informationIcon from "../images/information-button.png";
import peaceLogo from "../images/peace-logo.png";
import { registorEmployee } from "../utils/Validation";
import { LendingModal } from "../widgets/LendingModal";
import FilePreviewModal from "./FilePreviewModal";
import Logout from "./Logout";

const RegistorEmployee = () => {
	const cookies = new Cookies();
	const navigate = useNavigate();
	const token = cookies.get("token");

	const [emplyeeForm, setEmplyeeForm] = useState({
		first_name: "",
		middle_name: "",
		last_name: "",
		gender: null,
		martial_status: null,
		phone: "",
		employer: null,
		date_of_employment: new Date(),

		gross_salary: null,
		net_salary: null,
		account_number: "",
		position: null,
		total_work_experience: null,
	});
	const [employer, setEmployer] = useState();
	const [bulkRegistorForm, setBulkRegistorForm] = useState({
		file: null,
		remark: "",
	});
	const [file, setFile] = useState();
	const currentDate = new Date().toISOString().split("T")[0];
	const [searchCompany, setSearchCompany] = useState("");

	const filteredEmployers = employer?.filter(
		(list) =>
			list?.name.toLowerCase().includes(searchCompany.toLowerCase()) ||
			list?.code.toLowerCase().includes(searchCompany.toLowerCase())
	);

	const zones = [
		{
			value: 2,
			display_name: "Addis Ketema, Addis Abeba",
		},
		{
			value: 5,
			display_name: "Akaky Kaliti, Addis Abeba",
		},
		{
			value: 1,
			display_name: "Arada, Addis Abeba",
		},
		{
			value: 3,
			display_name: "Bole, Addis Abeba",
		},
		{
			value: 6,
			display_name: "Gullele, Addis Abeba",
		},
		{
			value: 7,
			display_name: "Kirkos, Addis Abeba",
		},
		{
			value: 8,
			display_name: "Kolfe Keranio, Addis Abeba",
		},
		{
			value: 11,
			display_name: "Lemi Kura, Addis Abeba",
		},
		{
			value: 9,
			display_name: "Lideta, Addis Abeba",
		},
		{
			value: 4,
			display_name: "Nifas Silk Lafto, Addis Abeba",
		},
		{
			value: 10,
			display_name: "Yeka, Addis Abeba",
		},
		{
			value: 75,
			display_name: "Dire Dawa City Administration, Dire Dawa",
		},
		{
			value: 12,
			display_name: "Central Tigray, Tigray",
		},
		{
			value: 14,
			display_name: "Eastern Tigray, Tigray",
		},
		{
			value: 18,
			display_name: "Mekelle City, Tigray",
		},
		{
			value: 15,
			display_name: "North Western Tigray, Tigray",
		},
		{
			value: 13,
			display_name: "South Eastern Tigray, Tigray",
		},
		{
			value: 17,
			display_name: "Southern Tigray, Tigray",
		},
		{
			value: 16,
			display_name: "Western Tigray, Tigray",
		},
		{
			value: 43,
			display_name: "Agew Awi Zone, Amhara",
		},
		{
			value: 52,
			display_name: "Bahir Dar Special Zone, Amhara",
		},
		{
			value: 76,
			display_name: "Central Gondar Zone, Amhara",
		},
		{
			value: 53,
			display_name: "Defunct Districts, Amhara",
		},
		{
			value: 44,
			display_name: "East Gojjam Zone, Amhara",
		},
		{
			value: 45,
			display_name: "North Gondar Zone, Amhara",
		},
		{
			value: 46,
			display_name: "North Shewa Zone, Amhara",
		},
		{
			value: 47,
			display_name: "North Wollo Zone, Amhara",
		},
		{
			value: 79,
			display_name: "Oromia Zone, Amhara",
		},
		{
			value: 49,
			display_name: "South Gondar Zone, Amhara",
		},
		{
			value: 48,
			display_name: "South Wollo Zone, Amhara",
		},
		{
			value: 50,
			display_name: "Wag Hemra Zone, Amhara",
		},
		{
			value: 51,
			display_name: "West Gojjam Zone, Amhara",
		},
		{
			value: 78,
			display_name: "West Gondar Zone, Amhara",
		},
		{
			value: 19,
			display_name: "Zone 1 (Awsi Rasu), Afar",
		},
		{
			value: 20,
			display_name: "Zone 2 (Kilbet Rasu), Afar",
		},
		{
			value: 21,
			display_name: "Zone 3 (Gabi Rasu), Afar",
		},
		{
			value: 22,
			display_name: "Zone 4 (Fantena Rasu), Afar",
		},
		{
			value: 23,
			display_name: "Zone 5 (Hari Rasu), Afar",
		},
		{
			value: 80,
			display_name: "Zone 6 (Mahi Rasu), Afar",
		},
		{
			value: 82,
			display_name: "Zone 7 (Argobba, special woreda), Afar",
		},
		{
			value: 70,
			display_name: "Adama City Administration, Oromia",
		},
		{
			value: 54,
			display_name: "Arsi Zone, Oromia",
		},
		{
			value: 55,
			display_name: "Bale Zone, Oromia",
		},
		{
			value: 56,
			display_name: "Borena Zone, Oromia",
		},
		{
			value: 89,
			display_name: "Buno Bedele Zone, Oromia",
		},
		{
			value: 73,
			display_name: "Defunct Districts, Oromia",
		},
		{
			value: 83,
			display_name: "East Arsi Zone, Oromia",
		},
		{
			value: 84,
			display_name: "East Bale Zone, Oromia",
		},
		{
			value: 86,
			display_name: "East Guji zone, Oromia",
		},
		{
			value: 57,
			display_name: "East Hararghe Zone, Oromia",
		},
		{
			value: 58,
			display_name: "East Shewa Zone, Oromia",
		},
		{
			value: 59,
			display_name: "East Wollega Zone, Oromia",
		},
		{
			value: 60,
			display_name: "Guji Zone, Oromia",
		},
		{
			value: 61,
			display_name: "Horo Guduru Welega Zone, Oromia",
		},
		{
			value: 88,
			display_name: "Illu ababora zone, Oromia",
		},
		{
			value: 71,
			display_name: "Jimma City Administration, Oromia",
		},
		{
			value: 62,
			display_name: "Jimma Zone, Oromia",
		},
		{
			value: 63,
			display_name: "Kelam Welega Zone, Oromia",
		},
		{
			value: 64,
			display_name: "North Shewa Zone, Oromia",
		},
		{
			value: 72,
			display_name: "Oromia Special Zone Surrounding Finfinne, Oromia",
		},
		{
			value: 65,
			display_name: "South West Shewa Zone, Oromia",
		},
		{
			value: 66,
			display_name: "West Arsi Zone, Oromia",
		},
		{
			value: 85,
			display_name: "West Bale zone, Oromia",
		},
		{
			value: 87,
			display_name: "West Guji, Oromia",
		},
		{
			value: 67,
			display_name: "West Hararghe Zone, Oromia",
		},
		{
			value: 68,
			display_name: "West Shewa Zone, Oromia",
		},
		{
			value: 69,
			display_name: "West Welega Zone, Oromia",
		},
		{
			value: 38,
			display_name: "Asosa Zone, Benishangul-Gumuz",
		},
		{
			value: 39,
			display_name: "Kamashi Zone, Benishangul-Gumuz",
		},
		{
			value: 41,
			display_name: "Mao-Komo (Special Woreda), Benishangul-Gumuz",
		},
		{
			value: 40,
			display_name: "Metekel Zone, Benishangul-Gumuz",
		},
		{
			value: 42,
			display_name: "Pawe  (Special Woreda), Benishangul-Gumuz",
		},
		{
			value: 24,
			display_name: "Anuak Zone, Gambela",
		},
		{
			value: 27,
			display_name: "Itang (Special Woreda), Gambela",
		},
		{
			value: 26,
			display_name: "Mezhenger, Gambela",
		},
		{
			value: 25,
			display_name: "Nuer Zone, Gambela",
		},
		{
			value: 74,
			display_name: "Harari, Harari",
		},
		{
			value: 118,
			display_name: "Aleta Chuko Zone, Sidama",
		},
		{
			value: 119,
			display_name: "Aleta Wendo Zone, Sidama",
		},
		{
			value: 120,
			display_name: "Arbegona Zone, Sidama",
		},
		{
			value: 121,
			display_name: "Aroresa Zone, Sidama",
		},
		{
			value: 123,
			display_name: "Bensa Zone, Sidama",
		},
		{
			value: 124,
			display_name: "Bona Zuria Zone, Sidama",
		},
		{
			value: 125,
			display_name: "Boricha Zone, Sidama",
		},
		{
			value: 126,
			display_name: "Bursa Zone, Sidama",
		},
		{
			value: 127,
			display_name: "Chere Zone, Sidama",
		},
		{
			value: 128,
			display_name: "Dale Zone, Sidama",
		},
		{
			value: 129,
			display_name: "Dara Zone, Sidama",
		},
		{
			value: 130,
			display_name: "Gorche Zone, Sidama",
		},
		{
			value: 131,
			display_name: "Hawassa city, Sidama",
		},
		{
			value: 122,
			display_name: "Hawassa Zuria Zone, Sidama",
		},
		{
			value: 132,
			display_name: "Hula Zone, Sidama",
		},
		{
			value: 133,
			display_name: "Loko Abaya zone, Sidama",
		},
		{
			value: 134,
			display_name: "Malga zone, Sidama",
		},
		{
			value: 135,
			display_name: "Shebedino zone, Sidama",
		},
		{
			value: 136,
			display_name: "Wensho zone, Sidama",
		},
		{
			value: 137,
			display_name: "Wondo Genet zone, Sidama",
		},
		{
			value: 112,
			display_name: "Bench Maji Zone, South West Ethiopia Peoples",
		},
		{
			value: 113,
			display_name:
				"Dawro (formerly part of North Omo Zone), South West Ethiopia Peoples",
		},
		{
			value: 114,
			display_name:
				"Keffa (formerly part of Keficho Shekicho Zone), South West Ethiopia Peoples",
		},
		{
			value: 116,
			display_name:
				"Konta (special woreda, formerly part of North Omo Zone), South West Ethiopia Peoples",
		},
		{
			value: 115,
			display_name:
				"Sheka (formerly part of Keficho Shekicho Zone), South West Ethiopia Peoples",
		},
		{
			value: 117,
			display_name: "West Omo Zone, South West Ethiopia Peoples",
		},
		{
			value: 106,
			display_name: "Alaba Zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 107,
			display_name:
				"Amaro (special woreda), Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 108,
			display_name:
				"Basketo (special woreda, formerly part of North Omo Zone), Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 109,
			display_name:
				"Burji (special woreda), Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 110,
			display_name:
				"Dirashe (special woreda), Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 97,
			display_name:
				"Gamo Gofa Zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 98,
			display_name: "Gedeo Zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 99,
			display_name: "Gurage Zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 100,
			display_name: "Hadiya Zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 101,
			display_name:
				"Kembata Tembaro zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 104,
			display_name: "Konso Zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 102,
			display_name: "Silt'e Zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 103,
			display_name:
				"South Omo zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 105,
			display_name:
				"Wolayita (formerly part of North Omo Zone), Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 111,
			display_name:
				"Yem (special woreda), Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 29,
			display_name: "Afder Zone, Somali",
		},
		{
			value: 93,
			display_name: "Degehabur Special Zone, Somali",
		},
		{
			value: 90,
			display_name: "Dhawa Zone, Somali",
		},
		{
			value: 37,
			display_name: "Dollo Zone, Somali",
		},
		{
			value: 32,
			display_name: "Erer Zone, Somali",
		},
		{
			value: 34,
			display_name: "Faafan Zone, Somali",
		},
		{
			value: 94,
			display_name: "Gode Special Zone, Somali",
		},
		{
			value: 30,
			display_name: "Jarar Zone, Somali",
		},
		{
			value: 91,
			display_name: "Jigjiga Special Zone, Somali",
		},
		{
			value: 95,
			display_name: "Kebri Beyah Special Zone, Somali",
		},
		{
			value: 96,
			display_name: "Kebri Dahar Special Zone, Somali",
		},
		{
			value: 35,
			display_name: "Korahe Zone, Somali",
		},
		{
			value: 28,
			display_name: "liben zone (Lahey), Somali",
		},
		{
			value: 31,
			display_name: "Nogob Zone, Somali",
		},
		{
			value: 33,
			display_name: "Shabelle Zone, Somali",
		},
		{
			value: 36,
			display_name: "Sitti Zone, Somali",
		},
		{
			value: 92,
			display_name: "Tog Wajale Special Zone, Somali",
		},
	];
	const region = [
		{
			value: "AA",
			display_name: "Addis Ababa",
		},
		{
			value: "AF",
			display_name: "Afar Region",
		},
		{
			value: "AM",
			display_name: "Amhara Region",
		},
		{
			value: "BG",
			display_name: "Benishangul-Gumuz Region",
		},
		{
			value: "DR",
			display_name: "Dire Dawa",
		},
		{
			value: "GM",
			display_name: "Gambela Region",
		},
		{
			value: "HR",
			display_name: "Harari Region",
		},
		{
			value: "OR",
			display_name: "Oromia Region",
		},
		{
			value: "SD",
			display_name: "Sidama Region",
		},
		{
			value: "SM",
			display_name: "Somali Region",
		},
		{
			value: "SW",
			display_name: "South West Ethiopia Peoples' Region",
		},
		{
			value: "SN",
			display_name: "Southern Nations, Nationalities, and Peoples' Region",
		},
		{
			value: "TG",
			display_name: "Tigray Region",
		},
	];
	const position = [
		{
			value: "Labour",
			display_name: "Labour",
		},
		{
			value: "Entry",
			display_name: "Entry",
		},
		{
			value: "Junior",
			display_name: "Junior",
		},
		{
			value: "Mid-Senior",
			display_name: "Mid-Senior",
		},
		{
			value: "Senior",
			display_name: "Senior",
		},
		{
			value: "Lead",
			display_name: "Lead",
		},
		{
			value: "MANAGER",
			display_name: "Manager",
		},
		{
			value: "Principal",
			display_name: "Principal",
		},
		{
			value: "Chief Officer",
			display_name: "Chief Officer",
		},
		{
			value: "Director",
			display_name: "Director",
		},
		{
			value: "Vice President",
			display_name: "Vice President",
		},
		{
			value: "Chief Vice President",
			display_name: "Chief Vice President",
		},
		{
			value: "President",
			display_name: "President",
		},
		{
			value: "Chief Executive Officer (CEO)",
			display_name: "Chief Executive Officer (CEO)",
		},
		{
			value: "Chairman",
			display_name: "Chairman",
		},
		{
			value: "Board of Directors",
			display_name: "Board of Directors",
		},
	];

	const [error, setError] = useState();
	const [success, setSuccess] = useState();
	const [errorBulk, setErrorBulk] = useState({
		first_name: "",
		middle_name: "",
		last_name: "",
		gender: "",
		martial_status: "",
		phone: "",
		employer: "",
		date_of_employment: "",

		gross_salary: "",
		net_salary: "",
		account_number: "",
		position: "",
		total_work_experience: "",
		others: "",
	});
	const [successBulk, setSuccessBulk] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [bulkData, setBulkData] = useState(null);
	const [isBulkModalOpen, setBulkModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const url = `${base_url}employees/`;
	const openModal = (values) => {
		setModalIsOpen(true);
		if (values.phone.includes("+")) {
			// Remove the plus sign and update the values object
			const updatedPhone = values.phone.replace("+", "");
			setEmplyeeForm({ ...values, phone: updatedPhone });
		} else {
			setEmplyeeForm(values);
		}
		console.log(emplyeeForm);
	};

	const closeModal = () => {
		setModalIsOpen(false);

		// window.location.reload();
	};

	const closeModalDialog = () => {
		setBulkModalOpen(false);
	};
	useEffect(() => {
		const fetchOrg = async () => {
			try {
				const employerList = await axios
					.get(`${base_url}orgs_list/`, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then((response) => {
						console.log(response);
						setEmployer(
							response.data?.sort((a, b) => a.name.localeCompare(b.name))
						);
					})
					.catch((error) => {
						if (error.response.status === 401) {
							Logout();
							setError(error?.message);
							setTimeout(() => {
								setError("");
							}, 2000);
							navigate("/login");
						}
						setError(error.message);
						setTimeout(() => {
							setError("");
						}, 2000);
					});
			} catch (error) {
				if (error.response.status === 401) {
					Logout();
					setError(error?.message);
					setTimeout(() => {
						setError("");
					}, 2000);
					navigate("/login");
				}
			}
		};
		fetchOrg();
	}, []);

	useEffect(() => {
		console.log("File state updated:", file);
	}, [file]);

	// const handleBulkRegistration = async (value) => {
	// 	console.log("Request Data:", value);

	// 	try {
	// 		const response = await axios.post(
	// 			`${base_url}employees/Upload_bulk_employee/`,
	// 			value,
	// 			{
	// 				headers: {
	// 					Authorization: `Bearer ${token}`,
	// 				},
	// 			}
	// 		);

	// 		console.log("Response:", response.data);

	// 		setSuccessBulk(response.data.message);
	// 		setTimeout(() => {
	// 			setSuccessBulk("");
	// 		}, 2000);
	// 	} catch (error) {
	// 		console.error("Error:", error);

	// 		setErrorBulk(error.message);
	// 		setTimeout(() => {
	// 			setErrorBulk("");
	// 		}, 2000);
	// 	}

	// 	setBulkRegistorForm(value);
	// 	console.log("bulkRegistorForm:", bulkRegistorForm);
	// };

	const handleNext = () => {
		setCurrentPage(currentPage + 1);
	};

	const handleBack = () => {
		setCurrentPage(currentPage - 1);
	};

	const readCSV = (file) => {
		const promise = new Promise((resolve, reject) => {
			Papa.parse(file, {
				complete: (result) => {
					if (result.data.length < 1) {
						reject(new Error("CSV file must have at least two rows."));
						return;
					}

					const [header, ...content] = result.data;
					const dataWithHeader = content.map((row) => {
						return header.reduce((acc, colName, index) => {
							acc[colName] = row[index];
							return acc;
						}, {});
					});

					resolve(dataWithHeader);
				},
				error: (error) => {
					reject(error);
				},
			});
		});

		return promise;
	};

	const handleFileUpload = async (values) => {
		try {
			if (!values.file) {
				setErrorBulk({
					others: "Please select a file to upload.",
				});
				setTimeout(() => {
					setErrorBulk({
						others: "",
					});
				}, 3000);
			}
			const dataWithHeader = await readCSV(values.file);
			console.log("Data with Header:", dataWithHeader);
			// setBulkRegistorForm(dataWithHeader);
			setBulkData(dataWithHeader);
			setBulkModalOpen(true);
		} catch (error) {
			console.error("Error parsing CSV file:", error);
		}
	};

	const handleUploadSubmit = async () => {
		try {
			console.log("Bulk Registor Form:", bulkRegistorForm);

			const result = await axios.post(
				`${base_url}employees/Upload_bulk_employee/`,
				bulkRegistorForm,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setSuccessBulk("Thank You, You Have Registered!");
			setTimeout(() => {
				setSuccessBulk("");
				setBulkRegistorForm({
					file: null,
					remark: "",
				});
				setLoading(false);
			}, 2000);
		} catch (error) {
			setErrorBulk({
				first_name: error.response.data.first_name,
				middle_name: error.response.data.middle_name,
				last_name: error.response.data.last_name,
				gender: error.response.data.gender,
				martial_status: error.response.data.martial_status,
				phone: error.response.data.phone,
				employer: error.response.data.employer,
				date_of_employment: error.response.data.date_of_employment,
				gross_salary: error.response.data.gross_salary,
				net_salary: error.response.data.net_salary,
				account_number: error.response.data.account_number,
				position: error.response.data.position,
				total_work_experience: error.response.data.total_work_experience,
				others: error.response.data.error,
			});
			if (error.response.status === 401) {
				Logout();
				setError(error?.message);
				setTimeout(() => {
					setError("");
				}, 2000);
				navigate("/login");
			}

			console.log("Error:", error.response);
			setTimeout(() => {
				setErrorBulk("");
				setLoading(false);
			}, 3000);
		}
	};

	console.log("employee Data", emplyeeForm);

	return (
		<div className="relative  bg-white">
			<div className="  responsive-aware-form flex  items-center justify-center pb-8 px-8 space-x-4">
				<div className=" flex item-center justify-between   p-12">
					<div className="flex flex-col items-center  justify-center">
						<div className="flex flex-col items-center  justify-center">
							<img src={peaceLogo} alt="logo" className="h-14 pb-2" />
							<h1 className="text-center font-montserrata text-kacha-text3 pb-4">
								Register Employee
							</h1>
							<div className="flex ">
								<img src={informationIcon} alt="logo" className="h-6 pr-2" />
								Please enter all mandatory fields indicated with
								<span className="text-red-500 pl-2 pr-2 font-semibold">
									{" "}
									*{" "}
								</span>{" "}
								before proceeding to next page!
							</div>
						</div>
						<Formik
							initialValues={{
								emplyeeForm,
							}}
							validationSchema={registorEmployee}
							onSubmit={(values) => {
								// handleRegistration(values);
								console.log("values onSubmit", values);
								openModal(values);
							}}
						>
							{(formik) => (
								<Form>
									{currentPage === 1 && (
										<>
											<div className="mt-5" />

											<div className="flex flex-col">
												<label className="mb-1 text-kacha-text9">
													First Name <span className="text-red-500">*</span>
												</label>
												<Field
													className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[400px] max-w-[400px]"
													name="first_name"
													as="input"
													type="text"
													// placeholder="Abebe"
												/>
												{formik.errors.first_name &&
													formik.touched.first_name && (
														<div className="error text-red-500">
															{formik.errors.first_name}
														</div>
													)}
											</div>
											<div className="mt-5" />
											<div className="flex flex-col">
												<label className="mb-1 text-kacha-text9">
													Middle Name <span className="text-red-500">*</span>
												</label>
												<Field
													className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
													name="middle_name"
													as="input"
													type="text"
													// placeholder="Hagos"
												/>
												{formik.errors.middle_name &&
													formik.touched.middle_name && (
														<div className="error text-red-500">
															{formik.errors.middle_name}
														</div>
													)}
											</div>
											<div className="mt-5" />
											<div className="flex flex-col">
												<label className="mb-1 text-kacha-text9">
													Last Name <span className="text-red-500">*</span>
												</label>
												<Field
													className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
													name="last_name"
													as="input"
													type="text"
													// placeholder="Challa"
												/>
												{formik.errors.last_name &&
													formik.touched.last_name && (
														<div className="error text-red-500">
															{formik.errors.last_name}
														</div>
													)}
											</div>

											<div className="mt-5" />
											<div className="flex flex-col">
												<label className="mb-1 text-kacha-text9">
													Gender<span className="text-red-500">*</span>
												</label>
												<Field
													as="select"
													id="gender"
													name="gender"
													className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
												>
													<option value="">---</option>
													<option value="MALE">Male</option>
													<option value="FEMALE">Female</option>
												</Field>
												{formik.errors.gender && formik.touched.gender && (
													<div className="error text-red-500">
														{formik.errors.gender}
													</div>
												)}
											</div>

											<div className="mt-5" />
											<div className="flex flex-col">
												<label className="mb-1 text-kacha-text9">
													Marital Status
												</label>
												<Field
													as="select"
													id="martial_status"
													name="martial_status"
													className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
												>
													<option value="">---</option>
													<option value="NOT_MARRIED">Single</option>
													<option value="MARRIED">Married</option>
													<option value="DIVORCED">Divorced</option>
													<option value="WIDOWED">Widowed</option>
												</Field>
												{formik.errors.martial_status &&
													formik.touched.martial_status && (
														<div className="error text-red-500">
															{formik.errors.martial_status}
														</div>
													)}
											</div>
											<div className="mt-5" />

											<div className="flex flex-col min-w-[300px] max-w-[400px]">
												<label className="mb-1 text-kacha-text9">
													Phone Number<span className="text-red-500">*</span>
												</label>
												<fieldset className="flex flex-row text-left min-w-[300px] max-w-[400px]">
													<div className="relative ">
														<div className="absolute inset-y-0 left-0 flex  p-3 pointer-events-none text-center">
															<div className="flag-icon">🇪🇹 +</div>
														</div>
														<Field
															className="border-l border-t border-b border-gray-300 p-3 rounded-l-md text-kacha-text9 min-w-[40px] max-w-[60px] text-center"
															name="countryCode"
															as="input"
															type="text"
															disabled
														/>
													</div>
													<div className="flex flex-col">
														<Field
															className="border border-gray-300 p-3 rounded-r-md text-kacha-text9 min-w-[340px] max-w-[340px]"
															name="phone"
															as="input"
															type="text"
															// placeholder="Company Phone"
														/>
														{formik.errors.phone && formik.touched.phone && (
															<div className="error text-red-500">
																{formik.errors.phone}
															</div>
														)}
													</div>
												</fieldset>
											</div>
											<div className="mt-5" />

											<div className="mt-5" />
											<button
												className=" bg-kacha-orange-700 text-center  m-2 p-3 min-w-[200px] text-white rounded-md text-lg"
												type="button"
												onClick={handleNext}
												disabled={
													!!formik.errors.first_name ||
													!!formik.errors.middle_name ||
													!!formik.errors.last_name ||
													!!formik.errors.gender ||
													!!formik.errors.phone ||
													Object.keys(formik.touched).length <= 0
												}
											>
												Next
											</button>
										</>
									)}
									{currentPage === 2 && (
										<>
											<div className="mt-5" />
											<div className="flex flex-col">
												<label className="mb-1 text-kacha-text9">
													Employer ID<span className="text-red-500">*</span>
												</label>
												<Field
													className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
													name="employer"
													as="select"
												>
													<option value="">---</option>
													{employer?.map((list) => (
														<option key={list?.code} value={list?.code}>
															{list?.name}
														</option>
													))}
												</Field>
												{formik.errors.employer && formik.touched.employer && (
													<div className="error text-red-500">
														{formik.errors.employer}
													</div>
												)}
											</div>

											<div className="mt-5" />
											<div className="flex flex-col">
												<label className="mb-1 text-kacha-text9">
													Date of Employment
													<span className="text-red-500">*</span>
												</label>
												<Field
													className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
													name="date_of_employment"
													as="input"
													type="date"
													max={currentDate}
												/>
												{formik.errors.date_of_employment &&
													formik.touched.date_of_employment && (
														<div className="error text-red-500">
															{formik.errors.date_of_employment}
														</div>
													)}
											</div>
											<div className="mt-5" />
											<div className="flex flex-col">
												<label className="mb-1 text-kacha-text9">
													Basic Salary <span className="text-red-500">*</span>
												</label>
												<Field
													className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
													name="gross_salary"
													as="input"
													type="number"
													// placeholder="Enter the gross_salary"
												/>
												{formik.errors.gross_salary &&
													formik.touched.gross_salary && (
														<div className="error text-red-500">
															{formik.errors.gross_salary}
														</div>
													)}
											</div>
											<div className="mt-5" />
											<div className="flex flex-col">
												<label className="mb-1 text-kacha-text9">
													Net Salary <span className="text-red-500">*</span>
												</label>
												<Field
													className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
													name="net_salary"
													as="input"
													type="number"
													// placeholder="Enter the net gross_salary"
												/>
												{formik.errors.net_salary &&
													formik.touched.net_salary && (
														<div className="error text-red-500">
															{formik.errors.net_salary}
														</div>
													)}
											</div>
											<div className="mt-5" />
											<div className="flex flex-col">
												<label className="mb-1 text-kacha-text9">
													Salary Account <span className="text-red-500">*</span>
												</label>
												<Field
													className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
													name="account_number"
													as="input"
													type="number"
													// placeholder="Enter the account number"
												/>
												{formik.errors.account_number &&
													formik.touched.account_number && (
														<div className="error text-red-500">
															{formik.errors.account_number}
														</div>
													)}
											</div>
											<div className="mt-5" />

											<div className="flex flex-col">
												<label className="mb-1 text-kacha-text9">
													Position
												</label>
												<Field
													className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
													name="position"
													as="select"
												>
													<option value="">---</option>
													{position.map((list) => (
														<option key={list.value} value={list.value}>
															{list.display_name}
														</option>
													))}
												</Field>
												{formik.errors.position && formik.touched.position && (
													<div className="error text-red-500">
														{formik.errors.position}
													</div>
												)}
											</div>
											<div className="mt-5" />
											<div className="flex flex-col">
												<label className="mb-1 text-kacha-text9">
													Total Work Experience
												</label>
												<Field
													className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
													name="total_work_experience"
													as="input"
													type="number"
													// placeholder="Enter the account number"
												/>
												{formik.errors.total_work_experience &&
													formik.touched.total_work_experience && (
														<div className="error text-red-500">
															{formik.errors.total_work_experience}
														</div>
													)}
											</div>
											<div className="mt-5" />

											<div className="mt-5" />
											<div className="text-kacha-text10">
												By proceeding you are accepting terms and conditions of
												the company.
											</div>
											<div className="mt-5" />
											<div type="submit">
												<button
													type="button"
													onClick={handleBack}
													className=" bg-kacha-orange-700 text-center cursor-pointer m-2 p-3 min-w-[200px] text-white rounded-md text-lg"
												>
													Back
												</button>
												<button
													className=" bg-kacha-orange-700 text-center cursor-pointer m-2 p-3 min-w-[300px] max-w-[400px] text-white rounded-md text-lg"
													type="submit"
													disabled={
														!!formik.errors.gross_salary ||
														!!formik.errors.net_salary ||
														!!formik.errors.account_number ||
														!!formik.errors.employer ||
														!!formik.errors.date_of_employment ||
														Object.keys(formik.touched).length <= 0
													}
												>
													Review and Submit
												</button>
											</div>
										</>
									)}

									<div className="mt-5" />

									<div type="submit">
										<LendingModal
											isOpen={modalIsOpen}
											closeModal={closeModal}
											reviewData={emplyeeForm}
											url={url}
											role="MAKER"
											type="EMPLOYEE"
										/>
									</div>
								</Form>
							)}
						</Formik>
					</div>
					<div className=" m-8 p-12 border border-gray-50 rounded-lg  pl-4  ">
						<h1 className="text-center pt-12 font-montserrat text-kacha-text3 text-2xl">
							Bulk Upload
						</h1>
						<div className="my-5">
							<h4 className="text-kacha-text8 text-md font-medium leading-6 text-gray-600">
								Additionally, you have the flexibility to upload bulk data.
							</h4>
						</div>
						<Formik
							initialValues={{
								file: null,
								remark: "",
							}}
							onSubmit={handleUploadSubmit}
						>
							{(formik) => (
								<Form>
									<div className="mt-5">
										<div className="flex flex-col w-[300px]">
											<label className="mb-1 text-kacha-text9">
												Select the file to upload
											</label>
											<input
												className="border border-gray-300 p-2 rounded-md text-kacha-text9 w-[300px]"
												name="file"
												id="file"
												accept=".csv"
												type="file"
												onChange={(e) => {
													const f = e.target.files && e.target?.files[0];
													if (f && f.type.includes("csv")) {
														formik.setFieldValue(
															"file",
															e.target.files && e.target.files[0]
														);
														setFile(e.target.files && e.target.files[0]?.name);
														setBulkRegistorForm({
															...bulkRegistorForm,
															file: e.target.files && e.target.files[0],
														});
														console.log(
															"Bulk Registor Form:",
															bulkRegistorForm
														);
													}
												}}
											/>
											{formik.errors.file && formik.touched.file && (
												<div className="error text-red-500">
													{formik.errors.file}
												</div>
											)}
										</div>

										{successBulk && (
											<div className="text-green-700 text-kacha-text7 mt-5 p-2 bg-white rounded-md w-[300px] ">
												{successBulk}
											</div>
										)}
										{errorBulk && (
											<div className="text-red-500 text-kacha-text7 mt-5 p-2 bg-white rounded-md w-[300px]">
												<span className="text-red-500">
													{errorBulk.first_name ||
														errorBulk.middle_name ||
														errorBulk.last_name ||
														errorBulk.gender ||
														errorBulk.martial_status ||
														errorBulk.phone ||
														errorBulk.employer ||
														errorBulk.date_of_employment ||
														errorBulk.gross_salary ||
														errorBulk.net_salary ||
														errorBulk.account_number ||
														errorBulk.position ||
														errorBulk.total_work_experience ||
														errorBulk.others}
												</span>{" "}
											</div>
										)}

										<div className="mt-5 flex space-x-4">
											<div className="bg-kacha-orange-700 text-center cursor-pointer p-3 rounded-md text-lg">
												<button
													className="text-center cursor-pointer text-kacha-text8 text-white"
													type="button"
													onClick={() => handleFileUpload(formik.values)}
												>
													Preview File
												</button>
											</div>

											<div className="bg-kacha-orange-700 text-center cursor-pointer p-3 w-[180px] rounded-md text-lg">
												<button
													className={`text-center ${
														loading
															? "opasity-50 cursor-not-allowed"
															: "cursor-pointer"
													} text-kacha-text8 text-white`}
													type="submit"
													onClick={() => setLoading(true)}
												>
													{console.log(loading)}
													{loading ? (
														<div className="spinner-container">
															<ClipLoader
																color="#fff"
																loading={loading}
																size={25}
															/>
														</div>
													) : (
														"Upload for Approval"
													)}
												</button>
											</div>
										</div>
									</div>
								</Form>
							)}
						</Formik>

						<FilePreviewModal
							isOpenBulkModal={isBulkModalOpen}
							closeModalDialog={closeModalDialog}
							fileContent={bulkData}
						/>
						<div className="flex flex-col">
							<h1 className="text-center font-montserrat text-kacha-text6 pb-4 pt-8">
								Need to know employer code? Search below.
							</h1>
							<div className="flex justify-end mb-4 ">
								<input
									type="text"
									placeholder="Search..."
									value={searchCompany}
									onChange={(e) => setSearchCompany(e.target.value)}
									className="border border-orange-500 rounded-md px-4 py-2 text-kacha-text9"
								/>
							</div>
							<div className="table-container overflow-y-auto max-h-96 scrollbar-orange">
								<table className="table-auto border-collapse border border-gray-300 rounded-md">
									<thead>
										<tr>
											<th className="border border-gray-300 px-4 py-2 text-kacha-text9">
												No
											</th>
											<th className="border border-gray-300 px-4 py-2 text-kacha-text9">
												Company Name
											</th>
											<th className="border border-gray-300 px-4 py-2 text-kacha-text9">
												Company Code
											</th>
										</tr>
									</thead>
									<tbody>
										{filteredEmployers?.map((list, index) => (
											<tr
												key={list?.code}
												className={
													index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
												}
											>
												<td className="border border-gray-300 px-4 py-2 text-kacha-text9">
													{index + 1}
												</td>
												<td className="border border-gray-300 px-4 py-2 text-kacha-text9">
													{list?.name}
												</td>
												<td className="border border-gray-300 px-4 py-2 text-kacha-text9">
													{list?.code}
												</td>
											</tr>
										))}
										{filteredEmployers?.length === 0 && (
											<tr>
												<span className="text-kacha-text9 px-4 py-2 text-center justify-center">
													No Company Found
												</span>
											</tr>
										)}
									</tbody>
								</table>
							</div>

							<div className="mt-5" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RegistorEmployee;
