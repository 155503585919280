import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { base_url } from "../api/Axios";
import peaceLogo from "../images/peace-logo.png";
import { registorEmployee } from "../utils/Validation";

const RegistorEmployee = () => {
	const [consent, setConsent] = useState({
		phone: "",
		acountType: "",
		fullName: "",
		email: "",
		employeeId: "",
		districtName: "",
		branchName: "",
	});
	const [error, setError] = useState();
	const handleRegistration = async (value) => {
		console.log(value);
		const result = await axios
			.get(`${base_url}consent/`, value)
			.then((response) => {
				console.log(response);
			})
			.catch((error) => {
				console.log(error);
				setError(error.message);
			});

		setConsent(value);
		console.log(value);
	};
	return (
		<div className="relative  bg-white">
			<div className="  responsive-aware-form flex flex-col items-center justify-center  mt-8 pb-8">
				<div className=" flex flex-col item-center shadow-kacha-box-shadow items-center  justify-center p-28">
					<img src={peaceLogo} alt="logo" className="h-14 pb-2" />
					<h1 className="text-center font-montserrata text-kacha-text3">
						Register Account
					</h1>
					<h4 className="m-5 text-center text-kacha-gray-500 text-kacha-text8">
						Glad to have you! please complete the form below
					</h4>

					<Formik
						initialValues={{
							phone: "",
							acountType: "",
							fullName: "",
							email: "",
							employeeId: "",
							districtName: "",
							branchName: "",
						}}
						validationSchema={registorEmployee}
						onSubmit={(values) => {
							handleRegistration(values);
						}}
					>
						{(formik) => (
							<Form>
								<div className="mt-5" />

								<div className="flex flex-col">
									<label className="mb-1 text-kacha-text9">Phone Number</label>
									<Field
										className="border border-gray-300 w-[360px] p-3 rounded-md text-kacha-text9"
										name="phone"
										as="input"
										type="text"
										placeholder="eg.0911124674"
									/>
									{formik.errors.phone && formik.touched.phone && (
										<div className="error text-red-500">
											{formik.errors.phone}
										</div>
									)}
								</div>
								<div className="mt-5" />

								<div className="flex flex-col">
									<label className="mb-1 text-kacha-text9">Account Type</label>
									<Field
										className="border border-gray-300 p-3 rounded-md text-kacha-text9"
										name="acountType"
										as="input"
										type="text"
										placeholder="-select One-"
									/>
									{formik.errors.acountType && formik.touched.acountType && (
										<div className="error text-red-500">
											{formik.errors.acountType}
										</div>
									)}
								</div>
								<div className="mt-5" />
								<div className="flex flex-col">
									<label className="mb-1 text-kacha-text9">Full Name</label>
									<Field
										className="border border-gray-300 p-3 rounded-md text-kacha-text9"
										name="fullName"
										as="input"
										type="text"
										placeholder="Enter Your Full Name"
									/>
									{formik.errors.fullName && formik.touched.fullName && (
										<div className="error text-red-500">
											{formik.errors.fullName}
										</div>
									)}
								</div>
								<div className="mt-5" />
								<Field
									className="border border-gray-300 w-[360px] p-3 rounded-md text-kacha-text9"
									name="email"
									as="input"
									type="text"
									placeholder="Enter company email"
								/>
								{formik.errors.email && formik.touched.email && (
									<div className="error text-red-500">
										{formik.errors.email}
									</div>
								)}
								<div className="mt-5" />

								<div className="flex flex-col">
									<label className="mb-1 text-kacha-text9">Employee ID</label>
									<Field
										className="border border-gray-300 p-3 rounded-md text-kacha-text9"
										name="employeeId"
										as="input"
										type="text"
										placeholder="Enter Employee ID"
									/>
									{formik.errors.employeeId && formik.touched.employeeId && (
										<div className="error text-red-500">
											{formik.errors.employeeId}
										</div>
									)}
								</div>
								<div className="mt-5" />
								<div className="flex flex-col">
									<label className="mb-1 text-kacha-text9">District Name</label>
									<Field
										className="border border-gray-300 p-3 rounded-md text-kacha-text9"
										name="districtName"
										as="input"
										type="text"
										placeholder="Enter Destrict Name"
									/>
									{formik.errors.districtName &&
										formik.touched.districtName && (
											<div className="error text-red-500">
												{formik.errors.districtName}
											</div>
										)}
								</div>
								<div className="mt-5" />
								<div className="flex flex-col">
									<label className="mb-1 text-kacha-text9">Branch Name</label>
									<Field
										className="border border-gray-300 p-3 rounded-md text-kacha-text9"
										name="branchName"
										as="input"
										type="text"
										placeholder="Enter Branch Name"
									/>
									{formik.errors.branchName && formik.touched.branchName && (
										<div className="error text-red-500">
											{formik.errors.branchName}
										</div>
									)}
								</div>
								<div className="mt-5" />
								{error && (
									<div className="text-red-500 p-2">
										Please Try Again. {error} !!
									</div>
								)}
								<div className="mt-5" />

								<div
									className=" bg-kacha-orange-700 text-center cursor-pointer p-3  w- rounded-md text-lg"
									type="submit"
								>
									<button
										className="text-center cursor-pointer text-kacha-text8 text-white"
										type="submit"
										disabled={
											!formik.values.fullName ||
											!formik.values.phone ||
											!formik.values.acountType ||
											formik.errors.phone ||
											formik.errors.acountType ||
											Object.keys(formik.touched).length <= 0
										}
									>
										Proceed
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default RegistorEmployee;
