import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { Router } from "react-router-dom";
import { base_url } from "../api/Axios";
import { useAuth } from "../context/AuthContext";
import peaceLogo from "../images/peace-logo.png";
import { contact } from "../utils/Validation";

const Contact = () => {
	const [cookie, setCookie] = useCookies();
	const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
	const [contsctForm, setContactForm] = useState({
		email: "Contact@Kachadfs.com",
		opnion: "",
	});
	const [error, setError] = useState();
	const submit = async (value) => {
		console.log(value);
		const result = await axios
			.put(`${base_url}contact/`, value)
			.then((response) => {
				console.log(response);
				Router.push("/");
			})
			.catch((error) => {
				console.log(error);
				setError(error.message);
			});

		setContactForm(value);
		console.log(contsctForm);
	};
	return (
		<div className="relative  bg-white">
			<div className="  responsive-aware-form flex flex-col items-center justify-center  mt-8 pb-8">
				<div className=" flex flex-col item-center shadow-kacha-box-shadow items-center  justify-center p-28">
					{error && <div className="text-red-500 p-2">{error} !!</div>}
					<img src={peaceLogo} alt="logo" className="h-14 pb-2" />
					<h1 className="text-center font-montserrata text-kacha-text3">
						Contact@Kachadfs.com
					</h1>
					<h4 className="m-5 text-center text-kacha-gray-500 text-kacha-text8">
						Kindly provide your opnion
					</h4>

					<Formik
						initialValues={{
							email: "Contact@Kachadfs.com",
							opnion: "",
						}}
						validationSchema={contact}
						onSubmit={(values) => {
							submit(values);
						}}
					>
						{(formik) => (
							<Form>
								<div className="mt-5" />

								<div className="flex flex-col">
									<Field
										className="border border-gray-300 w-[360px] p-3 rounded-md text-kacha-text9"
										name="opnion"
										as="textarea"
										type="text"
										placeholder="Enter your opnion"
									/>
									{formik.errors.defaultPassword &&
										formik.touched.defaultPassword && (
											<div className="error text-red-500">
												{formik.errors.defaultPassword}
											</div>
										)}
								</div>
								<div className="mt-5" />

								<div className="mt-5" />
								<div
									className=" bg-kacha-orange-700 text-center cursor-pointer p-3  w- rounded-md text-lg"
									type="submit"
								>
									<button
										className="text-center cursor-pointer text-kacha-text8 text-white"
										type="submit"
									>
										Submit
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default Contact;
