import React from "react";
import { RingLoader, CircleLoader, ClipLoader } from "react-spinners";

const Loader = () => {
	return (
		<div className="h-[80vh] flex items-center justify-center">
			<div className="spinner-container">
				<ClipLoader color="#fff000" size={50} />
			</div>
		</div>
	);
};

export default Loader;
