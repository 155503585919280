import React from "react";
import user_guide from "../images/crps-icon.png";

const UserGuide = () => {
	const pdfPath = "/CRPS User Guide.pdf";
	const openPdfInNewTab = () => {
		// window.open(pdfPath, '_blank');
	};
	return (
		<div>
			<div className="flex h-[70vh] justify-center items-center text-center ">
				<img
					src={user_guide}
					alt="PDF Preview"
					onClick={openPdfInNewTab}
					style={{ cursor: "pointer" }}
					className="w-[70vh] h-[60vh]"
				/>
			</div>
			<div className=" text-kacha-text5  p-4 justify-center items-center text-center">
				Coming Soon...
			</div>
		</div>
	);
};

export default UserGuide;
