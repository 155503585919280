import { ExclamationCircleIcon } from "@heroicons/react/solid";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";
import Cookies from "universal-cookie";
import { useAuth } from "../context/AuthContext";
import corporateIcon from "../images/corporation.png";
import inactiveIcon from "../images/inactive.png";
import userIcon from "../images/user-icon.png";

import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { base_url } from "../api/Axios";
import { DashboardDetailModal } from "../widgets/DashboardDetailModal";
import Logout from "./Logout";

export class SearchParams {
	first = 0;
	rows = 10;
	searchText = "";
	sortColumn = "";
	sortDirection = 1;
}

export class SearchParamsEmployee {
	first = 0;
	rows = 10;
	searchText = "";
	sortColumn = "";
	sortDirection = 1;
}

const Dashboard = () => {
	const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
	const cookies = new Cookies();
	const navigate = useNavigate();
	const token = cookies.get("token");
	const [orgs, setOrgs] = useState();
	const [company, setCompany] = useState(null);
	const [fetchCompanyError, setFetchCompanyError] = useState();
	const [employee, setEmployee] = useState(null);
	const [fetchEmployeeError, setFetchEmployeeError] = useState();

	const [showComanyDetails, setShowCompanyDetails] = useState(false);
	const [showEmployeeDetails, setShowEmployeeDetails] = useState(false);
	const [showApprovedEmployeeDetails, setShowApprovedEmployeeDetails] =
		useState(false);
	const [showApprovedCompanyDetails, setShowApprovedCompanyDetails] =
		useState(false);
	const [showPendingEmployeeDetails, setShowPendingEmployeeDetails] =
		useState(false);
	const [searchParams, setSearchParams] = useState(new SearchParams());
	const [searchParamsEmployee, setSearchParamsEmployee] = useState(
		new SearchParamsEmployee()
	);
	const [approvedCount, setApprovedCount] = useState(0);
	const [pendingCount, setPendingCount] = useState(0);
	const [disapprovedCount, setDisapprovedCount] = useState(0);
	const [disabledCount, setDisabledCount] = useState(0);
	const [removedCount, setRemovedCount] = useState(0);
	const [dormantCount, setDormantCount] = useState(0);
	const [suspendedCount, setSuspendedCount] = useState(0);
	const [inactiveCount, setInactiveCount] = useState(0);

	const [approvedCompanyCount, setApprovedCompanyCount] = useState(0);
	const [pendingCompanyCount, setPendingCompanyCount] = useState(0);
	const [disapprovedCompanyCount, setDisapprovedCompanyCount] = useState(0);
	const [disabledCompanyCount, setDisabledCompanyCount] = useState(0);
	const [removedCompanyCount, setRemovedCompanyCount] = useState(0);
	const [dormantCompanyCount, setDormantCompanyCount] = useState(0);
	const [suspendedCompanyCount, setSuspendedCompanyCount] = useState(0);
	const [inactiveCompanyCount, setInactiveCompanyCount] = useState(0);
	const [companyError, setCompanyError] = useState();
	const [employeeError, setEmployeeError] = useState();
	const [clicked, setClicked] = useState(false);
	const [selectedDuration, setSelectedDuration] = useState("");
	const [selectedEmployee, setSelectedEmployee] = useState(null);
	const [employeeDetial, setEmployeeDetial] = useState(null);
	const [companyDetial, setCompanyDetial] = useState(null);
	const [fetchEmployeeErrorDetial, setFetchEmployeeErrorDetial] = useState();
	const [fetchCompanyErrorDetial, setFetchCompanyErrorDetial] = useState();
	const [url, setUrl] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [prevFilterOption, setPrevFilterOption] = useState("");

	useEffect(() => {
		const fetchOrg = async () => {
			const employerList = await axios
				.get(`${base_url}orgs_list/`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then((response) => {
					setOrgs(response?.data?.sort((a, b) => a.name.localeCompare(b.name)));
				})
				.catch((error) => {
					if (error?.response?.status === 401) {
						Logout();
						setCompanyError(error?.response?.data[0]);
						setTimeout(() => {
							setCompanyError("");
						}, 2000);
						navigate("/login");
					}
					setCompanyError(error?.message);
					setTimeout(() => {
						setCompanyError("");
					}, 2000);
				});
		};
		fetchOrg();
	}, []);

	// Monthly data for line chart
	const [monthlyData, setMonthlyData] = useState({
		jan: 0,
		feb: 0,
		mar: 0,
		apr: 0,
		may: 0,
		jun: 0,
		jul: 0,
		aug: 0,
		sep: 0,
		oct: 0,
		nov: 0,
		dec: 0,
	});

	const [monthlyEmployeeData, setMonthlyEmployeeData] = useState({
		jan: 0,
		feb: 0,
		mar: 0,
		apr: 0,
		may: 0,
		jun: 0,
		jul: 0,
		aug: 0,
		sep: 0,
		oct: 0,
		nov: 0,
		dec: 0,
	});

	const handleCompanyViewDetails = () => {
		setShowCompanyDetails(!showComanyDetails);
		if (showEmployeeDetails) {
			setShowEmployeeDetails(!showEmployeeDetails);
		}
	};

	const handleViewEmployeeDetails = () => {
		setShowEmployeeDetails(!showEmployeeDetails);
		if (showComanyDetails) {
			setShowCompanyDetails(!showComanyDetails);
		}
	};

	const handleApprovedEmployeeAndCompanyDetails = () => {
		setShowApprovedEmployeeDetails(!showApprovedEmployeeDetails);
		setShowApprovedCompanyDetails(!showApprovedCompanyDetails);
		setShowPendingEmployeeDetails(false);
	};

	const handlePendingEmployeeDetails = () => {
		setShowPendingEmployeeDetails(!showPendingEmployeeDetails);
		setShowApprovedEmployeeDetails(false);
		setShowApprovedCompanyDetails(false);
	};

	const handleClicked = () => {
		setClicked(!clicked);
	};

	const openModal = (employeeName, url, employeeInfo) => {
		setSelectedEmployee(employeeName);
		setUrl(url);
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
		//window.location.reload();
	};

	const lineChartData = {
		series: [
			{
				name: "Registration Trend for Employees",
				data: [
					monthlyEmployeeData.sep,
					monthlyEmployeeData.oct,
					monthlyEmployeeData.nov,
					monthlyEmployeeData.dec,
					monthlyEmployeeData.jan,
					monthlyEmployeeData.feb,
					monthlyEmployeeData.mar,
					monthlyEmployeeData.apr,
					monthlyEmployeeData.may,
					monthlyEmployeeData.jun,
					monthlyEmployeeData.jul,
					monthlyEmployeeData.aug,
				],
			},
			{
				name: "Registration Trend for Companies",
				data: [
					monthlyData.sep,
					monthlyData.oct,
					monthlyData.nov,
					monthlyData.dec,
					monthlyData.jan,
					monthlyData.feb,
					monthlyData.mar,
					monthlyData.apr,
					monthlyData.may,
					monthlyData.jun,
					monthlyData.jul,
					monthlyData.aug,
				],
			},
		],
		options: {
			xaxis: {
				categories: [
					"Sep",
					"Oct",
					"Nov",
					"Dec",
					"Jan",
					"Feb",
					"Mar",
					"Apr",
					"May",
					"Jun",
					"Jul",
					"Aug",
				],
			},
			title: {
				text: "Total Statistics",
				align: "left",
				style: {
					fontSize: "14px",
				},
			},
			colors: ["#fdb913", "#4A8AF7"],
		},
	};

	// Bar Chart Data and Options
	const barChartData = {
		series: [
			{
				name: "Employees",
				data: [approvedCount, pendingCount, disapprovedCount, suspendedCount],
			},

			{
				name: "Companies",
				data: [
					approvedCompanyCount,
					pendingCompanyCount,
					disapprovedCompanyCount,
					suspendedCompanyCount,
				],
			},
		],
		options: {
			xaxis: {
				categories: ["Active", "Pending", "Declined", "Inactive"],
			},
			colors: ["#fdb913", "#4A8AF7"],
			title: {
				text: "Status Graph",
				align: "left",
				style: {
					fontSize: "14px",
				},
			},
		},
	};

	const pieChartData = {
		series: [approvedCount, pendingCount, disapprovedCount, inactiveCount],
		options: {
			labels: ["Active", "Pending", "Declined", "Inactive"],
			colors: ["#4CB963", "#fdb913", "#4A8AF7", "#fd1350"],
			legend: {
				show: true,
				position: "bottom",
			},
			title: {
				text: "Employee Status Distribution",
				align: "left",
				style: {
					fontSize: "14px",
				},
			},
		},
	};

	useEffect(() => {
		const fetchCompanyDetail = async () => {
			try {
				const response = await axios.get(`${url}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setCompanyDetial(response?.data);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setFetchCompanyErrorDetial(error?.response?.data[0]);
					setTimeout(() => {
						setFetchCompanyErrorDetial("");
					}, 2000);
					navigate("/login");
				}
				setFetchCompanyErrorDetial(error?.response?.data[0]);
				console.error("Error fetching company data:", fetchCompanyError);
			}
		};
		fetchCompanyDetail();
	}, [url]);

	useEffect(() => {
		const fetchEmployeeDetial = async () => {
			try {
				const response = await axios.get(`${url}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setEmployeeDetial(response?.data);
				console.log(employeeDetial);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setFetchEmployeeErrorDetial(error?.response?.data[0]);
					setTimeout(() => {
						setFetchEmployeeErrorDetial("");
					}, 2000);
					navigate("/login");
				}
				setFetchEmployeeErrorDetial(error?.response?.data[0]);
				console.error("Error fetching employee data:", fetchEmployeeError);
			}
		};
		fetchEmployeeDetial();
	}, [url]);

	useEffect(() => {
		const fetchCompany = async () => {
			try {
				const response = await axios.get(
					`${base_url}orgs/?f=${searchParams.first}&limit=${searchParams.rows}&offset=${searchParams.first}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setCompany(response?.data);
				const companyData = response?.data?.results?.map(
					(companyList, index) => {
						if (companyList?.created_at?.includes("2024-01")) {
							monthlyData.jan = monthlyData.jan + 1;
						}
						if (companyList?.created_at?.includes("2023-02")) {
							monthlyData.feb = monthlyData.feb + 1;
						}
						if (companyList?.created_at?.includes("2023-03")) {
							monthlyData.mar = monthlyData.mar + 1;
						}
						if (companyList?.created_at?.includes("2023-04")) {
							monthlyData.apr = monthlyData.apr + 1;
						}
						if (companyList?.created_at?.includes("2023-05")) {
							monthlyData.may = monthlyData.may + 1;
						}
						if (companyList?.created_at?.includes("2023-06")) {
							monthlyData.jun = monthlyData.jun + 1;
						}
						if (companyList?.created_at?.includes("2023-07")) {
							monthlyData.jul = monthlyData.jul + 1;
						}
						if (companyList?.created_at?.includes("2023-08")) {
							monthlyData.aug = monthlyData.aug + 1;
						}
						if (companyList?.created_at?.includes("2023-09")) {
							monthlyData.sep = monthlyData.sep + 1;
						}
						if (companyList?.created_at?.includes("2023-10")) {
							monthlyData.oct = monthlyData.oct + 1;
						}
						if (companyList?.created_at?.includes("2023-11")) {
							monthlyData.nov = monthlyData.nov + 1;
						}
						if (companyList?.created_at?.includes("2023-12")) {
							monthlyData.dec = monthlyData.dec + 1;
						}
						setMonthlyData(monthlyData);
					}
				);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setFetchCompanyError(error?.response?.data[0]);
					setTimeout(() => {
						setFetchCompanyError("");
					}, 2000);
					navigate("/login");
				}
				setFetchCompanyError(error?.response?.data[0]);
				console.error("Error fetching company data:", fetchCompanyError);
			}
		};

		fetchCompany();
	}, [searchParams]);

	useEffect(() => {
		const fetchEmployee = async () => {
			try {
				const response = await axios.get(
					`${base_url}employees/?f=${searchParamsEmployee.first}&limit=${searchParamsEmployee.rows}&offset=${searchParamsEmployee.first}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setEmployee(response.data);
				const employeeData = response?.data?.results?.map(
					(employeeList, index) => {
						if (employeeList?.created_at?.includes("2024-01")) {
							monthlyEmployeeData.jan = monthlyEmployeeData.jan + 1;
						}
						if (employeeList?.created_at?.includes("2023-02")) {
							monthlyEmployeeData.feb = monthlyEmployeeData.feb + 1;
						}
						if (employeeList?.created_at?.includes("2023-03")) {
							monthlyEmployeeData.mar = monthlyEmployeeData.mar + 1;
						}
						if (employeeList?.created_at?.includes("2023-04")) {
							monthlyEmployeeData.apr = monthlyEmployeeData.apr + 1;
						}
						if (employeeList?.created_at?.includes("2023-05")) {
							monthlyEmployeeData.may = monthlyEmployeeData.may + 1;
						}
						if (employeeList?.created_at?.includes("2023-06")) {
							monthlyEmployeeData.jun = monthlyEmployeeData.jun + 1;
						}
						if (employeeList?.created_at?.includes("2023-07")) {
							monthlyEmployeeData.jul = monthlyEmployeeData.jul + 1;
						}
						if (employeeList?.created_at?.includes("2023-08")) {
							monthlyEmployeeData.aug = monthlyEmployeeData.aug + 1;
						}
						if (employeeList?.created_at?.includes("2023-09")) {
							monthlyEmployeeData.sep = monthlyEmployeeData.sep + 1;
						}
						if (employeeList?.created_at?.includes("2023-10")) {
							monthlyEmployeeData.oct = monthlyEmployeeData.oct + 1;
						}
						if (employeeList?.created_at?.includes("2023-11")) {
							monthlyEmployeeData.nov = monthlyEmployeeData.nov + 1;
						}
						if (employeeList?.created_at?.includes("2023-12")) {
							monthlyEmployeeData.dec = monthlyEmployeeData.dec + 1;
						}

						setMonthlyEmployeeData(monthlyEmployeeData);
					}
				);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setFetchEmployeeError(error?.response?.data[0]);
					setTimeout(() => {
						setFetchEmployeeError("");
					}, 2000);
					navigate("/login");
				}
				setFetchEmployeeError(error?.response?.data[0]);
				console.error("Error fetching employee data:", fetchEmployeeError);
			}
		};

		fetchEmployee();
	}, [searchParamsEmployee]);

	useEffect(() => {
		const fetchEmployee = async () => {
			try {
				const response = await axios.get(
					`${base_url}employees/?status=ACTIVE`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				// setEmployee(response.data);
				setApprovedCount(response?.data?.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setEmployeeError(error?.response?.data[0]);
					setTimeout(() => {
						setEmployeeError("");
					}, 2000);
					navigate("/login");
				}
				setEmployeeError(error?.response?.data[0]);
				console.error("Error fetching employee data:", fetchEmployeeError);
			}
		};

		fetchEmployee();
	}, []);

	useEffect(() => {
		const fetchEmployee = async () => {
			try {
				const response = await axios.get(
					`${base_url}employees/?status=PENDING`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				// setEmployee(response.data);
				setPendingCount(response.data.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setEmployeeError(error?.response?.data[0]);
					setTimeout(() => {
						setEmployeeError("");
					}, 2000);
					navigate("/login");
				}
				setEmployeeError(error?.response?.data[0]);
				console.error("Error fetching employee data:", fetchEmployeeError);
			}
		};

		fetchEmployee();
	}, []);

	useEffect(() => {
		const fetchEmployee = async () => {
			try {
				const response = await axios.get(
					`${base_url}employees/?status=DISAPPROVED`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setDisapprovedCount(response?.data?.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setEmployeeError(error?.response?.data[0]);
					setTimeout(() => {
						setEmployeeError("");
					}, 2000);
					navigate("/login");
				}
				setEmployeeError(error?.response?.data[0]);
				console.error("Error fetching employee data:", fetchEmployeeError);
			}
		};

		fetchEmployee();
	}, []);
	useEffect(() => {
		const fetchEmployee = async () => {
			try {
				const response = await axios.get(
					`${base_url}employees/?status=DISABLED`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setDisabledCount(response?.data?.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setEmployeeError(error?.response?.data[0]);
					setTimeout(() => {
						setEmployeeError("");
					}, 2000);
					navigate("/login");
				}
				setEmployeeError(error?.response?.data[0]);
				console.error("Error fetching employee data:", fetchEmployeeError);
			}
		};

		fetchEmployee();
	}, []);

	// const employeeStatus = [
	// 	{
	// 		id: 1,
	// 		name: "ACTIVE",
	// 	},
	// 	{
	// 		id: 2,p[]
	// 		name: "PENDING",
	// 	},
	// 	{
	// 		id: 3,
	// 		name: "DISAPPROVED",
	// 	},
	// 	{
	// 		id: 4,
	// 		name: "DISABLED",
	// 	},
	// 	{
	// 		id: 5,
	// 		name: "REMOVED",
	// 	},
	// 	{
	// 		id: 6,
	// 		name: "DORMANT",
	// 	},
	// 	{
	// 		id: 7,
	// 		name: "SUSPENDED",
	// 	},
	// 	{
	// 		id: 8,
	// 		name: "INACTIVE",
	// 	},
	// ];

	// useEffect(() => {
	// 	const fetchEmployee = async () => {
	// 		try {
	// 			employeeStatus.map((status) => {
	// 				const response = axios.get(
	// 					`${base_url}employees/?status=${status.name}`,
	// 					{
	// 						headers: {
	// 							Authorization: `Bearer ${token}`,
	// 						},
	// 					}
	// 				);
	// 				console.log(response.data.count)
	// 				if(status.name === "ACTIVE"){
	// 					setApprovedCount(response.data.count);
	// 				}
	// 				if(status.name === "PENDING"){
	// 					setPendingCount(response.data.count);
	// 				}
	// 				if(status.name === "DISAPPROVED"){
	// 					setDisapprovedCount(response.data.count);
	// 				}
	// 				if(status.name === "DISABLED"){
	// 					setDisabledCount(response.data.count);
	// 				}
	// 				if(status.name === "REMOVED"){
	// 					setRemovedCount(response.data.count);
	// 				}
	// 				if(status.name === "DORMANT"){
	// 					setDormantCount(response.data.count);
	// 				}
	// 				if(status.name === "SUSPENDED"){
	// 					setSuspendedCount(response.data.count);
	// 				}
	// 				if(status.name === "INACTIVE"){
	// 					setInactiveCount(response.data.count);
	// 				}

	// 			})
	// 		} catch (error) {
	// 			setEmployeeError(error?.response?.data[0]);
	// 			console.error("Error fetching employee data:", fetchEmployeeError);
	// 		}
	// 	};
	// 	fetchEmployee();
	// }, []);

	useEffect(() => {
		const fetchEmployee = async () => {
			try {
				const response = await axios.get(
					`${base_url}employees/?status=REMOVED`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setRemovedCount(response?.data?.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setEmployeeError(error?.response?.data[0]);
					setTimeout(() => {
						setEmployeeError("");
					}, 2000);
					navigate("/login");
				}
				setEmployeeError(error?.response?.data[0]);
				console.error("Error fetching employee data:", fetchEmployeeError);
			}
		};

		fetchEmployee();
	}, []);

	useEffect(() => {
		const fetchEmployee = async () => {
			try {
				const response = await axios.get(
					`${base_url}employees/?status=DORMANT`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setDormantCount(response?.data?.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setEmployeeError(error?.response?.data[0]);
					setTimeout(() => {
						setEmployeeError("");
					}, 2000);
					navigate("/login");
				}
				setEmployeeError(error?.response?.data[0]);
				console.error("Error fetching employee data:", fetchEmployeeError);
			}
		};

		fetchEmployee();
	}, []);

	useEffect(() => {
		const fetchEmployee = async () => {
			try {
				const response = await axios.get(
					`${base_url}employees/?status=SUSPENDED`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setSuspendedCount(response?.data?.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setEmployeeError(error?.response?.data[0]);
					setTimeout(() => {
						setEmployeeError("");
					}, 2000);
					navigate("/login");
				}
				setEmployeeError(error?.response?.data[0]);
				console.error("Error fetching employee data:", fetchEmployeeError);
			}
		};

		fetchEmployee();
	}, []);

	useEffect(() => {
		const fetchEmployee = async () => {
			try {
				const response = await axios.get(
					`${base_url}employees/?status=INACTIVE`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setInactiveCount(response?.data?.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setEmployeeError(error?.response?.data[0]);
					setTimeout(() => {
						setEmployeeError("");
					}, 2000);
					navigate("/login");
				}
				setEmployeeError(error?.response?.data[0]);
				console.error("Error fetching employee data:", fetchEmployeeError);
			}
		};

		fetchEmployee();
	}, []);

	useEffect(() => {
		const fetchCompany = async () => {
			try {
				const response = await axios.get(`${base_url}orgs/?status=ACTIVE`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setApprovedCompanyCount(response.data.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setCompanyError(error?.response?.data[0]);
					setTimeout(() => {
						setCompanyError("");
					}, 2000);
					navigate("/login");
				}
				setCompanyError(error?.response?.data[0]);
				console.error("Error fetching Company data:", fetchCompanyError);
			}
		};

		fetchCompany();
	}, []);

	useEffect(() => {
		const fetchCompany = async () => {
			try {
				const response = await axios.get(`${base_url}orgs/?status=PENDING`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setPendingCompanyCount(response?.data?.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setCompanyError(error?.response?.data[0]);
					setTimeout(() => {
						setCompanyError("");
					}, 2000);
					navigate("/login");
				}
				setCompanyError(error?.response?.data[0]);
				console.error("Error fetching Company data:", fetchCompanyError);
			}
		};

		fetchCompany();
	}, []);

	useEffect(() => {
		const fetchCompany = async () => {
			try {
				const response = await axios.get(
					`${base_url}orgs/?status=DISAPPROVED`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setDisapprovedCompanyCount(response?.data?.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setCompanyError(error?.response?.data[0]);
					setTimeout(() => {
						setCompanyError("");
					}, 2000);
					navigate("/login");
				}
				setCompanyError(error?.response?.data[0]);
				console.error("Error fetching Company data:", fetchCompanyError);
			}
		};

		fetchCompany();
	}, []);

	useEffect(() => {
		const fetchCompany = async () => {
			try {
				const response = await axios.get(`${base_url}orgs/?status=DISABLED`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setDisabledCompanyCount(response?.data?.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setCompanyError(error?.response?.data[0]);
					setTimeout(() => {
						setCompanyError("");
					}, 2000);
					navigate("/login");
				}
				setCompanyError(error?.response?.data[0]);
				console.error("Error fetching Company data:", fetchCompanyError);
			}
		};

		fetchCompany();
	}, []);

	useEffect(() => {
		const fetchCompany = async () => {
			try {
				const response = await axios.get(`${base_url}orgs/?status=REMOVED`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setRemovedCompanyCount(response?.data?.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setCompanyError(error?.response?.data[0]);
					setTimeout(() => {
						setCompanyError("");
					}, 2000);
					navigate("/login");
				}
				setCompanyError(error?.response?.data[0]);
				console.error("Error fetching Company data:", fetchCompanyError);
			}
		};

		fetchCompany();
	}, []);

	useEffect(() => {
		const fetchCompany = async () => {
			try {
				const response = await axios.get(`${base_url}orgs/?status=DORMANT`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setDormantCompanyCount(response?.data?.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setCompanyError(error?.response?.data[0]);
					setTimeout(() => {
						setCompanyError("");
					}, 2000);
					navigate("/login");
				}
				setCompanyError(error?.response?.data[0]);
				console.error("Error fetching Company data:", fetchCompanyError);
			}
		};

		fetchCompany();
	}, []);

	useEffect(() => {
		const fetchCompany = async () => {
			try {
				const response = await axios.get(`${base_url}orgs/?status=SUSPENDED`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setSuspendedCompanyCount(response?.data?.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setCompanyError(error?.response?.data[0]);
					setTimeout(() => {
						setCompanyError("");
					}, 2000);
					navigate("/login");
				}
				setCompanyError(error?.response?.data[0]);
				console.error("Error fetching Company data:", fetchCompanyError);
			}
		};

		fetchCompany();
	}, []);

	useEffect(() => {
		const fetchCompany = async () => {
			try {
				const response = await axios.get(`${base_url}orgs/?status=INACTIVE`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setInactiveCompanyCount(response?.data?.count);
			} catch (error) {
				if (error?.response?.status === 401) {
					Logout();
					setCompanyError(error?.response?.data[0]);
					setTimeout(() => {
						setCompanyError("");
					}, 2000);
					navigate("/login");
				}
				setCompanyError(error?.response?.data[0]);
				console.error("Error fetching Company data:", fetchCompanyError);
			}
		};

		fetchCompany();
	}, []);

	const goNext = () => {
		if (searchParams.first + searchParams.rows < company.count) {
			setSearchParams({
				...searchParams,
				first: searchParams.first + searchParams.rows,
			});
		}
	};

	const goBack = () => {
		if (searchParams.first > 0) {
			setSearchParams({
				...searchParams,
				first: searchParams.first - searchParams.rows,
			});
		}
	};

	const goNextEmployee = () => {
		if (
			searchParamsEmployee.first + searchParamsEmployee.rows <
			employee.count
		) {
			setSearchParamsEmployee({
				...searchParamsEmployee,
				first: searchParamsEmployee.first + searchParamsEmployee.rows,
			});
		}
	};

	const goBackEmployee = () => {
		if (searchParamsEmployee.first > 0) {
			setSearchParamsEmployee({
				...searchParamsEmployee,
				first: searchParamsEmployee.first - searchParamsEmployee.rows,
			});
		}
	};

	const handleDuration = (e) => {
		setSelectedDuration(e.target.value);
	};

	const displayCompany =
		company?.results?.map((companyList, index) => ({
			url: companyList?.url,
			name: companyList?.name,
			phone: companyList?.phone,
			renewal_status: companyList?.renewal_status,
			tin_value: companyList?.tin_value,
			created: companyList?.created_at,
			updated: companyList?.updated_at,
			status: companyList?.status,
		})) || null;

	const displayEmployee =
		employee?.results?.map((employeeeList, index) => ({
			url: employeeeList?.url,
			name: `${employeeeList?.first_name} ${
				employeeeList?.last_name ? employeeeList?.last_name : ""
			}`,
			account_number: employeeeList?.account_number,
			phone: employeeeList?.phone,
			position: employeeeList?.position,
			gross_salary: employeeeList?.gross_salary,
			created: employeeeList?.created_at,
			updated: employeeeList?.updated_at,
			status: employeeeList?.status,
		})) || null;

	return (
		<div className="relative bg-gray-100 min-h-screen">
			<nav className="bg-gray-800 p-4">
				<div className="container mx-auto flex justify-between items-center">
					<div className="flex items-center text-center">
						<span className="text-white text-kacha-text4 font-bold ">
							Company and Employee Analysis
						</span>
					</div>
					<div className="flex items-center mr-48">
						{/* <nav className="flex items-center text-white font-semibold text-sm">
							<button
								className="py-2 px-2 hover:bg-orange-500 rounded"
								onClick={() => setSelectedDuration("day")}
							>
								Day
							</button>
							<button
								className="py-2 px-2 hover:bg-orange-500 rounded"
								onClick={() => setSelectedDuration("week")}
							>
								{" "}
								Week{" "}
							</button>
							<button
								className="py-2 px-2 hover:bg-orange-500 rounded"
								onClick={() => setSelectedDuration("month")}
							>
								{" "}
								Month{" "}
							</button>
							<button
								className="py-2 px-2 hover:bg-orange-500 rounded"
								onClick={() => setSelectedDuration("year")}
							>
								{" "}
								Year{" "}
							</button>
						</nav> */}
					</div>
				</div>
			</nav>
			<div className="container mx-auto p-4">
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
					<div className="bg-white p-4 rounded-md shadow-lg">
						<div className="flex items-center justify-between">
							<h2 className="text-xl font-bold mb-4">Number of Companies</h2>
							<img
								className="w-8 h-8"
								src={corporateIcon}
								alt="Corporation Icon"
							/>
						</div>

						<p className="text-lg font-semibold text-gray-800">
							{company?.count}
						</p>
						<button
							className="mt-4 bg-kacha-orange-700 text-white px-4 py-2 rounded"
							onClick={handleCompanyViewDetails}
							href="#company"
						>
							<a href="#company">
								{showComanyDetails ? "See Less" : "View List"}
							</a>
						</button>
					</div>
					{/* Card 2 */}
					<div className="bg-white p-4 rounded-md shadow-lg">
						<div className="flex items-center justify-between">
							<h2 className="text-xl font-bold mb-4">Number of Employees</h2>
							<img className="w-8 h-8" src={userIcon} alt="User Icon" />
						</div>
						<p className="text-lg font-semibold text-gray-800">
							{employee?.count}
						</p>
						<button
							className="mt-4 bg-[#fdb913] text-white px-4 py-2 rounded"
							onClick={handleViewEmployeeDetails}
							href="#employee"
						>
							<a href="#employee">
								{" "}
								{showEmployeeDetails ? "See Less" : "View List"}
							</a>
						</button>
					</div>
					{/* Card 3 */}
					<div className="bg-white p-4 rounded-md shadow-lg">
						<div className="flex items-center justify-between">
							<h2 className="text-xl font-bold mb-4">Active Employees</h2>
							<img className="w-8 h-8" src={userIcon} alt="User Icon" />
						</div>
						<p className="text-lg font-semibold text-gray-800">
							{approvedCount}
						</p>
						{showPendingEmployeeDetails && (
							<>
								<h2 className="text-xl font-bold mb-4">Pending Employees</h2>
								<p className="text-lg font-semibold text-gray-800">
									{pendingCount}
								</p>
								<h2 className="text-xl font-bold mb-4">Declined Employees</h2>
								<p className="text-lg font-semibold text-gray-800">
									{disapprovedCount}
								</p>
							</>
						)}
						<div className="flex items-center ">
							<button
								className="mt-4 bg-kacha-orange-700 text-white px-4 py-2 rounded"
								onClick={handlePendingEmployeeDetails}
							>
								{showPendingEmployeeDetails ? "See Less" : "View Details"}
							</button>
						</div>
					</div>

					{/* Card 4 */}
					<div className="bg-white p-4 rounded-md shadow-lg">
						<div className="flex items-center justify-between">
							<h2 className="text-xl font-bold mb-4">Active Companies</h2>
							<img
								className="w-8 h-8"
								src={corporateIcon}
								alt="Companies Icon"
							/>
						</div>
						<p className="text-lg font-semibold text-gray-800">
							{approvedCompanyCount}
						</p>
						{showApprovedCompanyDetails && (
							<>
								<h2 className="text-xl font-bold mb-4">Pending Companies</h2>
								<p className="text-lg font-semibold text-gray-800">
									{pendingCompanyCount}
								</p>
								<h2 className="text-xl font-bold mb-4">Declined Companies</h2>
								<p className="text-lg font-semibold text-gray-800">
									{disapprovedCompanyCount}
								</p>
							</>
						)}
						<div className="flex items-center ">
							<button
								className="mt-4 bg-[#fdb913] text-white px-4 py-2 rounded"
								onClick={handleApprovedEmployeeAndCompanyDetails}
							>
								{showApprovedEmployeeDetails ? "See Less" : "View Details"}
							</button>
						</div>
					</div>
				</div>

				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-2 mb-2">
					<div className="bg-white p-4 rounded-md shadow-lg">
						<Chart
							options={lineChartData.options}
							series={lineChartData.series}
							type="line"
							height={350}
						/>
					</div>

					<div className="bg-white p-4 rounded-md shadow-lg">
						<Chart
							options={barChartData.options}
							series={barChartData.series}
							type="bar"
							height={350}
						/>
					</div>

					<div className="bg-white p-4 rounded-md shadow-lg">
						<Chart
							options={pieChartData.options}
							series={pieChartData.series}
							type="donut"
							height={350}
						/>
					</div>
				</div>
			</div>
			{showComanyDetails && company.count > 0 && (
				<div
					className="min-h-[50vh] flex pt-8 px-4 flex-col justify-center items-center "
					id="company"
				>
					{fetchCompanyError ? (
						<div>Error fetching company data. Please try Agian.</div>
					) : (
						<div className="overflow-x-auto w-full py-8 px-2 border-2 border-b-gray-400">
							<table className="min-w-full  border-gray-300 ">
								<thead>
									<tr className="text-left border-2 border-b-gray-400">
										<th className="py-2 pl-2 border-b">No</th>
										<th className="py-2 pl-2 border-b">Name</th>
										<th className="py-2 px-4 border-b">Phone</th>
										<th className="py-2 px-4 border-b">TIN</th>
										<th className="py-2 px-4 border-b">Last Update Date</th>
										<th className="py-2 px-4 border-b">Status</th>
										<th className="py-2 px-4 border-b">Detail</th>
									</tr>
								</thead>
								<tbody className="align-center">
									{displayCompany?.map((companyInfo, index) => (
										<tr
											key={index}
											className={index % 2 === 0 ? "bg-gray-200" : ""}
										>
											<td className=" py-2 pl-2 border-b">
												{searchParams.first + index + 1}
											</td>
											<td className=" py-2 pl-2 border-b">
												<div className="flex items-center space-x-4">
													<span>{companyInfo.name}</span>
													<span
														className={
															companyInfo.renewal_status === "EXPIRED"
																? "blinking"
																: ""
														}
													>
														{companyInfo.renewal_status === "EXPIRED" ? (
															<img
																className="w-8 h-8"
																src={inactiveIcon}
																alt="Inactive"
															/>
														) : (
															""
														)}{" "}
													</span>
												</div>
											</td>
											<td className="py-2 px-4 border-b">
												+{companyInfo.phone}
											</td>
											<td className="py-2 px-4 border-b">
												{companyInfo.tin_value}
											</td>
											<td className="py-2 px-4 border-b">
												{companyInfo.updated
													? moment(companyInfo.updated).format("YYYY-MM-DD")
													: ""}
											</td>
											<td className="py-2 px-4 border-b">
												{companyInfo.status &&
													(companyInfo?.status === "DISAPPROVED"
														? "Declined"
														: companyInfo?.status.charAt(0).toUpperCase() +
														  companyInfo?.status.slice(1).toLowerCase())}
											</td>
											<td className="py-2 px-4 border-b">
												<i
													onClick={() =>
														openModal(
															companyInfo?.name
																? companyInfo?.name
																: "Anonymous",
															companyInfo?.url
														)
													}
													className="bg-kacha-orange-700 hover:bg-kacha-orange-600 text-white font-bold py-2 px-4 rounded w-[150px] text-center cursor-pointer"
												>
													Details
													{console.log(employeeDetial)}
												</i>
											</td>
										</tr>
									))}
								</tbody>
							</table>
							{selectedEmployee && (
								<DashboardDetailModal
									isOpen={modalIsOpen}
									closeModal={closeModal}
									selectedEmployee={selectedEmployee}
									employeeDetial={employeeDetial}
									url={url}
									type={"COMPANY"}
								/>
							)}
						</div>
					)}
					<div className="flex items-center  justify-between w-md">
						<div className="flex items-center space-x-2">
							<span className="text-gray-600">Items per page: </span>
							<div className="relative inline-block">
								<select
									className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:bg-white"
									value={searchParams.rows}
									onChange={(e) => {
										searchParams.rows = Number(e.target.value);
										setSearchParams({ ...searchParams });
									}}
								>
									<option value="10"> 10</option>
									<option value="20">20</option>
									<option value="50">50</option>
								</select>
								<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
									<svg
										className="fill-current h-4 w-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
									>
										<path d="M10 12.5l-5-5 1.5-1.5L10 9.5l3.5-3.5L15 7z" />
									</svg>
								</div>
							</div>
						</div>

						<span className="text-gray-600 px-10">
							{searchParams.first + 1} -{" "}
							{searchParams.first + searchParams.rows} of {company?.count || 0}
						</span>

						<div className="flex items-center space-x-4">
							<div>
								<CiCircleChevLeft
									className={`${
										searchParams.first === 0
											? "opacity-50 cursor-not-allowed"
											: "cursor-pointer"
									}  h-8 w-8 text-orange-700 font-bold`}
									size={30}
									onClick={goBack}
								/>
								{/* <svg
									xmlns="http://www.w3.org/2000/svg"
									className={`${
										searchParams.first === 0
											? "opacity-50 cursor-not-allowed"
											: "cursor-pointer"
									}  h-8 w-8 text-orange-700 font-bold`}
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M15 19l-7-7 7-7"
									/>
								</svg> */}{" "}
							</div>
							<div>
								<CiCircleChevRight
									className={`${
										searchParams.first + searchParams.rows >= company?.count
											? "opacity-50 cursor-not-allowed"
											: "cursor-pointer"
									}  h-8 w-8 text-orange-700 font-bold`}
									size={30}
									onClick={goNext}
								/>
								{/* <svg
									xmlns="http://www.w3.org/2000/svg"
									className={`${
										searchParams.first + searchParams.rows >= company?.count
											? "opacity-50 cursor-not-allowed"
											: "cursor-pointer"
									}  h-8 w-8  text-orange-700  font-bold`}
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M9 5l7 7-7 7"
									/>
								</svg> */}
							</div>
						</div>
					</div>
				</div>
			)}
			{showComanyDetails && company.count === 0 && (
				<div
					className="min-h-[50vh] flex pt-8 px-4 flex-col justify-center items-center "
					id="company"
				>
					<div className="flex flex-col justify-center items-center">
						<div className="flex flex-col justify-center items-center">
							<ExclamationCircleIcon className="h-10 w-10 text-orange-500" />
							<div className="text-gray-500 text-lg font-bold">
								No Registered Company Found
							</div>
						</div>
					</div>
				</div>
			)}
			{showEmployeeDetails && employee.count > 0 && (
				<div
					className="min-h-[50vh] flex flex-col pt-8 px-4 justify-center items-center "
					id="employee"
				>
					{fetchEmployeeError ? (
						<div>Error fetching employee data. Please try Agian.</div>
					) : (
						<div className="overflow-x-auto w-full py-8 px-2 border-2 border-b-gray-400">
							<table className="min-w-full  border-gray-300">
								<thead>
									<tr className="text-left border-2 border-b-gray-400 ">
										<th className="py-2 px-4 border-b">No</th>
										<th className="py-2 px-4 border-b">Name</th>
										<th className="py-2 px-4 border-b">Account Number</th>
										<th className="py-2 px-4 border-b">Phone</th>
										<th className="py-2 px-4 border-b">Gross Salary</th>
										<th className="py-2 px-4 border-b">Last Update Date</th>
										<th className="py-2 px-4 border-b">Status</th>
										<th className="py-2 px-4 border-b">Detail</th>
									</tr>
								</thead>
								<tbody className="align-center">
									{displayEmployee?.map((employeeInfo, index) => (
										<tr
											key={index}
											className={index % 2 === 0 ? "bg-gray-200" : ""}
										>
											<td className=" py-2 px-4 border-b">
												{searchParamsEmployee.first + index + 1}
											</td>
											<td className=" py-2 px-4 border-b">
												{employeeInfo?.name
													.split(" ")
													.map(
														(word) =>
															word?.charAt(0)?.toUpperCase() +
															word?.slice(1)?.toLowerCase()
													)
													.join(" ")}
											</td>
											<td className="py-2 px-4 border-b">
												{employeeInfo?.account_number}
											</td>
											<td className="py-2 px-4 border-b">
												+{employeeInfo?.phone}
											</td>
											<td className="py-2 px-4 border-b">
												{Number(employeeInfo?.gross_salary).toLocaleString(
													"en-US",
													{ style: "currency", currency: "ETB" }
												)}
											</td>
											<td className="py-2 px-4 border-b">
												{employeeInfo?.updated
													? moment(employeeInfo?.updated).format("YYYY-MM-DD")
													: ""}
											</td>
											<td className="py-2 px-4 border-b">
												{employeeInfo?.status &&
													(employeeInfo?.status === "DISAPPROVED"
														? "Declined"
														: employeeInfo?.status.charAt(0) +
														  employeeInfo?.status.slice(1).toLowerCase())}
											</td>
											<td className="py-2 px-4 border-b">
												<i
													className="bg-kacha-orange-700 hover:bg-kacha-orange-600 text-white font-bold py-2 px-4 rounded w-[150px] text-center cursor-pointer"
													onClick={() =>
														openModal(employeeInfo?.name, employeeInfo?.url)
													}
												>
													Details
												</i>
											</td>
										</tr>
									))}
								</tbody>
							</table>
							{selectedEmployee && (
								<DashboardDetailModal
									isOpen={modalIsOpen}
									closeModal={closeModal}
									selectedEmployee={selectedEmployee}
									employeeDetial={employeeDetial}
									url={url}
									type={"EMPLOYEE"}
								/>
							)}
						</div>
					)}
					<div className="flex items-center justify-between w-md ">
						<div className="flex items-center space-x-2">
							<span className="text-gray-600">Items per page: </span>
							<div className="relative inline-block">
								<select
									className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:bg-white"
									value={searchParamsEmployee.rows}
									onChange={(e) => {
										searchParamsEmployee.rows = Number(e.target.value);
										setSearchParamsEmployee({ ...searchParamsEmployee });
									}}
								>
									<option value="10"> 10</option>
									<option value="20">20</option>
									<option value="50">50</option>
								</select>
								<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
									<svg
										className="fill-current h-4 w-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
									>
										<path d="M10 12.5l-5-5 1.5-1.5L10 9.5l3.5-3.5L15 7z" />
									</svg>
								</div>
							</div>
						</div>

						<span className="text-gray-600 mx-auto px-10 ">
							{searchParamsEmployee.first + 1} -{" "}
							{searchParamsEmployee.first + searchParamsEmployee.rows} of{" "}
							{employee?.count || 0}
						</span>

						<div className="flex items-center space-x-4">
							<div>
								<CiCircleChevLeft
									className={`${
										searchParamsEmployee.first === 0
											? "opacity-50 cursor-not-allowed"
											: "cursor-pointer"
									}  h-8 w-8 text-orange-700 font-bold`}
									size={30}
									onClick={goBackEmployee}
								/>
								{/* <svg
									xmlns="http://www.w3.org/2000/svg"
									className={`${
										searchParamsEmployee.first === 0
											? "opacity-50 cursor-not-allowed"
											: "cursor-pointer"
									}  h-8 w-8  text-orange-700 font-bold`}
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M15 19l-7-7 7-7"
									/>
								</svg> */}{" "}
							</div>
							<div>
								<CiCircleChevRight
									className={`${
										searchParamsEmployee.first + searchParamsEmployee.rows >=
										employee?.count
											? "opacity-50 cursor-not-allowed"
											: "cursor-pointer"
									}  h-8 w-8 text-orange-700 font-bold`}
									size={30}
									onClick={goNextEmployee}
								/>
								{/* <svg
									xmlns="http://www.w3.org/2000/svg"
									className={`${
										searchParamsEmployee.first + searchParamsEmployee.rows >=
										employee?.count
											? "opacity-50 cursor-not-allowed"
											: "cursor-pointer"
									}  h-8 w-8  text-orange-700 font-bold`}
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M9 5l7 7-7 7"
									/>
								</svg> */}
							</div>
						</div>
					</div>
				</div>
			)}
			{showEmployeeDetails && employee.count === 0 && (
				<div
					className="min-h-[50vh] flex pt-8 px-4 flex-col justify-center items-center "
					id="employee"
				>
					<div className="flex flex-col justify-center items-center">
						<div className="flex flex-col justify-center items-center">
							<ExclamationCircleIcon className="h-10 w-10 text-orange-500" />
							<div className="text-gray-500 text-lg font-bold">
								No Registered Employee Found
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Dashboard;
