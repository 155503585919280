import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import Cookies from "universal-cookie";
import axios from "axios";
import { base_url } from "./api/Axios";
import { BellIcon } from "@heroicons/react/outline";
import { useNotification } from "./pages/NotificationContext";
import CryptoJS from "crypto-js";
// import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useAuth } from "./context/AuthContext";
import peaceLogo from "./images/peace-logo.png";
import { useNavigate } from "react-router-dom";
import Logout from "./pages/Logout";

const SubMenu = ({ items }) => {
	return (
		<ul className="submenu absolute bg-white shadow-kacha-box-shadow border-gray-200 min-w-[200px] px-2 py-4 whitespace-nowrap">
			{items.map((item, index) => (
				<li key={index}>
					<a
						href={item.href}
						className="block px-4 py-2 text-sm text-kacha-text6 hover:bg-gray-100 hover:text-kacha-orange-700"
					>
						{item.name}
					</a>
				</li>
			))}
		</ul>
	);
};

const Navbar = () => {
	const { disapprovedCount } = useNotification();
	const cookies = new Cookies();
	const navigate = useNavigate();
	const [activeSubMenu, setActiveSubMenu] = useState(null);
	const [showChangePassword, setShowChangePassword] = useState(false);
	const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
	const [notificationCount, setNotificationCount] = useState(0);
	const [prevNotificationCount, setPrevNotificationCount] = useState(0);
	const [companyNotificationCount, setCompanyNotificationCount] = useState(0);
	const [employeeNotificationClicked, setEmployeeNotificationClicked] =
		useState(false);
	const [companyNotificationClicked, setCompanyNotificationClicked] =
		useState(false);
	const [
		pendingEmployeeNotificationCount,
		setPendingEmployeeNotificationCount,
	] = useState(0);
	const [pendingCompanyNotificationCount, setPendingCompanyNotificationCount] =
		useState(0);
	const [
		pendingEmployeeNotificationClicked,
		setPendingEmployeeNotificationClicked,
	] = useState(false);
	const [
		pendingCompanyNotificationClicked,
		setPendingCompanyNotificationClicked,
	] = useState(false);
	const [error, setError] = useState("");
	const [status, setStatus] = useState("");
	let token = cookies.get("token");
	let user = cookies.get("user");
	let access = cookies.get("access");
	let role = "";
	try {
		role = access && token
		? CryptoJS.AES?.decrypt(access, token)?.toString(CryptoJS.enc.Utf8)
		: "";
	}catch(error){
		console.log(error);
	}
		
	let userName = cookies.get("userName");
	const navigation = [
		{
			name: (!token || authUser?.password_reset_required) && "Home",
			href: "/",
		},
		{
			name: !authUser?.password_reset_required && "Dashboard",
			href: "/dashboard",
		},
		{
			name: !authUser?.password_reset_required && "Service",
			href:
				(role === "CHECKER" ? "/service/validate-company" : null) ||
				(role === "MAKER" ? "/service/add-company" : null),
			subMenu:
				(role === "CHECKER"
					? [
							{ name: "Approve Company", href: "/service/validate-company" },
							{ name: "Approve Employee", href: "/service/validate-employee" },

							// { name: "View Consents", href: "/service/view-consents" },
							{ name: "Report", href: "/service/report" },
					  ]
					: null) ||
				(role === "MAKER"
					? [
							{ name: "Register Company", href: "/service/add-company" },
							{ name: "Register Employee", href: "/service/add-employee" },
							{
								name: "Declined Companies",
								href: "/service/disapproved-company",
							},
							{
								name: "Declined Employees",
								href: "/service/disapproved-employee",
							},
							// { name: "Add Consents", href: "/service/view-consents" },
							{ name: "Report", href: "/service/report" },
					  ]
					: null),
		},
		{
			name: "Help",
			href: "/help",
			subMenu: [
				{ name: "FAQ", href: "/faq" },
				{ name: "User Guide", href: "/user-guide" },
			],
		},
	];
	const logoutHandler = (e) => {
		e.preventDefault();
		setAuthUser(null);

		cookies.remove("token", { path: "/" });
		cookies.remove("refresh", { path: "/" });
		cookies.remove("access", { path: "/" });
		cookies.remove("user", { path: "/" });
		cookies.remove("userName", { path: "/" });
		localStorage.removeItem("employeeNotificationClicked");
		localStorage.removeItem("companyNotificationClicked");
		localStorage.removeItem("pendingEmployeeNotificationClicked");
		localStorage.removeItem("pendingCompanyNotificationClicked");
		navigate("/");
		window.location.reload();
	};
	const handleUserIconHover = () => {
		setShowChangePassword(true);
	};

	const handleUserIconLeave = () => {
		setShowChangePassword(false);
	};
	const handlePasswordClick = () => {
		navigate("/change-password");
	};

	useEffect(() => {
		if (!token) return;
		else {
			const getNotificationCount = async () => {
				try {
				const response = await axios.get(
					`${base_url}employees/?status=DISAPPROVED`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				).then((response) => {

				console.log(" status code ",response);
				if(response.status === 200) {
					setPrevNotificationCount(notificationCount);
					setNotificationCount(response.data.count);
				}}).catch((error) => {
					if(error.response){
						if (error.response.status === 401) {
							Logout();
							setIsLoggedIn(false);
							setAuthUser(null);
							setError("Session expired. Please login again");
							setTimeout(() => {
								setError("");
							}, 2000);
							navigate("/login");
						}
					}
					else {
						console.log("Error from catch",error);
						setError("Something went wrong");
						setTimeout(() => {
							setError("");
						}, 2000);
					}
				});
			}catch (error) {
				console.log("Error from catch",error);
				setError("Something went wrong");
				setTimeout(() => {
					setError("");
				}, 2000);
			}
			};

			getNotificationCount();
		}
	}, [token]);

	useEffect(() => {
		if (!token) return;
		else {
			const getCompanyNotificationCount = async () => {
				try {
				const response = await axios.get(
					`${base_url}orgs/?status=DISAPPROVED`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				).then((response) => {
					setCompanyNotificationCount(response.data.count);
				}
				).catch((error) => {
					if(error.response){
						if (error.response.status === 401) {
							Logout();
							setIsLoggedIn(false);
							setAuthUser(null);
							setError("Session expired. Please login again");
							setTimeout(() => {
								setError("");
							}, 2000);
							navigate("/login");
						}
					}
					else {
						console.log("Error from catch",error);
						setError("Something went wrong");
						setTimeout(() => {
							setError("");
						}, 2000);
					}
				});
			}catch (error) {
				console.log(error);
				setError("Something went wrong");
				setTimeout(() => {
					setError("");
				}, 2000);
			};
		}
			getCompanyNotificationCount();
	}
	}, [token]);

	useEffect(() => {
		if (!token) return;
		else {
			const getPendingEmployeeNotificationCount = async () => {
				try {
				const response = await axios.get(
					`${base_url}employees/?status=PENDING`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				).then((response) => {
				
					setPendingEmployeeNotificationCount(response.data.count);
				}).catch((error) => {
					if(error.response){
						if (error.response.status === 401) {
							Logout();
							setIsLoggedIn(false);
							setAuthUser(null);
							setError("Session expired. Please login again");
							setTimeout(() => {
								setError("");
							}, 2000);
							navigate("/login");
						}
					}
					else {
						console.log("Error from catch",error);
						setError("Something went wrong");
						setTimeout(() => {
							setError("");
						}, 2000);
					}
				});
				
			}catch (error) {
				console.log(error);
				setError("Something went wrong");
				setTimeout(() => {
					setError("");
				}, 2000);
			}
			};
			getPendingEmployeeNotificationCount();
		}
	}, [token]);

	useEffect(() => {
		if (!token) return;
		else {
			try {
			const getPendingCompanyNotificationCount = async () => {
				const response = await axios.get(`${base_url}orgs/?status=PENDING`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}).then((response) => {
				setPendingCompanyNotificationCount(response.data.count)
				}
				).catch((error) => {
					if(error.response){
						if (error.response.status === 401) {
							Logout();
							setIsLoggedIn(false);
							setAuthUser(null);
							setError("Session expired. Please login again");
							setTimeout(() => {
								setError("");
							}, 2000);
							navigate("/login");
						}
					}
					else {
						console.log("Error from catch",error);
						setError("Something went wrong");
						setTimeout(() => {
							setError("");
						}, 2000);
					}
				});
				
			};
			getPendingCompanyNotificationCount();
		} catch (error) {
			console.log(error);
			setError("Something went wrong");
			setTimeout(() => {
				setError("");
			}, 2000);
		}
		}
	}, [token]);

	useEffect(() => {
		const isEmployeeNotificationClicked = localStorage.getItem(
			"employeeNotificationClicked"
		);
		if (isEmployeeNotificationClicked) {
			setEmployeeNotificationClicked(true);
		}

		const isCompanyNotificationClicked = localStorage.getItem(
			"companyNotificationClicked"
		);
		if (isCompanyNotificationClicked) {
			setCompanyNotificationClicked(true);
		}

		const isPendingEmployeeNotificationClicked = localStorage.getItem(
			"pendingEmployeeNotificationClicked"
		);
		if (isPendingEmployeeNotificationClicked) {
			setPendingEmployeeNotificationClicked(true);
		}

		const isPendingCompanyNotificationClicked = localStorage.getItem(
			"pendingCompanyNotificationClicked"
		);
		if (isPendingCompanyNotificationClicked) {
			setPendingCompanyNotificationClicked(true);
		}
	}, []);

	const handleEmployeeNotificationClick = () => {
		setEmployeeNotificationClicked(true);
		localStorage.setItem("employeeNotificationClicked", "true");
	};

	const handleCompanyNotificationClick = () => {
		setCompanyNotificationClicked(true);
		localStorage.setItem("companyNotificationClicked", "true");
	};

	const handlePendingEmployeeNotificationClick = () => {
		setPendingEmployeeNotificationClicked(true);
		localStorage.setItem("pendingEmployeeNotificationClicked", "true");
	};

	const handlePendingCompanyNotificationClick = () => {
		setPendingCompanyNotificationClicked(true);
		localStorage.setItem("pendingCompanyNotificationClicked", "true");
	};
	return (
		<header className="top-0 bg-white z-50">
			<nav className="flex items-center justify-between p-4 lg:px-kacha-padding1  border-b border-kacha-orange-700">
				<div className="flex lg:flex-1">
					<a href="/" className="-m-1.5 p-1.5">
						<img className="h-14 w-auto" src={peaceLogo} alt="kacha logo" />
					</a>
				</div>
				{
					error && (
						<p className="text-red-500 text-kacha-text7 p-2 fixed  top-20 right-10 z-50 bg-white">
							{error} </p>
					)
				}
				<div className="hidden lg:flex gap-x-12">
					{navigation.map((item) => (
						<div
							key={item.name}
							onMouseEnter={() => setActiveSubMenu(item.name)}
							onMouseLeave={() => setActiveSubMenu(null)}
						>
							{((item.name === "Service" || item.name === "Dashboard") &&
								!token) || (
								<>
									{item.name === "Service" || item.name === "Help" ? (
										<div
											href={item.href}
											className={`font-montserrat text-kacha-text6 leading-6 text-kacha-gray-200 hover:text-kacha-orange-700 `}
										>
											{item.name}
										</div>
									) : (
										<a
											href={item.href}
											className={`font-montserrat text-kacha-text6 leading-6 text-kacha-gray-200 hover:text-kacha-orange-700 `}
										>
											{item.name}
										</a>
									)}

									{item.subMenu && activeSubMenu === item.name && (
										<SubMenu items={item.subMenu} />
									)}
								</>
							)}
						</div>
					))}
				</div>

				<div className="flex lg:hidden">
					<button>
						<i className="menu icon large text-kacha-gray-200"></i>
					</button>
				</div>

				<div className="hidden lg:flex flex-1 justify-end cursor-pointer">
					{user ? (
						<div className="flex items-center">
							{role === "MAKER" &&
								(notificationCount > 0 || companyNotificationCount > 0 ? (
									<div className=" text-red-700 px-4 py-3 rounded flex">
										<div className="mr-1 justify-end flex items-center transition-opacity duration-500 ease-in-out">
											<BellIcon className="h-6 w-6" />
											<div className="flex flex-col">
												<a
													href="/service/disapproved-company"
													title="Declined Companies"
													onClick={handleCompanyNotificationClick}
												>
													{companyNotificationClicked
														? null
														: companyNotificationCount > 1
														? `${companyNotificationCount} `
														: null}{" "}
												</a>
												<a
													href="/service/disapproved-employee"
													title="Declined Employees"
													onClick={handleEmployeeNotificationClick}
												>
													{employeeNotificationClicked
														? null
														: notificationCount > 1
														? `${notificationCount} `
														: null}{" "}
												</a>
											</div>
										</div>
									</div>
								) : null)}
							{role === "CHECKER" &&
								(pendingEmployeeNotificationCount > 0 ||
								pendingCompanyNotificationCount > 0 ? (
									<div className=" text-red-700 px-4 py-3 rounded flex">
										<div className="mr-1 justify-end flex items-center transition-opacity duration-500 ease-in-out">
											<BellIcon className="h-6 w-6" />
											<div className="flex flex-col">
												<a
													href="/service/validate-company"
													title="Pending Companies"
													onClick={handlePendingCompanyNotificationClick}
												>
													{pendingCompanyNotificationClicked
														? null
														: pendingCompanyNotificationCount > 1
														? `${pendingCompanyNotificationCount} `
														: null}{" "}
												</a>
												<a
													href="/service/validate-employee"
													title="Pending Employees"
													onClick={handlePendingEmployeeNotificationClick}
												>
													{pendingEmployeeNotificationClicked
														? null
														: pendingEmployeeNotificationCount > 1
														? `${pendingEmployeeNotificationCount} `
														: null}{" "}
												</a>
											</div>
										</div>
									</div>
								) : null)}
							<div className=""> {userName ? userName : user}</div>
							<div
								onMouseEnter={handleUserIconHover}
								onMouseLeave={handleUserIconLeave}
								className="relative h-6 mr-2 pb-2"
							>
								<i className="pb-4 pl-1 user icon large cursor-pointer" />
								V3
								<div
									className={`submenu absolute bg-white shadow-kacha-box-shadow border-gray-200 min-w-[200px] px-2 whitespace-nowrap transition-opacity ${
										showChangePassword ? "opacity-100" : "opacity-0"
									}`}
								>
									<a
										href="/change-password"
										className="block px-2 py-1 text-sm text-kacha-text6 hover:bg-gray-100 hover:text-kacha-orange-700"
										onClick={handlePasswordClick}
									>
										Change Password
									</a>
									<button
										className="block px-2 py-1 text-sm text-kacha-text6 hover:bg-gray-100 hover:text-kacha-orange-700"
										type="submit"
										onClick={(e) => logoutHandler(e)}
									>
										Logout
									</button>
								</div>
							</div>
						</div>
					) : (
						<div className="flex items-center">
							<a
								href="/login"
								className="font-montserrat font-bold text-kacha-text6 leading-6 text-kacha-gray-200 bg-kacha-orange-500 hover:bg-kacha-orange-600 py-2 px-4 rounded-md transition-colors duration-300"
							>
								Login
							</a>
						</div>
					)}
				</div>
			</nav>
		</header>
	);
};

export default Navbar;
