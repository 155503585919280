import * as yup from "yup";

const phoneRegex = /^((\+2519)|(\+2517)|2519|2517|09|07)[0-9]{8}$/;

const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
export const loginValidationSchema = yup.object({
	username: yup
		.string()
		.required("Please enter your user name")
		.min(4, "Please enter a valid user name")
		.max(100, "Please enter a valid user name"),
	password: yup
		.string()
		.required("Please enter your password")
		.min(4, "Please enter a valid password")
		.max(100, "Please enter a valid password"),
});

export const changePasswordValidation = yup.object({
	old_password: yup.string().required("Current password is required"),
	new_password: yup
		.string()
		.required("New password is required")
		.matches(
			/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
			"Password must contain a combination of numbers, alphabets , and special characters Ex. &Ebay@44"
		)
		.min(8, "Password must be at least 8 characters long"),
	confirm_password: yup
		.string()
		.required("Confirm password is required")
		.oneOf([yup.ref("new_password"), null], "Passwords must match"),
});
export const registorEmployee = yup.object().shape({
	first_name: yup
		.string()
		.required("First name shouldn't be empty")
		.min(2, "First name shouldn't be less than 2 characters")

		.max(150, "First name shouldn't be more than 150 characters")
		.matches(
			/^[a-z\s\/\.]+$/i,
			"First name should not contain special characters"
		),
	middle_name: yup
		.string()
		.required("Middle name shouldn't be empty")

		.min(1, "Middle name shouldn't be less than 1 characters")
		.max(150, "Middle name shouldn't be more than 150 characters")
		.matches(
			/^[a-z\s\/\.]+$/i,
			"Middle name should not contain special characters"
		),
	last_name: yup
		.string()
		.required("Last name shouldn't be empty")

		.min(2, "Last name shouldn't be less than 2 characters")
		.max(150, "Last name shouldn't be more than 150 characters")
		.matches(
			/^[a-z\s\/\.]+$/i,
			"Last name should not contain special characters"
		),
	phone: yup
		.string()
		.required("Please enter your phone number")
		.matches(
			phoneRegex,
			"Phone number shouldn't be more than 13 digits starting with 251, 07 or 09 "
		),
	gross_salary: yup
		.number()
		.required("Please enter a valid salary amount")
		.min(1, "Please enter a valid salary amount")
		.max(10000000, "Please enter a valid salary amount"),
	net_salary: yup
		.number()
		.required("Please enter a valid salary amount")
		.min(1, "Please enter a valid salary amount")
		.max(10000000, "Please enter a valid salary amount"),
	// .test(
	// 	"is-less-than-gross",
	// 	"Net salary should be less than basic salary amount",
	// 	function (value) {
	// 		const grossSalary = this.parent.gross_salary;
	// 		return value < grossSalary;
	// 	}
	// )
	gender: yup.string().required("Please select gender"),
	date_of_employment: yup
		.string()
		.required("Please enter valid date of employment"),
	account_number: yup
		.string()
		.test(
			"not-starts-with-dash",
			"Account number cannot start with '-'",
			(value) => !value || value[0] !== "-"
		)
		.min(3, "Please enter a valid account")
		.max(16, "Please enter a valid account")
		.required("Please enter an account number"),
	employer: yup.string().required("Please select valid employeer"),
	total_work_experience: yup
		.number("Experience should be a number")
		.positive("Experience should be a positive")
		.integer("Experience should be an integer")
		.typeError("Please enter a valid experience year")
		.min(0)
		.max(100)
		.nullable(),
});

export const registorCompany = yup.object({
	name: yup.string().required("Please enter company name"),
	phone: yup
		.string()
		.required("Please enter phone number")
		.matches(
			phoneRegex,
			"Phone number shouldn't be more than 13 digits starting with 251, 07 or 09  "
		),
	legal_condition: yup
		.string()
		.required("Please enter legal condition information"),
	license_number: yup.string().required("Please enter license number"),
	date_registered: yup.string().required("Please enter date of registration"),
	payday: yup
		.string()
		.required("Please enter payment valid day")
		.matches(/^[0-9]+$/, "Payday should be a number")
		.test({
			name: "min-max",
			message: "Payday should be between 1 and 31",
			test: (value) => {
				const parsedValue = parseInt(value, 10);
				return !isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= 31;
			},
		}),
	region: yup.string().required("Please enter region information"),
	zone: yup.string().required("Please enter zone information"),
	tin_value: yup
		.string()
		.required("Please enter TIN value")
		.matches(/^[0-9]+$/, "TIN value should be a number")
		.min(10, "TIN value should be 10 digits")
		.max(10, "TIN value should be 10 digits"),
	paid_up_capital: yup.number().min(1, "Capital can't be negative or zero"),
	account_number: yup
		.string()
		.test(
			"not-starts-with-dash",
			"Account number cannot start with '-'",
			(value) => !value || value[0] !== "-"
		)
		.min(3, "Please enter a valid account")
		.max(16, "Please enter a valid account")
		.required("Please enter an account number"),
	renewal_status: yup.string().required("Please select status"),
	license: yup.mixed().required("Please upload file"),

	contract: yup.mixed().required("Please upload file"),

	tin: yup.mixed().required("Please upload file"),
});

export const addTransaction = yup.object({
	phone: yup
		.string()
		.required("Please enter valid phone number")
		.matches(
			phoneRegex,
			"Phone number shouldn't be more than 13 digits starting with 251, 07 or 09 "
		),
	accountType: yup.string().required("Please enter account type"),
	fullName: yup.string().required("Please enter full name"),
	email: yup
		.string()
		.required("Please enter valid email")
		.matches(emailRegex, "Email is not valid"),
	employeeId: yup.string().required("Please enter employee ID"),
});
export const contact = yup.object({});
