import axios from "axios";
import React, { useState, useEffect } from "react";
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import Cookies from "universal-cookie";
import moment from "moment";
import inactiveIcon from "../images/inactive.png";
import { LendingModal } from "../widgets/LendingModal";
import { base_url } from "../api/Axios";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import Logout from "./Logout";

export class SearchParams {
	first = 0;
	rows = 10;
	searchText = "";
	sortColumn = "";
	sortDirection = 1;
}

const ValidateCompany = () => {
	const cookies = new Cookies();
	const navigate = useNavigate();
	const token = cookies.get("token");
	const [company, setCompany] = useState(null);
	const [fetchCompanyError, setFetchCompanyError] = useState();
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [url, setUrl] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [detailDataChecker, setDetailDataCheker] = useState();
	const parts = url?.split("/");
	const subUrl = parts?.slice(-3).join("/");
	const [searchParams, setSearchParams] = useState(new SearchParams());
	const [filterOption, setFilterOption] = useState("PENDING");
	const [searchResult, setSearchResult] = useState("");
	const [prevFilterOption, setPrevFilterOption] = useState("");
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		const fetchDetailData = async () => {
			if (token === undefined) {
				return;
			}
			else {
				try {
					const response = await axios.get(`${base_url}${subUrl}`, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
	
					setDetailDataCheker(response.data);
					console.log(detailDataChecker);
				} catch (error) {
					if(error.response.status === 401){
						Logout();
						setFetchCompanyError(error?.response?.data[0]);
						setTimeout(() => {
							setFetchCompanyError("");
						}, 2000);
						navigate("/login");
					}
					console.log(error);
				}
			}
		};

		fetchDetailData();
	}, [subUrl]);

	const openModal = (companyName, url) => {
		setSelectedCompany(companyName);
		setUrl(url);
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
		// window.location.reload();
	};

	useEffect(() => {
		
		const filterCompanyByStatus = async () => {
			if (prevFilterOption !== filterOption) {
				setSearchParams({
					...searchParams,
					first: 0,
				});
			}
			setLoading(true);
			try {
				const response = await axios.get(
					`${base_url}orgs/?f=${searchParams.first}&limit=${searchParams.rows}&offset=${searchParams.first}&status=${filterOption}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				setCompany(response.data);
				setLoading(false);
				console.log("Company data:", response.data);
				
			} catch (error) {
				if(error.response.status === 401){
					Logout();
					setFetchCompanyError(error?.response?.data[0]);
					setTimeout(() => {
						setFetchCompanyError("");
					}, 2000);
					navigate("/login");
				}
				setFetchCompanyError(error?.response?.data[0]);
				setLoading(false);
				console.error("Error fetching company data:", fetchCompanyError);
			}
		};

		setPrevFilterOption(filterOption);
		filterCompanyByStatus();
	}, [filterOption, searchParams]);

	useEffect(() => {
		
		const searchCompany = async () => {
			setLoading(true);
			try {
				
				const response = await axios.get(
					`${base_url}orgs/?search=${searchResult}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				setCompany(response.data);
				setLoading(false);
				
			} catch (error) {
				if(error.response.status === 401){
					Logout();
					setFetchCompanyError(error?.response?.data[0]);
					setTimeout(() => {
						setFetchCompanyError("");
					}, 2000);
					navigate("/login");
				}
				setFetchCompanyError(error?.response?.data[0]);
				setLoading(false);
				console.error("Error fetching company data:", fetchCompanyError);
			}
		};

		searchCompany();
	}, [searchResult]);

	const displayCompany =
		company?.results?.map((companyList, index) => ({
			url: companyList?.url,
			name: companyList?.name,
			phone: companyList?.phone,
			tin_value: companyList?.tin_value,
			renewal_status: companyList?.renewal_status,
			created: companyList?.created_at,
			updated: companyList?.updated_at,
			status: companyList?.status,
		})) || null;

	console.log("display company", company?.results);

	const goNext = () => {
		if (searchParams.first + searchParams.rows < company.count) {
			setSearchParams({
				...searchParams,
				first: searchParams.first + searchParams.rows,
			});
		}
	};

	const goBack = () => {
		if (searchParams.first > 0) {
			setSearchParams({
				...searchParams,
				first: searchParams.first - searchParams.rows,
			});
		}
	};
	return (
		<div className="min-h-[50vh] flex pt-8 px-4 flex-col justify-center items-center">
			
			{loading && (
				<Loader />
			)}

			{fetchCompanyError ? (
				<div>Error fetching company data. Please try Agian.</div>
			) : (
				<div className="overflow-x-auto w-full py-8 px-2 border-2 border-b-gray-400">
					<div className="flex items-center justify-end mr-8">
						<div className="mb-4 flex items-center justify-end mr-8">
							<input
								className="border border-gray-300 p-2 rounded-md"
								type="text"
								placeholder="Search..."
								value={searchResult}
								onChange={(e) => setSearchResult(e.target.value)}
							/>
						</div>
						<div className="mb-4 flex items-center justify-end mr-8">
							<label className="text-gray-600 mr-2">Filter by Status:</label>
							<select
								className="p-2 border border-gray-300 rounded-md"
								value={filterOption}
								onChange={(e) => setFilterOption(e.target.value)}
							>
								<option value="">------</option>
								<option value="PENDING">Pending</option>
								<option value="ACTIVE">Active</option>
								<option value="INACTIVE">Inactive</option>
								<option value="DISAPPROVED">Declined</option>
							</select>
						</div>
					</div>
					{
						company?.count === 0 ? (
							<div className="flex flex-col justify-center items-center">
							<div className="flex flex-col justify-center items-center">
								<ExclamationCircleIcon className="h-10 w-10 text-orange-500" />
								<div className="text-gray-500 text-lg font-bold">
									No Company Found to Approve
								</div>
							</div>
						</div>
						) : (
							<>

							<table className="min-w-full  border-gray-300">
								<thead>

									<tr  className="text-left border-2 border-b-gray-400">
										<th className="py-2 px-4 border-b">No</th>
										<th className="py-2 px-4 border-b">Name</th>
										<th className="py-2 px-4 border-b">Phone</th>
										<th className="py-2 px-4 border-b">TIN</th>
										<th className="py-2 px-4 border-b">Last Update Date</th>
										<th className="py-2 px-4 border-b">Status</th>
										<th className="py-2 px-4 border-b">Decision</th>
									</tr>
								</thead>
								<tbody>
									{displayCompany?.map((companyInfo, index) => (
										<tr
											key={index}
											className={`${index % 2 === 0 ? "bg-gray-100" : ""}`}
										>
											<td className="py-2 px-4 border-b">{searchParams.first + index + 1}</td>
											<td className="py-2 px-4 border-b">
												<div className="flex items-center space-x-4">
													<span>{companyInfo.name}</span>
													<span
														className={
															companyInfo.renewal_status === "EXPIRED"
																? "blinking"
																: ""
														}
													>
														{companyInfo.renewal_status === "EXPIRED" ? (
															<img
																className="w-8 h-8"
																src={inactiveIcon}
																alt="Inactive"
															/>
														) : (
															""
														)}{" "}
													</span>
												</div>
											</td>
											<td className="py-2 px-4 border-b">+{companyInfo.phone}</td>
											<td className="py-2 px-4 border-b">
												{companyInfo.tin_value}
											</td>
											<td className="py-2 px-4 border-b">
												{companyInfo.updated
													? moment(companyInfo.updated).format("YYYY-MM-DD")
													: ""}
											</td>
											<td className="py-2 px-4 border-b">{companyInfo.status && (
												companyInfo.status === "DISAPPROVED" ? (
													"Declined"
													
												) : (
													companyInfo.status.charAt(0) + companyInfo.status.slice(1).toLowerCase()
												))}
											</td>
											<td className="py-2 px-4 border-b">
												<button
													onClick={() =>
														openModal(
															companyInfo.name ? companyInfo.name : "Anonymous",
															companyInfo.url
														)
													}
													className={`bg-kacha-orange-700 hover:bg-kacha-orange-600 text-white font-bold py-2 px-4 rounded w-[150px] ${
														companyInfo.status === "ACTIVE" ||
														companyInfo.status === "DISAPPROVED"
															? "opacity-50 cursor-not-allowed"
															: ""
													}`}
													{...(companyInfo.status === "ACTIVE" || companyInfo.status === "DISAPPROVED"
														? { disabled: true }
														: {})}
												>
													{companyInfo?.status === "ACTIVE"
														? "Approved"
														: companyInfo?.status === "DISAPPROVED"
														? "Declined"
														: "  Approve/Decline"}
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
							{selectedCompany && (
								<LendingModal
									isOpen={modalIsOpen}
									closeModal={closeModal}
									reviewData={detailDataChecker}
									url={url}
									role="CHECKER"
									type="COMPANY"
								/>
							)}
							</>
						)
					}
				</div>
			)}
			{
			company?.count > 0 && (
				<div className="flex items-center  justify-between w-md">
					<div className="flex items-center space-x-2">
						<span className="text-gray-600">Items per page: </span>
						<div className="relative inline-block">
							<select
								className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:bg-white"
								value={searchParams.rows}
								onChange={(e) => {
									searchParams.rows = Number(e.target.value);
									setSearchParams({ ...searchParams });
								}}
							>
								<option value="10"> 10</option>
								<option value="20">20</option>
								<option value="50">50</option>
							</select>
							<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
								<svg
									className="fill-current h-4 w-4"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
								>
									<path d="M10 12.5l-5-5 1.5-1.5L10 9.5l3.5-3.5L15 7z" />
								</svg>
							</div>
						</div>
					</div>

					<span className="text-gray-600 px-10">
						{searchParams.first + 1} - {searchParams.first + searchParams.rows} of{" "}
						{company?.count || 0}
					</span>

					<div className="flex items-center space-x-4">
						<div >
							<CiCircleChevLeft
								className={`${
									searchParams.first === 0 ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
								}  h-8 w-8 text-orange-700 font-bold`}
								size={30}
								onClick={goBack}
							/>
							{/* <svg
								xmlns="http://www.w3.org/2000/svg"
								className={`${
									searchParams.first === 0 ? "opacity-50 cursor-not-allowed" : ""
								}  h-8 w-8 text-orange-700 font-bold`}
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M15 19l-7-7 7-7"
								/>
							</svg> */}
							{" "}
						</div>
						<div >
							<CiCircleChevRight
								className={`${
									searchParams.first + searchParams.rows >= company?.count
										? "opacity-50 cursor-not-allowed"
										: "cursor-pointer"
								}  h-8 w-8 text-orange-700 font-bold`}
								size={30}
								onClick={goNext}
								/>
							{/* <svg
								xmlns="http://www.w3.org/2000/svg"
								className={`${
									searchParams.first + searchParams.rows >= company?.count
										? "opacity-50 cursor-not-allowed"
										: ""
								}  h-8 w-8  text-orange-700  font-bold`}
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M9 5l7 7-7 7"
								/>
							</svg> */}
						</div>
					</div>
				</div>
			)
			}
		</div>
	);
};

export default ValidateCompany;
