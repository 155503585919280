import React from 'react';

const FilePreviewModal = ({ isOpenBulkModal, closeModalDialog, fileContent }) => {
  return (
    <>
      {isOpenBulkModal && (
        
          fileContent && fileContent.length > 0 ? (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 max-w-7xl rounded-lg relative">
            <h2 className="text-3xl font-bold mb-4 text-center text-gray-800">
              Bulk File Preview
            </h2>
            <button
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 cursor-pointer"
              onClick={closeModalDialog}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="overflow-x-auto max-h-96">
              <table className="table-auto w-full">
                <thead>
                  <tr className="bg-kacha-orange-600 text-white">
                    <th className='px-4 py-2'>S/N</th>
                    <th className="px-4 py-2">First Name</th>
                    <th className="px-4 py-2">Middle Name</th>
                    <th className="px-4 py-2">Last Name</th>
                    <th className="px-4 py-2">Gender</th>
                    {/* <th className="px-4 py-2">Date of Birth</th> */}
                    <th className='px-4 py-2'>Phone</th>
                    <th className='px-4 py-2'>Employer</th>
                    <th className='px-4 py-2'>Date of Employment</th>
                    <th className='px-4 py-2'>Basic Salary</th>
                    <th className='px-4 py-2'>Net Salary</th>
                    <th className='px-4 py-2'>Account Number</th>
                    <th className='px-4 py-2'>Position</th>
                    <th className='px-4 py-2'>Years of Experience</th>
                  </tr>
                </thead>
                <tbody>
                    {Array.isArray(fileContent) && fileContent.length > 0 ? (
                    fileContent.map((line, index) => (
                        <tr key={index}>
                            <td className="border px-4 py-2">{line.No}</td>
                            <td className="border px-4 py-2">{line.first_name}</td>
                            <td className="border px-4 py-2">{line.middle_name}</td>
                            <td className="border px-4 py-2">{line.last_name}</td>
                            <td className='border px-4 py-2'>{line.gender}</td>
                            {/* <td className='border px-4 py-2'>{line.date_of_birth}</td> */}
                            <td className='border px-4 py-2'>{line.phone}</td>
                            <td className='border px-4 py-2'>{line.employer}</td>
                            <td className='border px-4 py-2'>{line.date_of_employment}</td>
                            <td className='border px-4 py-2'>{line.gross_salary}</td>
                            <td className='border px-4 py-2'>{line.net_salary}</td>
                            <td className='border px-4 py-2'>{line.account_number}</td>
                            <td className='border px-4 py-2'>{line.position}</td>
                            <td className='border px-4 py-2'>{line.years_of_experience}</td>
                        </tr>
                    ))
                    ) : (
                    <tr>
                        <td
                        colSpan="2"
                        className="text-center text-red-500 py-4"
                        >
                        No data to display.
                        </td>
                    </tr>
                    )}

                  {/* {Array.isArray(fileContent) && fileContent.length > 0 ? (
                    fileContent.map((line, index) => (
                      <tr key={index}>
                        <td className="border px-4 py-2">{index + 1}</td>
                        <td className="border px-4 py-2 break-all">
                          {line}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="2"
                        className="text-center text-red-500 py-4"
                      >
                        No data to display.
                      </td>
                    </tr>
                  )} */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
          ) : (
            <div className='fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center' >
              <span className='bg-white p-8 max-w-7xl rounded-lg relative'>
                No File Content to Preview
                </span>
            </div>
          )

      )}
    </>
  );
};

export default FilePreviewModal;
