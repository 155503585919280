import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { base_url } from "../api/Axios";
import informationIcon from "../images/information-button.png";
import { registorCompany } from "../utils/Validation";
import Logout from "./Logout";

const customStyles = {
	content: {
		width: "50%",
		maxWidth: "80%",
		margin: "auto",
		borderRadius: "8px",
		boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
		top: "100px",
	},
	overlay: {
		backgroundColor: "rgba(0, 0, 0, 0.5)",
	},
	closeButton: {
		position: "absolute",
		top: "10px",
		right: "10px",
		background: "none",
		border: "none",
		cursor: "pointer",
		fontSize: "16px",
		color: "red",
	},
};

const EditCompany = ({
	isOpen,
	closeModal,
	reviewData,
	sub_url,
	dataTobeUpdated,
}) => {
	const cookies = new Cookies();
	const navigate = useNavigate();
	const token = cookies.get("token");

	// const match = sub_url.match(/\d+/);
	// if (match) {
	//     const extractedId = match[0];
	//     console.log("Extracted ID:", extractedId);
	// } else {
	//     console.log("No ID found in the sub-URL.");
	// }
	const zones = [
		{
			value: 2,
			display_name: "Addis Ketema, Addis Ababa",
			tag: "AA",
		},
		{
			value: 5,
			display_name: "Akaky Kaliti, Addis Ababa",
			tag: "AA",
		},
		{
			value: 1,
			display_name: "Arada, Addis Ababa",
			tag: "AA",
		},
		{
			value: 3,
			display_name: "Bole, Addis Ababa",
			tag: "AA",
		},
		{
			value: 6,
			display_name: "Gullele, Addis Ababa",
			tag: "AA",
		},
		{
			value: 7,
			display_name: "Kirkos, Addis Ababa",
			tag: "AA",
		},
		{
			value: 8,
			display_name: "Kolfe Keranio, Addis Ababa",
			tag: "AA",
		},
		{
			value: 11,
			display_name: "Lemi Kura, Addis Ababa",
			tag: "AA",
		},
		{
			value: 9,
			display_name: "Lideta, Addis Ababa",
			tag: "AA",
		},
		{
			value: 4,
			display_name: "Nifas Silk Lafto, Addis Ababa",
			tag: "AA",
		},
		{
			value: 10,
			display_name: "Yeka, Addis Ababa",
			tag: "AA",
		},
		{
			value: 75,
			display_name: "Dire Dawa City Administration, Dire Dawa",
			tag: "DR",
		},
		{
			value: 12,
			display_name: "Central Tigray, Tigray",
			tag: "TG",
		},
		{
			value: 14,
			display_name: "Eastern Tigray, Tigray",
			tag: "TG",
		},
		{
			value: 18,
			display_name: "Mekelle City, Tigray",
			tag: "TG",
		},
		{
			value: 15,
			display_name: "North Western Tigray, Tigray",
			tag: "TG",
		},
		{
			value: 13,
			display_name: "South Eastern Tigray, Tigray",
			tag: "TG",
		},
		{
			value: 17,
			display_name: "Southern Tigray, Tigray",
			tag: "TG",
		},
		{
			value: 16,
			display_name: "Western Tigray, Tigray",
			tag: "TG",
		},
		{
			value: 43,
			display_name: "Agew Awi Zone, Amhara",
			tag: "AM",
		},
		{
			value: 52,
			display_name: "Bahir Dar Special Zone, Amhara",
			tag: "AM",
		},
		{
			value: 76,
			display_name: "Central Gondar Zone, Amhara",
			tag: "AM",
		},
		{
			value: 53,
			display_name: "Defunct Districts, Amhara",
			tag: "AM",
		},
		{
			value: 44,
			display_name: "East Gojjam Zone, Amhara",
			tag: "AM",
		},
		{
			value: 45,
			display_name: "North Gondar Zone, Amhara",
			tag: "AM",
		},
		{
			value: 46,
			display_name: "North Shewa Zone, Amhara",
			tag: "AM",
		},
		{
			value: 47,
			display_name: "North Wollo Zone, Amhara",
			tag: "AM",
		},
		{
			value: 79,
			display_name: "Oromia Zone, Amhara",
			tag: "AM",
		},
		{
			value: 49,
			display_name: "South Gondar Zone, Amhara",
			tag: "AM",
		},
		{
			value: 48,
			display_name: "South Wollo Zone, Amhara",
			tag: "AM",
		},
		{
			value: 50,
			display_name: "Wag Hemra Zone, Amhara",
			tag: "AM",
		},
		{
			value: 51,
			display_name: "West Gojjam Zone, Amhara",
			tag: "AM",
		},
		{
			value: 78,
			display_name: "West Gondar Zone, Amhara",
			tag: "AM",
		},
		{
			value: 19,
			display_name: "Zone 1 (Awsi Rasu), Afar",
			tag: "AF",
		},
		{
			value: 20,
			display_name: "Zone 2 (Kilbet Rasu), Afar",
			tag: "AF",
		},
		{
			value: 21,
			display_name: "Zone 3 (Gabi Rasu), Afar",
			tag: "AF",
		},
		{
			value: 22,
			display_name: "Zone 4 (Fantena Rasu), Afar",
			tag: "AF",
		},
		{
			value: 23,
			display_name: "Zone 5 (Hari Rasu), Afar",
			tag: "AF",
		},
		{
			value: 80,
			display_name: "Zone 6 (Mahi Rasu), Afar",
			tag: "AF",
		},
		{
			value: 82,
			display_name: "Zone 7 (Argobba, special woreda), Afar",
			tag: "AF",
		},
		{
			value: 70,
			display_name: "Adama City Administration, Oromia",
			tag: "OR",
		},
		{
			value: 54,
			display_name: "Arsi Zone, Oromia",
			tag: "OR",
		},
		{
			value: 55,
			display_name: "Bale Zone, Oromia",
			tag: "OR",
		},
		{
			value: 56,
			display_name: "Borena Zone, Oromia",
			tag: "OR",
		},
		{
			value: 89,
			display_name: "Buno Bedele Zone, Oromia",
			tag: "OR",
		},
		{
			value: 73,
			display_name: "Defunct Districts, Oromia",
			tag: "OR",
		},
		{
			value: 83,
			display_name: "East Arsi Zone, Oromia",
			tag: "OR",
		},
		{
			value: 84,
			display_name: "East Bale Zone, Oromia",
			tag: "OR",
		},
		{
			value: 86,
			display_name: "East Guji zone, Oromia",
			tag: "OR",
		},
		{
			value: 57,
			display_name: "East Hararghe Zone, Oromia",
			tag: "OR",
		},
		{
			value: 58,
			display_name: "East Shewa Zone, Oromia",
			tag: "OR",
		},
		{
			value: 59,
			display_name: "East Wollega Zone, Oromia",
			tag: "OR",
		},
		{
			value: 60,
			display_name: "Guji Zone, Oromia",
			tag: "OR",
		},
		{
			value: 61,
			display_name: "Horo Guduru Welega Zone, Oromia",
			tag: "OR",
		},
		{
			value: 88,
			display_name: "Illu ababora zone, Oromia",
			tag: "OR",
		},
		{
			value: 71,
			display_name: "Jimma City Administration, Oromia",
			tag: "OR",
		},
		{
			value: 62,
			display_name: "Jimma Zone, Oromia",
			tag: "OR",
		},
		{
			value: 63,
			display_name: "Kelam Welega Zone, Oromia",
			tag: "OR",
		},
		{
			value: 64,
			display_name: "North Shewa Zone, Oromia",
			tag: "OR",
		},
		{
			value: 72,
			display_name: "Oromia Special Zone Surrounding Finfinne, Oromia",
			tag: "OR",
		},
		{
			value: 65,
			display_name: "South West Shewa Zone, Oromia",
			tag: "OR",
		},
		{
			value: 66,
			display_name: "West Arsi Zone, Oromia",
			tag: "OR",
		},
		{
			value: 85,
			display_name: "West Bale zone, Oromia",
			tag: "OR",
		},
		{
			value: 87,
			display_name: "West Guji, Oromia",
			tag: "OR",
		},
		{
			value: 67,
			display_name: "West Hararghe Zone, Oromia",
			tag: "OR",
		},
		{
			value: 68,
			display_name: "West Shewa Zone, Oromia",
			tag: "OR",
		},
		{
			value: 69,
			display_name: "West Welega Zone, Oromia",
			tag: "OR",
		},
		{
			value: 38,
			display_name: "Asosa Zone, Benishangul-Gumuz",
			tag: "BG",
		},
		{
			value: 39,
			display_name: "Kamashi Zone, Benishangul-Gumuz",
			tag: "BG",
		},
		{
			value: 41,
			display_name: "Mao-Komo (Special Woreda), Benishangul-Gumuz",
			tag: "BG",
		},
		{
			value: 40,
			display_name: "Metekel Zone, Benishangul-Gumuz",
			tag: "BG",
		},
		{
			value: 42,
			display_name: "Pawe  (Special Woreda), Benishangul-Gumuz",
			tag: "BG",
		},
		{
			value: 24,
			display_name: "Anuak Zone, Gambela",
			tag: "GM",
		},
		{
			value: 27,
			display_name: "Itang (Special Woreda), Gambela",
			tag: "GM",
		},
		{
			value: 26,
			display_name: "Mezhenger, Gambela",
			tag: "GM",
		},
		{
			value: 25,
			display_name: "Nuer Zone, Gambela",
			tag: "GM",
		},
		{
			value: 74,
			display_name: "Harari, Harari",
			tag: "HR",
		},
		{
			value: 118,
			display_name: "Aleta Chuko Zone, Sidama",
			tag: "SD",
		},
		{
			value: 119,
			display_name: "Aleta Wendo Zone, Sidama",
			tag: "SD",
		},
		{
			value: 120,
			display_name: "Arbegona Zone, Sidama",
			tag: "SD",
		},
		{
			value: 121,
			display_name: "Aroresa Zone, Sidama",
			tag: "SD",
		},
		{
			value: 123,
			display_name: "Bensa Zone, Sidama",
			tag: "SD",
		},
		{
			value: 124,
			display_name: "Bona Zuria Zone, Sidama",
			tag: "SD",
		},
		{
			value: 125,
			display_name: "Boricha Zone, Sidama",
			tag: "SD",
		},
		{
			value: 126,
			display_name: "Bursa Zone, Sidama",
			tag: "SD",
		},
		{
			value: 127,
			display_name: "Chere Zone, Sidama",
			tag: "SD",
		},
		{
			value: 128,
			display_name: "Dale Zone, Sidama",
			tag: "SD",
		},
		{
			value: 129,
			display_name: "Dara Zone, Sidama",
			tag: "SD",
		},
		{
			value: 130,
			display_name: "Gorche Zone, Sidama",
			tag: "SD",
		},
		{
			value: 131,
			display_name: "Hawassa city, Sidama",
			tag: "SD",
		},
		{
			value: 122,
			display_name: "Hawassa Zuria Zone, Sidama",
			tag: "SD",
		},
		{
			value: 132,
			display_name: "Hula Zone, Sidama",
			tag: "SD",
		},
		{
			value: 133,
			display_name: "Loko Abaya zone, Sidama",
			tag: "SD",
		},
		{
			value: 134,
			display_name: "Malga zone, Sidama",
			tag: "SD",
		},
		{
			value: 135,
			display_name: "Shebedino zone, Sidama",
			tag: "SD",
		},
		{
			value: 136,
			display_name: "Wensho zone, Sidama",
			tag: "SD",
		},
		{
			value: 137,
			display_name: "Wondo Genet zone, Sidama",
			tag: "SD",
		},
		{
			value: 112,
			tag: "SW",
			display_name: "Bench Maji Zone, South West Ethiopia Peoples",
		},
		{
			value: 113,
			tag: "SW",
			display_name:
				"Dawro (formerly part of North Omo Zone), South West Ethiopia Peoples",
		},
		{
			value: 114,
			tag: "SW",
			display_name:
				"Keffa (formerly part of Keficho Shekicho Zone), South West Ethiopia Peoples",
		},
		{
			value: 116,
			tag: "SW",
			display_name:
				"Konta (special woreda, formerly part of North Omo Zone), South West Ethiopia Peoples",
		},
		{
			value: 115,
			tag: "SW",
			display_name:
				"Sheka (formerly part of Keficho Shekicho Zone), South West Ethiopia Peoples",
		},
		{
			value: 117,
			tag: "SW",
			display_name: "West Omo Zone, South West Ethiopia Peoples",
		},
		{
			value: 106,
			tag: "SN",
			display_name: "Alaba Zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 107,
			tag: "SN",
			display_name:
				"Amaro (special woreda), Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 108,
			tag: "SN",
			display_name:
				"Basketo (special woreda, formerly part of North Omo Zone), Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 109,
			tag: "SN",
			display_name:
				"Burji (special woreda), Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 110,
			tag: "SN",
			display_name:
				"Dirashe (special woreda), Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 97,
			tag: "SN",
			display_name:
				"Gamo Gofa Zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 98,
			tag: "SN",
			display_name: "Gedeo Zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 99,
			tag: "SN",
			display_name: "Gurage Zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 100,
			tag: "SN",
			display_name: "Hadiya Zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 101,
			tag: "SN",
			display_name:
				"Kembata Tembaro zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 104,
			tag: "SN",
			display_name: "Konso Zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 102,
			tag: "SN",
			display_name: "Silt'e Zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 103,
			tag: "SN",
			display_name:
				"South Omo zone, Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 105,
			tag: "SN",
			display_name:
				"Wolayita (formerly part of North Omo Zone), Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 111,
			tag: "SN",
			display_name:
				"Yem (special woreda), Southern Nations, Nationalities, and Peoples",
		},
		{
			value: 29,
			display_name: "Afder Zone, Somali",
			tag: "SM",
		},
		{
			value: 93,
			display_name: "Degehabur Special Zone, Somali",
			tag: "SM",
		},
		{
			value: 90,
			display_name: "Dhawa Zone, Somali",
			tag: "SM",
		},
		{
			value: 37,
			display_name: "Dollo Zone, Somali",
			tag: "SM",
		},
		{
			value: 32,
			display_name: "Erer Zone, Somali",
			tag: "SM",
		},
		{
			value: 34,
			display_name: "Faafan Zone, Somali",
			tag: "SM",
		},
		{
			value: 94,
			display_name: "Gode Special Zone, Somali",
			tag: "SM",
		},
		{
			value: 30,
			display_name: "Jarar Zone, Somali",
			tag: "SM",
		},
		{
			value: 91,
			display_name: "Jigjiga Special Zone, Somali",
			tag: "SM",
		},
		{
			value: 95,
			display_name: "Kebri Beyah Special Zone, Somali",
			tag: "SM",
		},
		{
			value: 96,
			display_name: "Kebri Dahar Special Zone, Somali",
			tag: "SM",
		},
		{
			value: 35,
			display_name: "Korahe Zone, Somali",
			tag: "SM",
		},
		{
			value: 28,
			display_name: "liben zone (Lahey), Somali",
			tag: "SM",
		},
		{
			value: 31,
			display_name: "Nogob Zone, Somali",
			tag: "SM",
		},
		{
			value: 33,
			display_name: "Shabelle Zone, Somali",
			tag: "SM",
		},
		{
			value: 36,
			display_name: "Sitti Zone, Somali",
			tag: "SM",
		},
		{
			value: 92,
			display_name: "Tog Wajale Special Zone, Somali",
			tag: "SM",
		},
	];
	const legal = [
		{
			value: 1,
			display_name: "Private",
		},
		{
			value: 2,
			display_name: "Private Limited Company",
		},
		{
			value: 3,
			display_name: "Share Company",
		},
		{
			value: 4,
			display_name: "Commercial Representative",
		},
		{
			value: 5,
			display_name: "Public Enterprise",
		},
		{
			value: 6,
			display_name: "Partnership",
		},
		{
			value: 7,
			display_name: "Cooperatives Association",
		},
		{
			value: 9,
			display_name: "Trade Sectoral Association",
		},
		{
			value: 10,
			display_name: "Non-Public Enterprise",
		},
		{
			value: 11,
			display_name: "NGO",
		},
		{
			value: 12,
			display_name: "Branch of A Foreign Chamber of Commerce",
		},
		{
			value: 13,
			display_name: "Holding Company",
		},
		{
			value: 14,
			display_name: "Franchising",
		},
		{
			value: 15,
			display_name: "Border Trade",
		},
		{
			value: 19,
			display_name: "International Bid Winners Foreign Companies",
		},
		{
			value: 21,
			display_name: "One Man Private Limited Company",
		},
	];
	const region = [
		{
			value: "AA",
			display_name: "Addis Ababa",
		},
		{
			value: "AF",
			display_name: "Afar Region",
		},
		{
			value: "AM",
			display_name: "Amhara Region",
		},
		{
			value: "BG",
			display_name: "Benishangul-Gumuz Region",
		},
		{
			value: "DR",
			display_name: "Dire Dawa",
		},
		{
			value: "GM",
			display_name: "Gambela Region",
		},
		{
			value: "HR",
			display_name: "Harari Region",
		},
		{
			value: "OR",
			display_name: "Oromia Region",
		},
		{
			value: "SD",
			display_name: "Sidama Region",
		},
		{
			value: "SM",
			display_name: "Somali Region",
		},
		{
			value: "SW",
			display_name: "South West Ethiopia Peoples' Region",
		},
		{
			value: "SN",
			display_name: "Southern Nations, Nationalities, and Peoples' Region",
		},
		{
			value: "TG",
			display_name: "Tigray Region",
		},
	];

	const [companyForm, setCompanyForm] = useState({
		name: dataTobeUpdated?.name,
		phone: dataTobeUpdated?.phone,
		region: dataTobeUpdated?.region,
		zone: dataTobeUpdated?.zone,
		legal_condition: dataTobeUpdated?.legal_condition,

		paid_up_capital: dataTobeUpdated?.paid_up_capital,
		date_registered: dataTobeUpdated?.date_registered,
		license_number: dataTobeUpdated?.license_number,
		sectors: dataTobeUpdated?.sectors,
		account_number: dataTobeUpdated?.account_number,
		payday: dataTobeUpdated?.payday,

		renewal_status: dataTobeUpdated?.renewal_status,
		license: dataTobeUpdated?.license,
		contract: dataTobeUpdated?.contract,
		tin: dataTobeUpdated?.tin,
		tin_value: dataTobeUpdated?.tin_value,
		remark: dataTobeUpdated?.remark,
	});

	const [error, setError] = useState();
	const [success, setSuccess] = useState();
	const [errorBulk, setErrorBulk] = useState();
	const [successBulk, setSuccessBulk] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [contract, setContract] = useState();
	const [license, setLicense] = useState();
	const [tin, setTin] = useState();
	const [file, setFile] = useState();
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const url = `${base_url}`;
	//   const partUrl = companyForm.url;
	//     const subUrl = partUrl.slice(0, -1);
	//     console.log("subUrl",subUrl)

	const handleHideModal = () => {
		setModalIsOpen(false);
		closeModal();
	};

	useEffect(() => {
		const fetchDetailCompanyInfo = async () => {
			setCompanyForm({
				name: "",
				phone: "",
				region: null,
				zone: null,
				woreda: "",
				kebele: "",
				street_name: "",
				legal_condition: null,
				reg_num: "",

				paid_up_capital: "",
				manager_name: "",
				regular_phone: "",
				no_employees: null,
				date_registered: null,
				license_number: "",
				sectors: "",
				account_number: "",
				payday: null,

				renewal_status: "",
				license: null,
				contract: null,
				tin: null,
				tin_value: "",
				remark: "",
				is_supplier: false,
				has_supplier: false,
			});
			try {
				const response = await axios.get(`${url}${sub_url}`, {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${token}`,
					},
				});
				setCompanyForm({
					name: response.data?.name,
					phone: response.data?.phone,
					region: response.data?.region,
					zone: response.data?.zone,
					legal_condition: response.data?.legal_condition,

					paid_up_capital: response.data?.paid_up_capital,
					date_registered: response.data?.date_registered,
					license_number: response.data?.license_number,
					sectors: response.data?.sectors,
					account_number: response.data?.account_number,
					payday: response.data?.payday,

					renewal_status: response.data?.renewal_status,
					license: response.data?.license,
					contract: response.data?.contract,
					tin: response.data?.tin,
					tin_value: response.data?.tin_value,
					remark: response.data?.remark,
				});
			} catch (error) {
				if (error.response.status === 401) {
					Logout();
					navigate("/login");
				}
				console.log(error);
			}
		};
		fetchDetailCompanyInfo();
	}, [sub_url]);

	const openModal = (values) => {
		const updatedCompanyForm = {
			...values,
			license: license,
			contract: contract,
			tin: tin,
		};
		setModalIsOpen(true);
		setCompanyForm(updatedCompanyForm);
		handleUpdateCompany(updatedCompanyForm);
	};

	const handleNext = () => {
		setCurrentPage(currentPage + 1);
	};
	const handleBack = () => {
		setCurrentPage(currentPage - 1);
	};

	const handleUpdateCompany = async (values) => {
		const result = await axios
			.put(`${url}${sub_url}`, values, {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				setSuccess("Company updated successfully");
				setTimeout(() => {
					setSuccess("");
					closeModal();
				}, 3000);
			})
			.catch((error) => {
				if (error.response.status === 401) {
					Logout();
					navigate("/login");
				}
				setError("Company updated failed");
				setTimeout(() => {
					setError("");
					// closeModal();
				}, 3000);
			});
	};

	return (
		<Modal
			style={customStyles}
			isOpen={isOpen}
			onRequestClose={closeModal}
			enter="ease-out duration-300"
			enterFrom="opacity-0 scale-95"
			enterTo="opacity-100 scale-100"
			leave="ease-in duration-200"
			leaveFrom="opacity-100 scale-100"
			leaveTo="opacity-0 scale-95"
		>
			<div className="relative  bg-white">
				<button style={customStyles.closeButton} onClick={closeModal}>
					<i className="x large icon" />
				</button>
				<div className="responsive-aware-form flex  items-center justify-center   pb-8">
					<div className=" flex item-center    p-8">
						<div className="flex flex-col items-center  justify-center">
							<div className="flex flex-col items-center  justify-center">
								<h1 className="text-center font-montserrata text-kacha-text3 pb-4">
									Update Company Information
								</h1>
								<div className="flex ">
									<img src={informationIcon} alt="logo" className="h-6 pr-2" />
									Please enter all mandatory fields indicated with
									<span className="text-red-500 pl-2 pr-2 font-semibold">
										{" "}
										*{" "}
									</span>{" "}
									before proceed to next page!
								</div>
								<div className="mt-5" />
								<div className="flex flex-col">
									<span className="text-kacha-text9"> Remark</span>
									<textarea
										type="text"
										name="remark"
										value={companyForm.remark}
										disabled
										className="italic font-sans border border-red-400 p-3 rounded-md text-kacha-text9 min-w-[400px] max-w-[500px]"
									/>
								</div>
							</div>
							{companyForm.name ? (
								<Formik
									initialValues={companyForm}
									validationSchema={registorCompany}
									onSubmit={(values) => {
										openModal(values);
									}}
								>
									{(formik) => (
										<Form method="post" enctype="multipart/form-data">
											{currentPage === 1 && (
												<>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															TIN <span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="tin_value"
															as="input"
															type="text"
															// placeholder="TIN Number"
														/>
														{formik.errors.tin_value &&
															formik.touched.tin_value && (
																<div className="error text-red-500">
																	{formik.errors.tin_value}n
																</div>
															)}
													</div>
													<div className="mt-5" />

													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Trade Name <span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="name"
															as="input"
															type="text"
															// placeholder="Kacha Digital Finance"
														/>
														{formik.errors.name && formik.touched.name && (
															<div className="error text-red-500">
																{formik.errors.name}
															</div>
														)}
													</div>
													<div className="mt-5" />

													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Mobile Phone{" "}
															<span className="text-red-500">*</span>
														</label>
														<fieldset className="flex flex-row text-left min-w-[300px] max-w-[400px]">
															<div className="relative ">
																<div className="absolute inset-y-0 left-0 flex  p-3 pointer-events-none text-center">
																	<div className="flag-icon">🇪🇹 +</div>
																</div>
																<Field
																	className="border-l border-t border-b border-gray-300 p-3 rounded-l-md text-kacha-text9 min-w-[40px] max-w-[60px] text-center"
																	name="countryCode"
																	as="input"
																	type="text"
																	disabled
																/>
															</div>
															<div className="flex flex-col">
																<Field
																	className="border border-gray-300 p-3 rounded-r-md text-kacha-text9 min-w-[340px] max-w-[340px] "
																	name="phone"
																	as="input"
																	type="text"
																/>
																{formik.errors.phone &&
																	formik.touched.phone && (
																		<div className="error text-red-500">
																			{formik.errors.phone}
																		</div>
																	)}
															</div>
														</fieldset>
													</div>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Region <span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="region"
															as="select"
														>
															<option value="">---</option>
															{region.map((list) => (
																<option key={list.value} value={list.value}>
																	{list.display_name}
																</option>
															))}
														</Field>
														{formik.errors.region && formik.touched.region && (
															<div className="error text-red-500">
																{formik.errors.region}
															</div>
														)}
													</div>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label>
															Zone <span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="zone"
															as="select"
														>
															<option value="">--</option>
															{zones
																.filter(
																	(zone) => zone.tag === formik.values.region
																)
																.map((list) => (
																	<option key={list.value} value={list.value}>
																		{list.display_name}
																	</option>
																))}
														</Field>
														{formik.errors.zone && formik.touched.zone && (
															<div className="error">{formik.errors.zone}</div>
														)}
													</div>
													<div className="mt-5" />
													<div className="mt-5" />
													<button
														className=" bg-kacha-orange-700 text-center  m-2 p-3 min-w-[200px] text-white rounded-md text-lg"
														type="button"
														onClick={handleNext}
														disabled={
															!!formik.errors.name ||
															!!formik.errors.phone ||
															!!formik.errors.region ||
															!!formik.errors.zone ||
															!!formik.errors.tin_value
														}
													>
														Next
													</button>
												</>
											)}

											{currentPage === 2 && (
												<>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Legal condition{" "}
															<span className="text-red-500">*</span>
														</label>
														<Field
															as="select"
															name="legal_condition"
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
														>
															<option value="">---</option>
															{legal.map((list) => (
																<option key={list.value} value={list.value}>
																	{list.display_name}
																</option>
															))}
														</Field>
														{formik.errors.legal_condition &&
															formik.touched.legal_condition && (
																<div className="error text-red-500">
																	{formik.errors.legal_condition}
																</div>
															)}
													</div>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Capital
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="paid_up_capital"
															as="input"
															type="number"
															// placeholder="Paid Up Capital"
														/>
														{formik.errors.paid_up_capital &&
															formik.touched.paid_up_capital && (
																<div className="error text-red-500">
																	{formik.errors.paid_up_capital}
																</div>
															)}
													</div>
													<div className="mt-5" />

													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Date of Issuance{" "}
															<span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="date_registered"
															as="input"
															type="date"
															// placeholder="Number of Employees"
														/>
														{formik.errors.date_registered &&
															formik.touched.date_registered && (
																<div className="error text-red-500">
																	{formik.errors.date_registered}
																</div>
															)}
													</div>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															business License No.{" "}
															<span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="license_number"
															as="input"
															type="text"
															// placeholder="Number of Employees"
														/>
														{formik.errors.license_number &&
															formik.touched.license_number && (
																<div className="error text-red-500">
																	{formik.errors.license_number}
																</div>
															)}
													</div>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Sectors
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="sectors"
															as="input"
															type="text"
															// placeholder="Number of Employees"
														/>
														{formik.errors.sectors &&
															formik.touched.sectors && (
																<div className="error text-red-500">
																	{formik.errors.sectors}
																</div>
															)}
													</div>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Salary Paying Account Number{" "}
															<span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="account_number"
															as="input"
															type="text"
															// placeholder="Number of Employees"
														/>
														{formik.errors.account_number &&
															formik.touched.account_number && (
																<div className="error text-red-500">
																	{formik.errors.account_number}
																</div>
															)}
													</div>
													<div className="mt-5" />

													<div className="flex">
														<button
															type="button"
															onClick={handleBack}
															className=" bg-kacha-orange-700 text-center cursor-pointer m-2 p-3 min-w-[200px] text-white rounded-md text-lg"
														>
															Back
														</button>

														<div className="mt-5" />
														<button
															className=" bg-kacha-orange-700 text-center  m-2 p-3 min-w-[200px] text-white rounded-md text-lg"
															type="button"
															onClick={handleNext}
															disabled={
																!!formik.errors.date_registered ||
																!!formik.errors.license_number ||
																!!formik.errors.account_number ||
																!!formik.errors.legal_condition
															}
														>
															Next
														</button>
													</div>
												</>
											)}
											{currentPage === 3 && (
												<>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Pay Day <span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="payday"
															as="input"
															type="text"
															// placeholder="Number of Employees"
														/>
														{formik.errors.payday && formik.touched.payday && (
															<div className="error text-red-500">
																{formik.errors.payday}
															</div>
														)}
													</div>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Renewal Status{" "}
															<span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="renewal_status"
															as="select"
															type="text"
															// placeholder="TIN Number"
														>
															<option value="">------</option>
															<option value="RENEWED">Can renewed</option>
															<option value="CAN_RENEWED">
																Can be Renewed
															</option>
															<option value="EXPIRED">Expired</option>

															{formik.errors.renwal_status &&
																formik.touched.renwal_status && (
																	<div className="error text-red-500">
																		{formik.errors.renwal_status}
																	</div>
																)}
														</Field>
													</div>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Business License Document
															<span className="text-red-500">*</span>
														</label>
														<input
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="license"
															as="input"
															type="file"
															onChange={(e) => {
																formik.setFieldValue(
																	"license",
																	e.target.files && e.target.files[0]
																);
																setLicense(e.target.files && e.target.files[0]);
															}}
														/>
														{formik.errors.license &&
															formik.touched.license && (
																<div className="error text-red-500">
																	{formik.errors.license}
																</div>
															)}
													</div>

													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Signed Contract
															<span className="text-red-500">*</span>
														</label>
														<input
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="contract"
															as="input"
															type="file"
															onChange={(e) => {
																formik.setFieldValue(
																	"contract",
																	e.target.files && e.target.files[0]
																);
																setContract(
																	e.target.files && e.target.files[0]
																);
															}}
														/>
														{formik.errors.contract &&
															formik.touched.contract && (
																<div className="error text-red-500">
																	{formik.errors.contract}
																</div>
															)}
													</div>
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															TIN Certificate
															<span className="text-red-500">*</span>
														</label>
														<input
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															type="file"
															id="tin"
															name="tin"
															onChange={(e) => {
																formik.setFieldValue(
																	"tin",
																	e.target.files && e.target.files[0]
																);
																setTin(e.target.files && e.target.files[0]);
															}}
														/>
														{formik.errors.tin && formik.touched.tin && (
															<div className="error text-red-500">
																{formik.errors.tin}
															</div>
														)}
													</div>
													<div className="mt-5" />

													<div className="mt-5" />

													<div className="mt-5" />
													<div className="mt-5" />
													<div className="text-kacha-text10 ">
														By proceeding you are accepting terms and conditions
														of the MFI.
													</div>
													<div className="mt-5" />
													<div className="mt-5" />
													<>
														{success && (
															<div className="text-green-700 text-kacha-text7 p-2  z-50 bg-white">
																{success}
															</div>
														)}
														{error && (
															<div className="text-red-500 text-kacha-text7 p-2  z-50 bg-white">
																Please Try Again. {error} !!
															</div>
														)}
													</>
													<div className="flex">
														<button
															type="button"
															onClick={handleBack}
															className=" bg-kacha-orange-700 text-center cursor-pointer m-2 p-3 min-w-[200px] text-white rounded-md text-lg"
														>
															Back
														</button>

														<button
															className=" bg-kacha-orange-700 text-center  m-2 p-3 min-w-[200px]  text-white rounded-md text-lg"
															type="submit"
															disabled={
																!!formik.errors.renewal_status ||
																!!formik.errors.tin_value ||
																!!formik.errors.payday
															}
														>
															Submit
														</button>
													</div>
												</>
											)}
										</Form>
									)}
								</Formik>
							) : (
								<div className="text-red-500 text-center py-8">
									The Company not Registered Correctly First Time!!
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default EditCompany;
