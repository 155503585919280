import axios from "axios";
import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import moment from "moment";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { BellIcon } from "@heroicons/react/outline";
import { LendingModal } from "../widgets/LendingModal";
import { base_url } from "../api/Axios";
import EditEmployee from "./EditEmployee";
import Loader from "./Loader";
import Logout from "./Logout";
import { useNavigate } from "react-router-dom";

export class SearchParams {
	first = 0;
	rows = 10;
	searchText = "";
	sortColumn = "";
	sortDirection = 1;
}

const DisapprovedEmployeeList = () => {
	const cookies = new Cookies();
	const navigate = useNavigate();	
	const token = cookies.get("token");
	const [employee, setEmployee] = useState(null);
	const [fetchEmployeeError, setEmployeeError] = useState();
	const [selectedEmployee, setSelectedEmployee] = useState(null);
	const [url, setUrl] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [detailDataChecker, setDetailDataCheker] = useState();
	const parts = url?.split("/");
	const subUrl = parts?.slice(-3).join("/");
	const [searchParams, setSearchParams] = useState(new SearchParams());
	const [disapprovedCount, setDisapprovedCount] = useState(0);
	const [loading, setLoading] = useState(false);

	const [employeeForm, setEmployeeForm] = useState({
		first_name: "",
		middle_name: "",
		last_name: "",
		gender: null,
		marital_status: "",
		phone: "",
		employer: null,
		date_of_employment: null,

		gross_salary: null,
		net_salary: null,
		account_number: "",
		position: null,
		total_work_experience: null,
		remark: "",
	});

	useEffect(() => {
		const fetchDetailData = async () => {
			try {
				const response = await axios.get(`${base_url}${subUrl}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				setDetailDataCheker(response.data);
				console.log("Detail Data Checker", detailDataChecker);
				setEmployeeForm({
					first_name: response.data.first_name,
					middle_name: response.data.middle_name,
					last_name: response.data.last_name,
					gender: response.data.gender,
					date_of_birth: response.data.date_of_birth,
					phone: response.data.phone,
					employer: response.data.employer,
					date_of_employment: response.data.date_of_employment,

					gross_salary: response.data.gross_salary,
					net_salary: response.net_salary,
					account_number: response.data.account_number,
					position: response.data.position,
					total_work_experience: response.data.total_work_experience,
					salary_payment_start_date: response.data.salary_payment_start_date,
					salary_payment_end_date: response.data.salary_payment_end_date,

					region: response.data.region,
					zone: response.data.zone,
					woreda: response.data.woreda,
					kebele: response.data.kebele,
					street_name: response.data.street_name,
					remark: response.data.remark,
				});
			} catch (error) {
				if(error.response.status === 401){
					Logout();
					navigate("/login");
				}
				console.log(error);
			}
		};

		fetchDetailData();
	}, [subUrl]);

	useEffect(() => {
		console.log("Employee data to be edited:", employeeForm);
	}, [employeeForm]);

	const openModal = (employeeName, url) => {
		setSelectedEmployee(employeeName);
		setUrl(url);
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
		window.location.reload();
	};

	useEffect(() => {
		const fetchEmployee = async () => {
			setLoading(true);
			try {
				const response = await axios.get(
					`${base_url}employees/?f=${searchParams.first}&limit=${searchParams.rows}&offset=${searchParams.first}&status=DISAPPROVED`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setEmployee(response.data);
				setDisapprovedCount(response.data.count);
				setLoading(false);
				console.log("Employee data:", response.data);
			} catch (error) {
				if(error.response.status === 401){
					Logout();
					navigate("/login");
				}
				setEmployeeError(error?.response?.data[0]);
				setLoading(false);
				console.error("Error fetching employee data:", fetchEmployeeError);
			}
		};

		fetchEmployee();
	}, [searchParams, modalIsOpen]); // Empty dependency array means this effect runs once on mount

	// console.log(fetchEmployeeError);
	const displayEmployee =
		employee?.results?.map((employeeList, index) => ({
			url: employeeList?.url,
			name: `${employeeList?.first_name}  ${employeeList?.middle_name}  ${employeeList?.last_name}`,
			phone: employeeList?.phone,
			position: employeeList?.position,
			gross_salary: employeeList?.gross_salary,
			net_salary: employeeList?.net_salary,
			created: employeeList?.created_at,
			status: employeeList?.status,
		})) || null;
	const goNext = () => {
		if (searchParams.first + searchParams.rows < employee.count) {
			setSearchParams({
				...searchParams,
				first: searchParams.first + searchParams.rows,
			});
		}
	};

	const goBack = () => {
		if (searchParams.first > 0) {
			setSearchParams({
				...searchParams,
				first: searchParams.first - searchParams.rows,
			});
		}
	};
	return (
		<>
			<div className="min-h-[50vh] flex flex-col justify-center items-center">
				{
					loading && (
						<Loader />
					)
				}
				{fetchEmployeeError ? (
					<div>Error fetching employee data. Please try Agian.</div>
				) : (
					employee?.count > 0 ? (
					<div className="overflow-x-auto w-full py-8 px-2">
						<table className="min-w-full  border-gray-300">
							<thead>
								<tr className="text-left">
									<th className="py-2 px-2 border-b">No</th>
									<th className="py-2 px-2 border-b">Name</th>
									<th className="py-2 px-2 border-b">Phone</th>
									<th className="py-2 px-2 border-b">Position</th>
									<th className="py-2 px-2 border-b">Base Salary</th>
									<th className="py-2 px-2 border-b">Net Salary</th>

									<th className="py-2 px-2 border-b">Created Date</th>
									<th className="py-2 px-2 border-b">Status</th>
									<th className="py-2 px-2 border-b">Change</th>
								</tr>
							</thead>
							<tbody className="align-center text-left">
								{displayEmployee?.map((employeeInfo, index) => (
									<tr
										key={index}
										className={index % 2 === 0 ? "bg-gray-100" : ""}
									>
										<td className=" py-2 px-2 border-b">{searchParams.first + index + 1}</td>
										<td className=" py-2 px-2 border-b">
											{employeeInfo?.name}
										</td>
										<td className=" py-2 px-2 border-b">
											{employeeInfo?.phone}
										</td>
										<td className=" py-2 px-2 border-b">
											{employeeInfo?.position}
										</td>
										<td className=" py-2 px-2 border-b">
											{Number(employeeInfo?.gross_salary).toLocaleString(
												"en-US",
												{ style: "currency", currency: "ETB" }
											)}
										</td>
										<td className=" py-2 px-2 border-b">
											{employeeInfo?.net_salary}
										</td>

										<td className="py-2 px-2 border-b">
											{employeeInfo?.created
												? moment(employeeInfo?.created).format("YYYY-MM-DD")
												: ""}
										</td>
										<td className="py-2 px-2 border-b">
											{employeeInfo?.status}
										</td>
										<td className="py-2 px-2 border-b">
											<button
												onClick={() =>
													openModal(
														employeeInfo?.first_name
															? employeeInfo?.first_name
															: "Anonymous",
														employeeInfo?.url
													)
												}
												className="bg-kacha-orange-700 hover:bg-kacha-orange-600 text-white font-bold py-2 px-4 rounded"
											>
												Edit
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						{selectedEmployee && (
							<EditEmployee
								isOpen={modalIsOpen}
								closeModal={closeModal}
								reviewData={detailDataChecker}
								dataTobeUpdated={employeeForm}
								name={selectedEmployee}
								setEmployeeForm={setEmployeeForm}
								sub_url={subUrl}
							/>
						)}
					</div>
					): (
						<div className="flex flex-col justify-center items-center">
							<div className="flex flex-col justify-center items-center">
								<ExclamationCircleIcon className="h-10 w-10 text-orange-500" />
								<div className="text-gray-500 text-lg font-bold">
									No Disapproved Employee Found
								</div>
							</div>
						</div>
					)
				)}
				{
				employee?.count > 0 && (
					<div className="flex items-center  justify-between w-md">
						<div className="flex items-center space-x-2">
							<span className="text-gray-600">Items per page: </span>
							<div className="relative inline-block">
								<select
									className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:bg-white"
									value={searchParams.rows}
									onChange={(e) => {
										searchParams.rows = Number(e.target.value);
										setSearchParams({ ...searchParams });
									}}
								>
									<option value="10"> 10</option>
									<option value="20">20</option>
									<option value="50">50</option>
								</select>
								<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
									<svg
										className="fill-current h-4 w-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
									>
										<path d="M10 12.5l-5-5 1.5-1.5L10 9.5l3.5-3.5L15 7z" />
									</svg>
								</div>
							</div>
						</div>

						<span className="text-gray-600 px-10">
							{searchParams.first + 1} - {searchParams.first + searchParams.rows}{" "}
							of {employee?.count || 0}
						</span>

						<div className="flex items-center space-x-4">
							<div onClick={goBack}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className={`${
										searchParams.first === 0
											? "opacity-50 cursor-not-allowed"
											: ""
									}  h-8 w-8 text-orange-700 font-bold`}
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M15 19l-7-7 7-7"
									/>
								</svg>{" "}
							</div>
							<div onClick={goNext} className="">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className={`${
										searchParams.first + searchParams.rows >= employee?.count
											? "opacity-50 cursor-not-allowed"
											: ""
									}  h-8 w-8  text-orange-700  font-bold`}
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M9 5l7 7-7 7"
									/>
								</svg>
							</div>
						</div>
					</div>
				)
				}
			</div>
		</>
	);
};

export default DisapprovedEmployeeList;
