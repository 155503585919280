import { Field, Form, Formik } from "formik";
import React, { useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { base_url } from "../api/Axios";
import { useAuth } from "../context/AuthContext";
import peaceLogo from "../images/peace-logo.png";
import { changePasswordValidation } from "../utils/Validation";
import Logout from "./Logout";

const ChangeFirstTimePassword = () => {
	const cookies = new Cookies();
	const token = cookies.get("token");
	const navigate = useNavigate();
	const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
	const [changePasswordForm, setChangeFirstTimePasswordForm] = useState({
		old_password: "",
		new_password: "",
		confirm_password: "",
	});
	const [error, setError] = useState();
	const changePassword = async (value) => {
		const result = await axios
			.put(`${base_url}users/activate/`, value, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				console.log(response);
				navigate("/");
				cookies.remove("token");
				cookies.remove("refresh");
				cookies.remove("user");
				cookies.remove("roleAs");
				cookies.remove("login");
				window.location.reload();
			})
			.catch((error) => {
				if (error.response.status === 401) {
					Logout();
					navigate("/login");
				}
				console.log(error);
				setError(error.message);
			});

		setChangeFirstTimePasswordForm(value);
		console.log(value);
	};
	return (
		<div className="relative  bg-white">
			<div className="  responsive-aware-form flex flex-col items-center justify-center  mt-8 pb-8">
				<div className=" flex flex-col item-center shadow-kacha-box-shadow items-center  justify-center p-28">
					{error && <div className="text-red-500 p-2">{error} !!</div>}
					<img src={peaceLogo} alt="logo" className="h-14 pb-2" />
					<h1 className="text-center font-montserrata text-kacha-text3">
						Reset Password{" "}
					</h1>
					<h4 className="m-5 text-center text-kacha-gray-500 text-kacha-text8">
						Kindly provide the old Password to set a new one{" "}
					</h4>

					<Formik
						initialValues={{
							old_password: "",
							new_password: "",
							confirm_password: "",
						}}
						validationSchema={changePasswordValidation}
						onSubmit={(values) => {
							changePassword(values);
						}}
					>
						{(formik) => (
							<Form>
								<div className="mt-5" />

								<div className="flex flex-col">
									<label className="mb-1 text-kacha-text9">
										Default Password
									</label>
									<Field
										className="border border-gray-300 w-[360px] p-3 rounded-md text-kacha-text9"
										name="old_password"
										as="input"
										type="password"
										placeholder="Enter default password"
									/>
									{formik.errors.old_password &&
										formik.touched.old_password && (
											<div className="error text-red-500">
												{formik.errors.old_password}
											</div>
										)}
								</div>
								<div className="mt-5" />

								<div className="flex flex-col">
									<label className="mb-1 text-kacha-text9">New Password</label>
									<Field
										className="border border-gray-300 p-3 rounded-md text-kacha-text9"
										name="new_password"
										as="input"
										type="password"
										placeholder="Enter your new password"
									/>
									{formik.errors.new_password &&
										formik.touched.new_password && (
											<div className="error text-red-500">
												{formik.errors.new_password}
											</div>
										)}
								</div>
								<div className="mt-5" />
								<div className="flex flex-col">
									<label className="mb-1 text-kacha-text9">
										Confirm Password
									</label>
									<Field
										className="border border-gray-300 p-3 rounded-md text-kacha-text9"
										name="confirm_password"
										as="input"
										type="password"
										placeholder="Confirm your new password"
									/>
									{formik.errors.confirm_password &&
										formik.touched.confirm_password && (
											<div className="error text-red-500">
												{formik.errors.confirm_password}
											</div>
										)}
								</div>
								<div className="mt-5" />
								<div
									className=" bg-kacha-orange-700 text-center cursor-pointer p-3  w- rounded-md text-lg"
									type="submit"
								>
									<button
										className="text-center cursor-pointer text-kacha-text8 text-white"
										type="submit"
									>
										Proceed
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default ChangeFirstTimePassword;
