import axios from "axios";
import CryptoJS from "crypto-js";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
import { base_url } from "./api/Axios";
import { useAuth } from "./context/AuthContext";
import peaceLogo from "./images/peace-logo.png";
import Loader from "./pages/Loader";
import { loginValidationSchema } from "./utils/Validation";

const Login = () => {
	const cookies = new Cookies();
	const navigate = useNavigate();
	const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
	const [loginForm, setLoginForm] = useState({ username: "", password: "" });
	const [success, setSuccess] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const token = cookies.get("token");
	const handleLogin = async (value) => {
		try {
			const response = await axios.post(`${base_url}login/`, value);
			setAuthUser(response.data.user);
			const expireTime = new Date();
			expireTime.setHours(expireTime.getHours() + 12);
			cookies.set("token", response.data.access, { expires: expireTime });
			let token = cookies.get("token");

			cookies.set("refresh", response.data.refresh, { expires: expireTime });
			cookies.set(
				"access",
				CryptoJS.AES.encrypt(response.data.user.role, token).toString(),
				{ expires: expireTime }
			);
			cookies.set("user", response.data.user.username, { expires: expireTime });
			cookies.set("userName", response.data.user.first_name, {
				expires: expireTime,
			});
			if (!response.data.user.password_reset_required) {
				if (!token) <Loader />;
				if (token) {
					navigate("/loader");
					navigate("/dashboard");
					window.location.reload();
				}
			} else {
				navigate("/change-first-time-password");
			}
		} catch (error) {
			setError("Something went wrong");
			setTimeout(() => {
				setError("");
			}, 2000);
		}

		setLoginForm(value);
	};

	const showErrorMessage = () => {
		toast.error(error, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 2000,
		});
	};

	return (
		<div className="relative  bg-white">
			<div className="  responsive-aware-form flex flex-col items-center justify-center  mt-8 pb-8">
				<div className=" flex flex-col item-center shadow-kacha-box-shadow items-center  justify-center p-28">
					<img src={peaceLogo} alt="logo" className="h-14 pb-2" />
					<h1 className="text-center font-montserrata text-kacha-text3">
						Sign in to your account
					</h1>
					<h4 className="m-5 text-center text-kacha-gray-500 text-kacha-text8">
						Welcome back! Please enter your details.
					</h4>

					<Formik
						initialValues={{
							username: "",
							password: "",
						}}
						validationSchema={loginValidationSchema}
						onSubmit={(values) => {
							handleLogin(values);
						}}
					>
						{(formik) => (
							<Form autoComplete="off">
								<div className="mt-5" />

								<div className="flex flex-col">
									<label className="mb-1 text-kacha-text9">User Id</label>
									<Field
										className="border border-gray-300 w-[360px] p-3 rounded-md text-kacha-text9"
										name="username"
										as="input"
										type="text"
										placeholder="Enter your user name"
										autoComplete="off"
										readOnly
										onFocus={(e) => e.target.removeAttribute("readOnly")}
										id="UserId"
									/>
									{formik.errors.username && formik.touched.username && (
										<div className="error text-red-500">
											{formik.errors.username}
										</div>
									)}
								</div>
								<div className="mt-5" />

								<div className="flex flex-col">
									<label className="mb-1 text-kacha-text9">Password</label>
									<Field
										className="border border-gray-300 p-3 rounded-md text-kacha-text9"
										name="password"
										as="input"
										type="password"
										placeholder="Enter your password"
										autocomplete="new-password"
										readOnly
										onFocus={(e) => e.target.removeAttribute("readOnly")}
										id="Password"
									/>
									{formik.errors.password && formik.touched.password && (
										<div className="error text-red-500">
											{formik.errors.password}
										</div>
									)}
								</div>
								<div className="mt-5" />
								{error && (
									<div className="text-red-500 text-kacha-text7 p-2  right-10 z-50 bg-white">
										Please Try Again. {error} !!
									</div>
								)}
								<div className="mt-5" />

								<div>
									<button
										className=" bg-kacha-orange-700 rounded-md text-lg text-center min-w-[360px]  text-kacha-text8 cursor-pointer  text-white p-3"
										type="submit"
										disabled={
											!!formik.errors.username || !!formik.errors.password
										}
									>
										Sign In
									</button>
								</div>

								{/* <div className="text-center text-kacha-text8 ">
									<a href="/forgot-password" style ={{color: "#007bff"}}>Forgot Password?</a>
								</div> */}
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};
export default Login;
