import CryptoJS from "crypto-js";
import React from "react";
import Cookies from "universal-cookie";
import { useAuth } from "./context/AuthContext";
import callIcon from "./images/call-icon.png";
import emailIcon from "./images/email-icon.png";
import locationIcon from "./images/location-icon.png";
import peaceLogo from "./images/peace-logo.png";
const Footer = () => {
	const cookies = new Cookies();
	const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
	const access = cookies.get("access");
	const token = cookies.get("token");
	let role = "";
	try {
		role =
			access && token
				? CryptoJS.AES?.decrypt(access, token)?.toString(CryptoJS.enc.Utf8)
				: "";
	} catch (error) {
		console.log(error);
	}

	return (
		<div className="bg-kacha-gray-50">
			<div className=" flex item-center justify-center   pb-8">
				<div className="w-[100%] bg-[#e5e7eb] h-[2px]"></div>
			</div>
			{/* footer for large sized screens */}
			<div className=" hidden lg:grid md:grid-cols-6 gap-10 pl-kacha-padding1  pr-kacha-padding1">
				<div className="col-span-1 flex flex-col items-center justify-center text-justify">
					<div className="font-montserrat pb-2 pt-1 font-kacha-font-weight1 text-kacha-text6 p-2"></div>
					<a href="/" alt="home">
						<img
							className="pb-2 font-kacha-font-weight1"
							src={peaceLogo}
							alt="icon"
						/>
					</a>
				</div>

				<div className=" col-span-2  flex flex-col items-left text-justify ">
					<div className="font-montserrat pb-2 pt-1 font-kacha-font-weight1 text-kacha-text6 p-1">
						Contact
					</div>
					<div className="font-sans flex text-kacha-text9   text-kacha-gray-200 ">
						<img className="w-[20px] ml-1 mr-2" src={callIcon} alt="icon" />
						+25115571921/+251115570420
					</div>

					<div className="font-sans flex text-kacha-text9   text-kacha-gray-200 ">
						<img className="w-[20px] ml-1 mr-2" src={emailIcon} alt="icon" />
						info@peacemfi.com/executive@peacemfi.com
					</div>

					<div className="font-sans flex text-kacha-text9  mx-w-[300px]  text-kacha-gray-200 ">
						<img
							className="w-[27px] h-[27px]  mr-2"
							src={locationIcon}
							alt="icon"
						/>
						Kirkos Sub City, Wereda 3, House No. 198, Behind Meskel Flower Hotel
					</div>
				</div>
				<div className="flex flex-col items-left text-left ">
					<div className="font-montserrat pb-2 pt-1 font-kacha-font-weight1 text-kacha-text6 p-1">
						Links
					</div>
					<a
						href="/"
						className="font-sans text-kacha-text9   text-kacha-gray-200 p-1"
					>
						Home
					</a>

					<a
						href="/faq"
						className="font-sans text-kacha-text9   text-kacha-gray-200 p-1"
					>
						FAQ
					</a>
					<a
						href="/user-guide"
						className="font-sans text-kacha-text9   text-kacha-gray-200 p-1"
					>
						User Guide
					</a>
				</div>
				{role === "MAKER" && (
					<div className="flex flex-col items-left text-left ">
						<div className="font-montserrat  pt-1 font-kacha-font-weight1 text-kacha-text6 p-1">
							Services
						</div>

						<a
							href="/service/add-company"
							className="font-sans text-kacha-text9   text-kacha-gray-200 p-1"
						>
							Register Company
						</a>
						<a
							href="/service/add-employee"
							className="font-sans text-kacha-text9   text-kacha-gray-200 p-1"
						>
							Register Employee
						</a>
						<a
							href="/service/report"
							className="font-sans text-kacha-text9   text-kacha-gray-200 p-1"
						>
							Report
						</a>
					</div>
				)}
				{role === "CHECKER" && (
					<div className="flex flex-col items-left text-left ">
						<div className="font-montserrat  pt-1 font-kacha-font-weight1 text-kacha-text6 p-1">
							Services
						</div>
						<a
							href="/service/validate-company"
							className="font-sans text-kacha-text9   text-kacha-gray-200 p-1"
						>
							Approve Company
						</a>
						<a
							href="/service/validate-employee"
							className="font-sans text-kacha-text9   text-kacha-gray-200 p-1"
						>
							Approve Employee
						</a>
						<a
							href="/service/report"
							className="font-sans text-kacha-text9   text-kacha-gray-200 p-1"
						>
							Report
						</a>
					</div>
				)}
			</div>
			{/* footer for mobile and middium screen */}
			{/* <div className="lg:hidden grid pl-kacha-padding3 pr-kacha-padding3 md:pl-kacha-padding2 md:pr-kacha-padding2 lg:pl-kacha-padding1 lg:pr-kacha-padding1">
				<div className="gap-2 col-span-6  flex md:flex lg:flex-col items-left text-justify justify-between">
					<div className="col-span-2 ">
						<a href="/" alt="home">
							<img
								className="pb-2 font-kacha-font-weight1"
								src={peace-logo}
								alt="icon"
							/>
						</a>
						<div className="font-sans flex text-kacha-text9 md:text-kacha-text8 lg:text-kacha-text9   text-kacha-gray-200 p-2">
							<img className="w-[20px] mr-2" src={callIcon} alt="icon" />
							+251 116 68 63 04 / 05
						</div>
						<div className="font-sans flex text-kacha-text9 md:text-kacha-text8 lg:text-kacha-text9   text-kacha-gray-200 p-2">
							<img className="w-[20px] mr-2" src={emailIcon} alt="icon" />
							info@kachadfs.com
						</div>
						<div className="font-sans flex text-kacha-text9 md:text-kacha-text8 lg:text-kacha-text9  max-w-[300px]  text-kacha-gray-200 p-2">
							<img
								className="w-[27px] h-[27px] mr-2"
								src={locationIcon}
								alt="icon"
							/>
							<p className="">
								African Avenue, Noah Plaza, Mezzanine Floor, Addis Ababa /
								Ethiopia
							</p>
						</div>
					</div>
				</div>
				<div className="col-span-6  md:flex md:gap-8  ">
					<div className=" flex col-span  pt-4 lg:pt-0 justify-between ">
						<div className="flex flex-col items-left text-left ">
							<div className="font-montserrat pb-2 pt-1 font-kacha-font-weight1 text-kacha-text6 p-2">
								Links
							</div>
							<a
								href="/"
								className="font-sans text-kacha-text9   text-kacha-gray-200 p-2"
							>
								Home
							</a>

							<a
								href="/help"
								className="font-sans text-kacha-text9   text-kacha-gray-200 p-2"
							>
								Help
							</a>
						</div>
						{role === "MAKER" && (
							<div className="flex flex-col items-left text-left ">
								<div className="font-montserrat pb-2 pt-1 font-kacha-font-weight1 text-kacha-text6 p-2">
									Services
								</div>
								<a
									href="/"
									className="font-sans text-kacha-text9   text-kacha-gray-200 p-2"
								></a>

								<a
									href="/service/add-company"
									className="font-sans text-kacha-text9   text-kacha-gray-200 p-2"
								>
									Register Company
								</a>
								<a
									href="/service/add-employee"
									className="font-sans text-kacha-text9   text-kacha-gray-200 p-2"
								>
									Register Employee
								</a>

								<a
									href="/report"
									className="font-sans text-kacha-text9   text-kacha-gray-200 p-2"
								>
									Report
								</a>
							</div>
						)}
						{role === "CHEKER" && (
							<div className="flex flex-col items-left text-left ">
								<div className="font-montserrat pb-2 pt-1 font-kacha-font-weight1 text-kacha-text6 p-2">
									Services
								</div>
								<a
									href="/"
									className="font-sans text-kacha-text9   text-kacha-gray-200 p-2"
								></a>

								<a
									href="/service/validate-company"
									className="font-sans text-kacha-text9   text-kacha-gray-200 p-2"
								>
									Approve Company
								</a>
								<a
									href="/service/validate-employee"
									className="font-sans text-kacha-text9   text-kacha-gray-200 p-2"
								>
									Approve Employee
								</a>
								<a
									href="/report"
									className="font-sans text-kacha-text9   text-kacha-gray-200 p-2"
								>
									Report
								</a>
							</div>
						)}
					</div>
				</div>
			</div> */}
			<div className="grid md:grid-cols-1  pl-kacha-padding3 pr-kacha-padding3 md:pl-kacha-padding2 md:pr-kacha-padding2 lg:pl-kacha-padding1 lg:pr-kacha-padding1  pt-4 pb-4">
				<div className=" w-[100%] bg-[#e5e7eb] h-[2px]"></div>
			</div>
			<div className="grid  gap-4 pb-4 pl-kacha-padding3 pr-kacha-padding3 md:pl-kacha-padding2 md:pr-kacha-padding2 lg:pl-kacha-padding1 lg:pr-kacha-padding1  ">
				<div className=" cursor-pointer font-sans ">
					<div className="font-sans flex text-center justify-center md:items-left text-kacha-text10">
						Copyright {new Date().getFullYear()}, © Kacha Digital Financial
						Service S.C, All Rights Reserved
					</div>
				</div>
				<div className=" cursor-pointer flex flex-raw  order-1 md:order-3 col-span-3 md:col-span-1 gap-2 items-center justify-center md:items-center md:justify-end "></div>
			</div>
		</div>
	);
};

export default Footer;
