import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
import { useAuth } from "./context/AuthContext";
import kachaLogo from "./images/kacha-logo.png";
import peaceLogo from "./images/peace-logo.png";

const Home = () => {
	const cookies = new Cookies();
	const token = cookies.get("token");
	const navigate = useNavigate();
	const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
	const [loginForm, setLoginForm] = useState({ username: "", password: "" });
	const [success, setSuccess] = useState("");
	const [error, setError] = useState("");

	const redirectToLogin = () => {
		navigate("/login");
	};

	return (
		<div className="bg-gray-100 h-[80vh] flex flex-col items-center justify-center">
			<div className="text-center ">
				<div className="flex justify-center">
					<img
						src={peaceLogo}
						alt="peace-logo Logo"
						className="mx-auto h-24 w-auto pr-16"
					/>
				</div>
				<h1 className="text-4xl mb-4 pt-8">
					Welcome to Customer Registration and Profiling Portal
				</h1>
				{/* <p className="text-lg text-gray-700 mb-16 ">
					Manage your loans efficiently with our powerful system.
				</p> */}
				{token ? (
					""
				) : (
					<button
						className="bg-kacha-orange-700 hover:bg-kacha-orange-600 text-white py-2 px-4 rounded-full"
						onClick={redirectToLogin}
					>
						Get Started
					</button>
				)}
			</div>
			<div className="flex  items-center justify-center mt-16 pt-8">
				<p className="text-sm text-orange-500 mt-8 text-semibold pr-2">
					Powered by
				</p>
				<img src={kachaLogo} alt="Kacha Logo" className="h-16" />
			</div>
		</div>
	);
};
export default Home;
