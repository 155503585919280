import React from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

function Logout() {
    
    const cookies = new Cookies();
    cookies.remove("token");
    cookies.remove("refresh");
    cookies.remove("access");
    cookies.remove("user");
    cookies.remove("userName");
    cookies.remove("role");
    window.location.reload();
    }

export default Logout;