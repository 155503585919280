import axios from "axios";
import React, { useState, useEffect } from "react";
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";
import Cookies from "universal-cookie";
import moment from "moment";
import { useNotification } from "./NotificationContext";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { BellIcon } from "@heroicons/react/outline";
import { LendingModal } from "../widgets/LendingModal";
import { base_url } from "../api/Axios";
import EditCompany from "./EditCompany";
import Loader from "./Loader";
import Logout from "./Logout";
import { useNavigate } from "react-router-dom";

export class SearchParams {
	first = 0;
	rows = 10;
	searchText = "";
	sortColumn = "";
	sortDirection = 1;
}

const DisapprovedCompanyList = () => {
	const { updateDisapprovedCount } = useNotification();
	const cookies = new Cookies();
	const navigate = useNavigate();
	const token = cookies.get("token");
	const [company, setCompany] = useState(null);
	const [fetchCompanyError, setFetchCompanyError] = useState();
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [url, setUrl] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [detailDataChecker, setDetailDataCheker] = useState();
	const parts = url?.split("/");
	const subUrl = parts?.slice(-3).join("/");
	const [searchParams, setSearchParams] = useState(new SearchParams());
	const [disapprovedCount, setDisapprovedCount] = useState(0);
	const [loading, setLoading] = useState(false);

	const [companyForm, setCompanyForm] = useState({
		name: "",
		phone: "",
		region: null,
		zone: null,
		woreda: "",
		kebele: "",
		street_name: "",
		legal_condition: null,
		reg_num: "",

		paid_up_capital: "",
		manager_name: "",
		regular_phone: "",
		no_employees: null,
		date_registered: null,
		license_number: "",
		sectors: "",
		account_number: "",
		payday: null,

		renewal_status: "",
		license: null,
		contract: null,
		tin: null,
		tin_value: "",
		remark: "",
		is_supplier: false,
		has_supplier: false,
	});

	useEffect(() => {
		const fetchDetailData = async () => {
			try {
				const response = await axios.get(`${base_url}${subUrl}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				setDetailDataCheker(response.data);
				// console.log("Detail Data Checker",detailDataChecker);
				setCompanyForm({
					name: response.data.name,
					phone: response.data.phone,
					region: response.data.region,
					zone: response.data.zone,

					legal_condition: response.data.legal_condition,
					paid_up_capital: response.data.paid_up_capital,
					date_registered: response.data.date_registered,
					license_number: response.data.license_number,
					sectors: response.data.sectors,
					account_number: response.data.account_number,
					payday: response.data.payday,
					renewal_status: response.data.renewal_status,
					license: response.data.license,
					contract: response.data.contract,
					tin: response.data.tin,
					tin_value: response.data.tin_value,
				});
			} catch (error) {
				if(error.response.status === 401){
					Logout();
					
					setTimeout(() => {
					}, 2000);
					navigate("/login");
				}
				console.log(error);
			}
		};

		fetchDetailData();
	}, [subUrl]);

	//   useEffect(() => {
	//     console.log("Company data to be edited:", companyForm);
	//   }, [companyForm]);

	// const fetchCompanyDetail = async () => {
	// 	try {
	// 		const response = await axios.get(`${base_url}${subUrl}`, {
	// 			headers: {
	// 				Authorization: `Bearer ${token}`,
	// 			},
	// 		});
	// 		setCompanyForm({
	//             name: response.data.name,
	//             phone: response.data.phone,
	//             region: response.data.region,
	//             zone: response.data.zone,
	//             woreda: response.data.woreda,
	//             kebele: response.data.kebele,
	//             street_name: response.data.street_name,
	//             legal_condition: response.data.legal_condition,
	//             reg_num: response.data.reg_num,
	//             paid_up_capital: response.data.paid_up_capital,
	//             manager_name: response.data.manager_name,
	//             regular_phone: response.data.regular_phone,
	//             no_employees: response.data.no_employees,
	//             date_registered: response.data.date_registered,
	//             license_number: response.data.license_number,
	//             sectors: response.data.sectors,
	//             account_number: response.data.account_number,
	//             payday: response.data.payday,
	//             renewal_status: response.data.renewal_status,
	//             license: response.data.license,
	//             contract: response.data.contract,
	//             tin: response.data.tin,
	//             tin_value: response.data.tin_value,
	//             remark: response.data.remark,
	//             is_supplier: response.data.is_supplier,
	//             has_supplier: response.data.has_supplier,
	//             });
	// 		console.log("Company data:", response.data);
	// 	}
	// 	catch (error) {
	// 		console.error("Error fetching company data:", fetchCompanyError);
	// 	}
	// };

	const openModal = (companyName, url) => {
		setSelectedCompany(companyName);
		setUrl(url);
		setModalIsOpen(true);
		//  fetchCompanyDetail();
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	useEffect(() => {
		const fetchCompany = async () => {
			setLoading(true);
			try {
				const response = await axios.get(
					`${base_url}orgs/?f=${searchParams.first}&limit=${searchParams.rows}&offset=${searchParams.first}&status=DISAPPROVED`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setCompany(response.data);
				setDisapprovedCount(response.data.count);
				updateDisapprovedCount(response.data.count);
				setLoading(false);
			} catch (error) {
				if(error.response.status === 401){
					Logout();
					setFetchCompanyError(error?.response?.data[0]);
					setTimeout(() => {
						setFetchCompanyError("");
					}, 2000);
					navigate("/login");
				}
				setFetchCompanyError(error?.response?.data[0]);
				setLoading(false);
				console.error("Error fetching company data:", fetchCompanyError);
			}
		};

		fetchCompany();
	}, [searchParams, modalIsOpen]); // Empty dependency array means this effect runs once on mount

	// console.log(fetchCompanyError);
	const displayCompany =
		company?.results?.map((companyList, index) => ({
			url: companyList?.url,
			name: companyList?.name,
			phone: companyList?.phone,
			tin_value: companyList?.tin_value,
			created: companyList?.created_at,
			status: companyList?.status,
		})) || null;
	const goNext = () => {
		if (searchParams.first + searchParams.rows < company.count) {
			setSearchParams({
				...searchParams,
				first: searchParams.first + searchParams.rows,
			});
		}
	};

	const goBack = () => {
		if (searchParams.first > 0) {
			setSearchParams({
				...searchParams,
				first: searchParams.first - searchParams.rows,
			});
		}
	};
	return (
		<>
			<div className="min-h-[50vh] flex flex-col justify-center items-center">
				{
					loading && (
						<Loader />
					)
				}
				{fetchCompanyError ? (
					<div>Error fetching company data. Please try Agian.</div>
				) : (
					company?.count > 0 ? (
					<div className="overflow-x-auto w-full py-8 px-2">
						<table className="min-w-full  border-gray-300">
							<thead>
								<tr className="text-left">
									<th className="py-2 px-4 border-b">No.</th>
									<th className="py-2 px-4 border-b">Name</th>
									<th className="py-2 px-4 border-b">Phone</th>
									<th className="py-2 px-4 border-b">TIN</th>
									<th className="py-2 px-4 border-b">Created</th>
									<th className="py-2 px-4 border-b">Status</th>
									<th className="py-2 px-4 border-b">Change</th>
								</tr>
							</thead>
							<tbody className="align-center text-left">
								{displayCompany?.map((companyInfo, index) => (
									<tr
										key={index}
										className={index % 2 === 0 ? "bg-gray-100" : ""}
									>
										<td className="py-2 px-4 border-b">{searchParams.first + index + 1}</td>
										<td className=" py-2 px-4 border-b">{companyInfo?.name}</td>
										<td className="py-2 px-4 border-b">{companyInfo?.phone}</td>
										<td className="py-2 px-4 border-b">
											{companyInfo?.tin_value}
										</td>
										<td className="py-2 px-4 border-b">
											{companyInfo?.created
												? moment(companyInfo?.created).format("YYYY-MM-DD")
												: ""}
										</td>
										<td className="py-2 px-4 border-b">
											{companyInfo?.status}
										</td>
										<td className="py-2 px-4 border-b">
											<button
												onClick={() =>
													openModal(
														companyInfo?.name ? companyInfo?.name : "Anonymous",
														companyInfo?.url
													)
												}
												className="bg-kacha-orange-700 hover:bg-kacha-orange-600 text-white font-bold py-2 px-4 rounded"
											>
												Edit
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						{selectedCompany && (
							<EditCompany
								isOpen={modalIsOpen}
								closeModal={closeModal}
								reviewData={detailDataChecker}
								dataTobeUpdated={companyForm}
								setCompanyForm={setCompanyForm}
								sub_url={subUrl}
							/>
						)}
					</div>
					): (
						<div className="flex flex-col justify-center items-center">
							<div className="flex flex-col justify-center items-center">
								<ExclamationCircleIcon className="h-10 w-10 text-orange-500" />
								<div className="text-gray-500 text-lg font-bold">
									No Disapproved Company Found
								</div>
							</div>
						</div>
					)
				)}
				{
				company?.count > 0 && (
				<div className="flex items-center  justify-between w-md">
					<div className="flex items-center space-x-2">
						<span className="text-gray-600">Items per page: </span>
						<div className="relative inline-block">
							<select
								className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:bg-white"
								value={searchParams.rows}
								onChange={(e) => {
									searchParams.rows = Number(e.target.value);
									setSearchParams({ ...searchParams });
								}}
							>
								<option value="10"> 10</option>
								<option value="20">20</option>
								<option value="50">50</option>
							</select>
							<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
								<svg
									className="fill-current h-4 w-4"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
								>
									<path d="M10 12.5l-5-5 1.5-1.5L10 9.5l3.5-3.5L15 7z" />
								</svg>
							</div>
						</div>
					</div>

					<span className="text-gray-600 px-10">
						{searchParams.first + 1} - {searchParams.first + searchParams.rows}{" "}
						of {company?.count || 0}
					</span>

					<div className="flex items-center space-x-4">
						<div onClick={goBack}>

							<svg
								xmlns="http://www.w3.org/2000/svg"
								className={`${
									searchParams.first === 0
										? "opacity-50 cursor-not-allowed"
										: ""
								}  h-8 w-8 text-orange-700 font-bold`}
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M15 19l-7-7 7-7"
								/>
							</svg>{" "}
						</div>
						<div onClick={goNext} className="">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className={`${
									searchParams.first + searchParams.rows >= company?.count
										? "opacity-50 cursor-not-allowed"
										: ""
								}  h-8 w-8  text-orange-700  font-bold`}
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M9 5l7 7-7 7"
								/>
							</svg>
						</div>
					</div>
				</div>
				)
				}
			</div>
		</>
	);
};

export default DisapprovedCompanyList;
