import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { base_url } from "../api/Axios";
import informationIcon from "../images/information-button.png";
import { registorEmployee } from "../utils/Validation";
import Logout from "./Logout";

const customStyles = {
	content: {
		width: "50%",
		maxWidth: "80%",
		margin: "auto",
		borderRadius: "8px",
		boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
		top: "100px",
	},
	overlay: {
		backgroundColor: "rgba(0, 0, 0, 0.5)",
	},
	closeButton: {
		position: "absolute",
		top: "10px",
		right: "10px",
		background: "none",
		border: "none",
		cursor: "pointer",
		fontSize: "16px",
		color: "red",
	},
};

const EditEmployee = ({
	isOpen,
	closeModal,
	reviewData,
	name,
	sub_url,
	dataTobeUpdated,
}) => {
	const cookies = new Cookies();
	const navigate = useNavigate();
	const token = cookies.get("token");
	const [error, setError] = useState();
	const [success, setSuccess] = useState();
	const [errorBulk, setErrorBulk] = useState();
	const [successBulk, setSuccessBulk] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [contract, setContract] = useState();
	const [license, setLicense] = useState();
	const [tin, setTin] = useState();
	const [file, setFile] = useState();
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [employer, setEmployer] = useState();
	const currentDate = new Date().toISOString().split("T")[0];

	const url = `${base_url}`;

	const legal = [
		{
			value: 1,
			display_name: "Private",
		},
		{
			value: 2,
			display_name: "Private Limited Company",
		},
		{
			value: 3,
			display_name: "Share Company",
		},
		{
			value: 4,
			display_name: "Commercial Representative",
		},
		{
			value: 5,
			display_name: "Public Enterprise",
		},
		{
			value: 6,
			display_name: "Partnership",
		},
		{
			value: 7,
			display_name: "Cooperatives Association",
		},
		{
			value: 9,
			display_name: "Trade Sectoral Association",
		},
		{
			value: 10,
			display_name: "Non-Public Enterprise",
		},
		{
			value: 11,
			display_name: "NGO",
		},
		{
			value: 12,
			display_name: "Branch of A Foreign Chamber of Commerce",
		},
		{
			value: 13,
			display_name: "Holding Company",
		},
		{
			value: 14,
			display_name: "Franchising",
		},
		{
			value: 15,
			display_name: "Border Trade",
		},
		{
			value: 19,
			display_name: "International Bid Winners Foreign Companies",
		},
		{
			value: 21,
			display_name: "One Man Private Limited Company",
		},
	];

	const maritalStatus = [
		{
			name: "Single",
			value: "NOT_MARRIED",
		},
		{
			name: "Married",
			value: "MARRIED",
		},
		{
			name: "Divorced",
			value: "DIVORCED",
		},
		{
			name: "Widowed",
			value: "WIDOWED",
		},
	];

	const genderOptions = [
		{
			name: "Male",
			value: "MALE",
		},
		{
			name: "Female",
			value: "FEMALE",
		},
	];

	const position = [
		{
			value: "Labour",
			display_name: "Labour",
		},
		{
			value: "Entry",
			display_name: "Entry",
		},
		{
			value: "Junior",
			display_name: "Junior",
		},
		{
			value: "Mid-Senior",
			display_name: "Mid-Senior",
		},
		{
			value: "Senior",
			display_name: "Senior",
		},
		{
			value: "Lead",
			display_name: "Lead",
		},
		{
			value: "MANAGER",
			display_name: "Manager",
		},
		{
			value: "Principal",
			display_name: "Principal",
		},
		{
			value: "Chief Officer",
			display_name: "Chief Officer",
		},
		{
			value: "Director",
			display_name: "Director",
		},
		{
			value: "Vice President",
			display_name: "Vice President",
		},
		{
			value: "Chief Vice President",
			display_name: "Chief Vice President",
		},
		{
			value: "President",
			display_name: "President",
		},
		{
			value: "Chief Executive Officer (CEO)",
			display_name: "Chief Executive Officer (CEO)",
		},
		{
			value: "Chairman",
			display_name: "Chairman",
		},
		{
			value: "Board of Directors",
			display_name: "Board of Directors",
		},
	];
	const [employeeForm, setEmloyeeForm] = useState({
		first_name: dataTobeUpdated?.first_name,
		middle_name: dataTobeUpdated?.middle_name,
		last_name: dataTobeUpdated?.last_name,
		gender: dataTobeUpdated?.gender,
		martial_status: dataTobeUpdated?.martial_status,

		phone: dataTobeUpdated?.phone,
		employer: dataTobeUpdated?.employer,
		date_of_employment: dataTobeUpdated?.date_of_employment,

		gross_salary: dataTobeUpdated?.gross_salary,
		net_salary: dataTobeUpdated?.net_salary,
		account_number: dataTobeUpdated?.account_number,
		position: dataTobeUpdated?.position,
		total_work_experience: dataTobeUpdated?.total_work_experience,
		remark: dataTobeUpdated?.remark,
	});

	useEffect(() => {
		setEmloyeeForm({
			first_name: dataTobeUpdated?.first_name,
			middle_name: dataTobeUpdated?.middle_name,
			last_name: dataTobeUpdated?.last_name,
			gender: dataTobeUpdated?.gender,
			martial_status: dataTobeUpdated?.martial_status,

			phone: dataTobeUpdated?.phone,
			employer: dataTobeUpdated?.employer,
			date_of_employment: dataTobeUpdated?.date_of_employment,

			gross_salary: dataTobeUpdated?.gross_salary,
			net_salary: dataTobeUpdated?.net_salary,
			account_number: dataTobeUpdated?.account_number,
			position: dataTobeUpdated?.position,
			total_work_experience: dataTobeUpdated?.total_work_experience,
			remark: dataTobeUpdated?.remark,
		});
	}, [sub_url]);

	console.log("employeeForm", employeeForm);
	const [bulkRegistorForm, setBulkRegistorForm] = useState({
		file: null,
		remark: "",
	});

	useEffect(() => {
		const fetchEmployeeInfo = async () => {
			setEmloyeeForm({
				first_name: "",
				middle_name: "",
				last_name: "",
				gender: null,
				martial_status: "",
				phone: "",
				employer: null,
				date_of_employment: null,

				gross_salary: null,
				net_salary: null,
				account_number: "",
				position: null,
				total_work_experience: null,
				remark: "",
			});
			try {
				const response = await axios.get(`${url}${sub_url}`, {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${token}`,
					},
				});
				console.log("response", response.data);
				setEmloyeeForm({
					first_name: response.data?.first_name,
					middle_name: response.data?.middle_name,
					last_name: response.data?.last_name,
					gender: response.data?.gender,
					martial_status: response.data?.martial_status,
					phone: response.data?.phone,
					employer: response.data?.employer,
					date_of_employment: response.data?.date_of_employment,

					gross_salary: response.data?.gross_salary,
					net_salary: response.data?.net_salary,
					account_number: response.data?.account_number,
					position: response.data?.position,
					total_work_experience: response.data?.total_work_experience,
					remark: response.data?.remark,
				});
			} catch (error) {
				if (error.response.status === 401) {
					Logout();
					navigate("/login");
				}
				console.log(error);
			}
		};
		fetchEmployeeInfo();
	}, [sub_url]);

	useEffect(() => {
		const fetchEmployer = async () => {
			try {
				const response = await axios.get(`${base_url}orgs_list/`, {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${token}`,
					},
				});
				setEmployer(
					response.data?.sort((a, b) => a.name.localeCompare(b.name))
				);
			} catch (error) {
				if (error.response.status === 401) {
					Logout();
					navigate("/login");
				}
				console.log(error);
			}
		};
		fetchEmployer();
	}, []);

	console.log("employer", employer);

	const displayEmployer = employer?.map((list) => ({
		code: list?.code,
		name: list?.name,
	}));

	const openModal = (values) => {
		setModalIsOpen(true);
		setEmloyeeForm({
			...values,
		});
		console.log(values);
		handleUpdateEmployee(values);
	};

	const handleNext = () => {
		setCurrentPage(currentPage + 1);
	};
	const handleBack = () => {
		setCurrentPage(currentPage - 1);
	};

	const handleUpdateEmployee = async (values) => {
		const result = await axios
			.put(`${url}${sub_url}`, values, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				setSuccess("Employee updated successfully");
				setTimeout(() => {
					setSuccess("");
					closeModal();
				}, 3000);
			})
			.catch((error) => {
				if (error.response.status === 401) {
					Logout();
					navigate("/login");
				}
				setError("Employee updated failed");
				setTimeout(() => {
					setError("");
					// closeModal();
				}, 3000);
			});
	};

	return (
		<Modal
			style={customStyles}
			isOpen={isOpen}
			onRequestClose={closeModal}
			enter="ease-out duration-300"
			enterFrom="opacity-0 scale-95"
			enterTo="opacity-100 scale-100"
			leave="ease-in duration-200"
			leaveFrom="opacity-100 scale-100"
			leaveTo="opacity-0 scale-95"
		>
			<div className="relative  bg-white">
				<button style={customStyles.closeButton} onClick={closeModal}>
					<i className="x large icon" />
				</button>
				<div className="responsive-aware-form flex  items-center justify-center   pb-8">
					<div className=" flex item-center    p-8">
						<div className="flex flex-col items-center  justify-center">
							<div className="flex flex-col items-center  justify-center">
								<h1 className="text-center font-montserrata text-kacha-text3 pb-4">
									Update Employee Information
								</h1>
								<div className="flex ">
									<img src={informationIcon} alt="logo" className="h-6 pr-2" />
									Please enter all mandatory fields indicated with
									<span className="text-red-500 pl-2 pr-2 font-semibold">
										{" "}
										*{" "}
									</span>{" "}
									before proceed to next page!
								</div>
								<div className="mt-5" />
								<div className="flex flex-col">
									<span className="text-kacha-text9"> Remark</span>
									<textarea
										type="text"
										name="remark"
										value={employeeForm.remark}
										disabled
										className=" italic font-sans border border-red-400 p-3 rounded-md text-kacha-text9 min-w-[400px] max-w-[500px]"
									/>
								</div>
							</div>
							{employeeForm.first_name ? (
								<Formik
									initialValues={employeeForm}
									validationSchema={registorEmployee}
									onSubmit={(values) => {
										openModal(values);
									}}
								>
									{(formik) => (
										<Form>
											{currentPage === 1 && (
												<>
													<div className="mt-5" />

													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															First Name <span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[400px] max-w-[400px]"
															name="first_name"
															as="input"
															type="text"
															// placeholder="Abebe"
														/>
														{formik.errors.first_name &&
															formik.touched.first_name && (
																<div className="error text-red-500">
																	{formik.errors.first_name}
																</div>
															)}
													</div>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Middle Name{" "}
															<span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="middle_name"
															as="input"
															type="text"
															// placeholder="Hagos"
														/>
														{formik.errors.middle_name &&
															formik.touched.middle_name && (
																<div className="error text-red-500">
																	{formik.errors.middle_name}
																</div>
															)}
													</div>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Last Name <span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="last_name"
															as="input"
															type="text"
															// placeholder="Challa"
														/>
														{formik.errors.last_name &&
															formik.touched.last_name && (
																<div className="error text-red-500">
																	{formik.errors.last_name}
																</div>
															)}
													</div>

													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Gender<span className="text-red-500">*</span>
														</label>
														<Field
															as="select"
															name="gender"
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
														>
															<option value="">---</option>
															{genderOptions.map((list) => (
																<option key={list.value} value={list.value}>
																	{list.name}
																</option>
															))}
														</Field>
														{formik.errors.gender && formik.touched.gender && (
															<div className="error text-red-500">
																{formik.errors.gender}
															</div>
														)}
													</div>

													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Marital Status
														</label>
														<Field
															as="select"
															id="martial_status"
															name="martial_status"
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
														>
															<option value="">---</option>
															{maritalStatus.map((list) => (
																<option key={list.value} value={list.value}>
																	{list.name}
																</option>
															))}
														</Field>
														{formik.errors.martial_status &&
															formik.touched.martial_status && (
																<div className="error text-red-500">
																	{formik.errors.martial_status}
																</div>
															)}
													</div>

													<div className="mt-5" />
													<div className="flex flex-col min-w-[300px] max-w-[400px]">
														<label className="mb-1 text-kacha-text9">
															Phone Number
															<span className="text-red-500">*</span>
														</label>
														<fieldset className="flex flex-row text-left min-w-[300px] max-w-[400px]">
															<div className="relative ">
																<div className="absolute inset-y-0 left-0 flex  p-3 pointer-events-none text-center">
																	<div className="flag-icon">🇪🇹 +</div>
																</div>
																<Field
																	className="border-l border-t border-b border-gray-300 p-3 rounded-l-md text-kacha-text9 min-w-[40px] max-w-[60px] text-center"
																	name="countryCode"
																	as="input"
																	type="text"
																	disabled
																/>
															</div>
															<div className="flex flex-col">
																<Field
																	className="border border-gray-300 p-3 rounded-r-md text-kacha-text9 min-w-[340px] max-w-[340px]"
																	name="phone"
																	as="input"
																	type="text"
																	// placeholder="Company Phone"
																/>
																{formik.errors.phone &&
																	formik.touched.phone && (
																		<div className="error text-red-500">
																			{formik.errors.phone}
																		</div>
																	)}
															</div>
														</fieldset>
													</div>
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Employer ID<span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="employer"
															as="select"
														>
															<option value="">---</option>
															{employer?.map((list) => (
																<option key={list?.code} value={list?.code}>
																	{list?.name}
																</option>
															))}
														</Field>
														{formik.errors.employer &&
															formik.touched.employer && (
																<div className="error text-red-500">
																	{formik.errors.employer}
																</div>
															)}
													</div>
													<div className="mt-5" />

													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Date of Employment{" "}
															<span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="date_of_employment"
															as="input"
															type="date"
															max={currentDate}
														/>
														{formik.errors.date_of_employment &&
															formik.touched.date_of_employment && (
																<div className="error text-red-500">
																	{formik.errors.date_of_employment}
																</div>
															)}
													</div>
													<div className="mt-5" />
													<button
														className=" bg-kacha-orange-700 text-center  m-2 p-3 min-w-[200px] text-white rounded-md text-lg"
														type="button"
														onClick={handleNext}
														disabled={
															!!formik.errors.first_name ||
															!!formik.errors.middle_name ||
															!!formik.errors.last_name ||
															!!formik.errors.gender ||
															!!formik.errors.phone ||
															!!formik.errors.employer ||
															!!formik.errors.date_of_employment
														}
													>
														Next
													</button>
												</>
											)}
											{currentPage === 2 && (
												<>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Basic Salary{" "}
															<span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="gross_salary"
															as="input"
															type="number"
															// placeholder="Enter the gross_salary"
														/>
														{formik.errors.gross_salary &&
															formik.touched.gross_salary && (
																<div className="error text-red-500">
																	{formik.errors.gross_salary}
																</div>
															)}
													</div>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Net Salary <span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="net_salary"
															as="input"
															type="number"
															// placeholder="Enter the net gross_salary"
														/>
														{formik.errors.net_salary &&
															formik.touched.net_salary && (
																<div className="error text-red-500">
																	{formik.errors.net_salary}
																</div>
															)}
													</div>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Salary Account
															<span className="text-red-500">*</span>
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="account_number"
															as="input"
															type="number"
															// placeholder="Enter the account number"
														/>
														{formik.errors.account_number &&
															formik.touched.account_number && (
																<div className="error text-red-500">
																	{formik.errors.account_number}
																</div>
															)}
													</div>
													<div className="mt-5" />

													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Position
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="position"
															as="select"
														>
															<option value="">---</option>
															{position.map((list) => (
																<option key={list.value} value={list.value}>
																	{list.display_name}
																</option>
															))}
														</Field>
														{formik.errors.position &&
															formik.touched.position && (
																<div className="error text-red-500">
																	{formik.errors.position}
																</div>
															)}
													</div>
													<div className="mt-5" />
													<div className="flex flex-col">
														<label className="mb-1 text-kacha-text9">
															Total Work Experience
														</label>
														<Field
															className="border border-gray-300 p-3 rounded-md text-kacha-text9 min-w-[300px] max-w-[400px]"
															name="total_work_experience"
															as="input"
															type="number"
															// placeholder="Enter the account number"
														/>
														{formik.errors.total_work_experience &&
															formik.touched.total_work_experience && (
																<div className="error text-red-500">
																	{formik.errors.total_work_experience}
																</div>
															)}
													</div>

													<div className="mt-5" />
													<div className="mt-5" />
													<div className="text-kacha-text10">
														By proceeding you are accepting terms and conditions
														of the MFI.
													</div>
													<div className="mt-5" />
													<>
														{success && (
															<div className="text-green-700 text-kacha-text7 p-2  z-50 bg-white">
																{success}
															</div>
														)}
														{error && (
															<div className="text-red-500 text-kacha-text7 p-2  z-50 bg-white">
																Please Try Again. {error} !!
															</div>
														)}
													</>
													<div className=" flex">
														<button
															type="button"
															onClick={handleBack}
															className=" bg-kacha-orange-700 text-center cursor-pointer m-2 p-3 min-w-[200px] text-white rounded-md text-lg"
														>
															Back
														</button>
														<button
															className=" bg-kacha-orange-700 text-center cursor-pointer m-2 p-3 min-w-[200px]  text-white rounded-md text-lg"
															type="submit"
															disabled={
																!!formik.errors.gross_salary ||
																!!formik.errors.net_salary ||
																!!formik.errors.account_number
															}
														>
															Submit
														</button>
													</div>
												</>
											)}
										</Form>
									)}
								</Formik>
							) : (
								<div className="text-center py-8 text-red-500">
									This Employee registration missed mandatory fields!!
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default EditEmployee;
