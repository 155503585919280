import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Navbar from "./Navbar";
import Home from "./Home";
import Footer from "./Footer";
import { AuthProvider } from "./context/AuthContext";
import ChangeFirstTimePassword from "./pages/ChangeFirstTimePassword";
import RegistorCompany from "./pages/RegistorCompany";
import Cookies from "universal-cookie";
import RegistorEmployee from "./pages/RegistorEmployee";
import AddTransaction from "./pages/AddTransaction";
import ViewConsents from "./pages/ViewConsents";
import Report from "./pages/Report";
import FAQ from "./pages/FAQ";
import Contact from "./pages/Contact";
import ValidateCompany from "./pages/ValidateCompany";
import ValidateEmployee from "./pages/ValidateEmployee";
import ChangePassword from "./pages/ChangePassword";
import DisapprovedCompanyList from "./pages/DisapprovedCompanyList";
import DisapprovedEmployeeList from "./pages/DisapprovedEmployeeList";
import Dashboard from "./pages/Dashboard";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import UserGuide from "./pages/UserGuide";
import Loader from "./pages/Loader";

const App = () => {
	const cookie = new Cookies();
	const token = cookie.get("token");
	return (
		<AuthProvider>
			<BrowserRouter>
				<div className="sticky z-50 top-0">
					<Navbar />
				</div>

				<Routes>
					<Route path="*" element={<Loader />} />
					<Route path="/loader" element={<Loader />} />
					{token && (
						<Route
							forceRefresh={true}
							path="/forgot-password"
							exact
							element={<ForgotPassword />}
						/>
					)}
					{token && (
						<Route
							forceRefresh={true}
							path="/dashboard"
							exact
							element={<Dashboard />}
						/>
					)}

					<Route path="/" exact element={<Home />} />
					<Route forceRefresh={true} path="/login" exact element={<Login />} />
					{token && (
						<Route
							path="/service/add-employee"
							exact
							element={<RegistorEmployee />}
						/>
					)}
					{/* <Route path="/service" exact element={<RegistorCompany />} /> */}
					{token && (
						<Route
							forceRefresh={true}
							path="/service/add-company"
							exact
							element={<RegistorCompany />}
						/>
					)}
					{token && (
						<Route
							forceRefresh={true}
							path="/service/disapproved-company"
							exact
							element={<DisapprovedCompanyList />}
						/>
					)}
					{token && (
						<Route
							forceRefresh={true}
							path="/service/validate-company"
							exact
							element={<ValidateCompany />}
						/>
					)}
					{token && (
						<Route
							forceRefresh={true}
							path="/service/validate-employee"
							exact
							element={<ValidateEmployee />}
						/>
					)}

					{token && (
						<Route
							forceRefresh={true}
							path="/service/disapproved-employee"
							exact
							element={<DisapprovedEmployeeList />}
						/>
					)}

					{token && <Route path="/service/report" exact element={<Report />} />}

					<Route
						forceRefresh={true}
						path="/change-first-time-password"
						exact
						element={<ChangeFirstTimePassword />}
					/>

					{token && (
						<Route
							forceRefresh={true}
							path="/change-password"
							exact
							element={<ChangePassword />}
						/>
					)}
					<Route path="/faq" exact element={<FAQ />} />
					<Route path="/user-guide" exact element={<UserGuide />} />
				</Routes>
				<Footer />
			</BrowserRouter>
		</AuthProvider>
	);
};

export default App;
