import React, { useState } from "react";

const faqData = [
	{
		question: "What is Kacha?",
		answer:
			"Kacha is a payment instrument issuer licensed by the National MFI of Ethiopia. We work in a digital financial space to ensure access finance.",
	},
	{
		question: "What are the advantages of Kacha services?",
		answer:
			"You can send and receive money, get access to loan, micro-insurance service, pension products, international remittance.",
	},
	{
		question: "How can I register?",
		answer:
			"You just need to dial *677# or download the app from Playstore or Appstore and proceed the registration.",
	},
	{
		question: "Where can I register?",
		answer:
			"You can visit nearby outlet , agent , dial *677# download the app from playstore or appstore.",
	},

	{
		question: "I forgot my PIN code, what can I do?",
		answer: "Dial 9964 or visit nearby outlet to reset your PIN.",
	},
	{
		question: "How can I become an agent?",
		answer:
			"You need to have a valid and renewed trade license. Please call for more support via 9964 or drop us an e-mail contact@kachadfs.com for detail.",
	},
	{
		question: "What is my account number in Kacha ?",
		answer:
			"Customers account number is his/her mobile subscriber number / MSIN. That means you can just use someone mobile number to send money to.",
	},
	{
		question: "Where is Kacha head quarter located?",
		answer:
			"Our head office is located in Bole Sub City, Kebele, Africa Avenue Noah Plaza Mezzanine Floor building Addis Ababa, Ethiopia.",
	},
];
const FAQ = () => {
	const [openIndex, setOpenIndex] = useState(0); //FAQ
	const toggleAccordion = (index) => {
		if (openIndex === index) {
			setOpenIndex(null); // Close if it's already open
		} else {
			setOpenIndex(index); // Open the selected FAQ
		}
	};
	return (
		<div>
			<div className="pl-kacha-padding3 pr-kacha-padding3 md:pl-kacha-padding2 md:pr-kacha-padding2 lg:pl-kacha-padding1 lg:pr-kacha-padding1 pb-2">
				<div className="font-montserrat text-center text-kacha-text4 md:text-kacha-text3 lg:text-kacha-text2 text-kacha-yellow-200   font-kacha-font-weight1 pt-6 pb-6 md:pt-10 md:pb-10 lg:pt-20 lg:pb-20">
					FAQ
				</div>
				<div className="grid grid-cols-8 gap-4 md:gap-8 align-start">
					<div className="col-span-8 ">
						{faqData.map((faq, index) => (
							<div key={index} className="border-b border-gray-300">
								<button
									className="flex items-center justify-between w-full p-4 text-left focus:outline-none"
									onClick={() => toggleAccordion(index)} // Toggle FAQ on click
								>
									<span className="font-montserrat text-kacha-text8 md:text-kacha-text7 lg:text-kacha-text6 font-kacha-font-weight1">
										{faq.question}
									</span>
									<span className="font-sans text-kacha-text9 md:text-kacha-text8 lg:text-kacha-text7 font-kacha-font-weight1 text-kacha-orange-700">
										{openIndex === index ? "-" : "+"}
									</span>
								</button>
								<div
									className={`font-sans p-4 text-kacha-text8 md:text-kacha-text7 lg:text-kacha-text6 ${
										openIndex === index ? "" : "hidden"
									}`}
								>
									{faq.answer}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default FAQ;
