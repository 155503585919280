import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { base_url } from "./api/Axios";

const ForgotPassword = () => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleForgotPassword = async (values) => {
    try {
      const response = await axios.post(`${base_url}forgot-password/`, values);
      setSuccess(response.data.detail);
      setError(null);
    } catch (error) {
      setError(error.response?.data?.detail);
      setSuccess(null);
    }
  };

  return (
    <div className="relative bg-white">
      <div className="responsive-aware-form flex flex-col items-center justify-center mt-8 pb-8">
        <div className="flex flex-col item-center shadow-kacha-box-shadow items-center justify-center p-28">
          <h1 className="text-center font-montserrata text-kacha-text3">
            Forgot Password
          </h1>
          <h4 className="m-5 text-center text-kacha-gray-500 text-kacha-text8">
            Enter your email address to reset your password.
          </h4>

          <Formik
            initialValues={{ email: "" }}
            onSubmit={(values) => handleForgotPassword(values)}
          >
            {(formik) => (
              <Form>
                <div className="mt-5" />
                <div className="flex flex-col">
                  <label className="mb-1 text-kacha-text9">Email</label>
                  <Field
                    className="border border-gray-300 w-[360px] p-3 rounded-md text-kacha-text9"
                    name="email"
                    type="email"
                    placeholder="Enter your email address"
                  />
                </div>
                <div className="mt-5" />
                {error && (
                  <div className="text-red-500 text-kacha-text7 p-2">
                    {error}
                  </div>
                )}
                {success && (
                  <div className="text-green-700 text-kacha-text7 p-2">
                    {success}
                  </div>
                )}
                <div className="mt-5" />
                <div className="bg-kacha-orange-700 text-center cursor-pointer rounded-md text-lg">
                  <button
                    className="text-center text-kacha-text8 cursor-pointer text-white p-3"
                    type="submit"
                    disabled={!formik.isValid}
                  >
                    Reset Password
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
