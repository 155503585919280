import { ExclamationCircleIcon } from "@heroicons/react/solid";
import axios from "axios";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { base_url } from "../api/Axios";
import peaceLogo from "../images/peace-logo.png";
import { registorEmployee } from "../utils/Validation";
import Loader from "./Loader";
import Logout from "./Logout";

const tableColums = [
	"District Name",
	"Branch Name",
	"Customer",
	"Status",
	"Activation Date",
];

export class SearchParams {
	first = 0;
	rows = 10;
	searchText = "";
	sortColumn = "";
	sortDirection = 1;
}

const RegistorEmployee = () => {
	const cookies = new Cookies();
	const navigate = useNavigate();
	const token = cookies.get("token");
	const [company, setCompany] = useState(null);
	const [fetchCompanyError, setFetchCompanyError] = useState();
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [employee, setEmployee] = useState(null);
	const [fetchEmployeeError, setFetchEmployeeError] = useState();
	const [url, setUrl] = useState(null);
	const [filterOption, setFilterOption] = useState("ALL_COMPANY");
	const [filterOptionEmployee, setFilterOptionEmployee] = useState("");
	const [filterOptionCompany, setFilterOptionCompany] = useState("");
	const [prevFilterOption, setPrevFilterOption] = useState("");
	const [registeredCompanies, setRegisteredCompanies] = useState([]);
	const [searchResult, setSearchResult] = useState("");
	const [loading, setLoading] = useState(false);
	const [report, setReportForm] = useState({
		dateFrom: "",
		dateTo: "",
	});
	const [dateFrom, setDateFrom] = useState("");
	const [dateTo, setDateTo] = useState("");
	const [error, setError] = useState();
	const [searchParams, setSearchParams] = useState(new SearchParams());
	const currentDate = new Date().toISOString().split("T")[0];

	const reportDate = (values) => {
		setReportForm(values);
	};

	//search
	useEffect(() => {
		const searchCompany = async () => {
			setLoading(true);
			try {
				const response = await axios.get(
					`${base_url}orgs/?search=${searchResult}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setCompany(response.data);
				setLoading(false);
				console.log("Company data:", response.data);
			} catch (error) {
				if (error.response.status === 401) {
					Logout();
					setFetchEmployeeError(error?.response?.data[0]);
					setTimeout(() => {
						setFetchEmployeeError("");
					}, 2000);
					navigate("/login");
				}
				setFetchCompanyError(error?.response?.data[0]);
				setLoading(false);
				console.error("Error fetching company data:", fetchCompanyError);
			}
		};

		searchCompany();
	}, [searchResult]);

	//filter
	useEffect(() => {
		if (prevFilterOption !== filterOption) {
			setSearchParams({
				...searchParams,
				first: 0,
			});
		}
		const fetchEmployeeByDate = async () => {
			setLoading(true);
			if (
				filterOption === "ALL_EMPLOYEE" ||
				filterOption === "PENDING_EMPLOYEE" ||
				filterOption === "ACTIVE_EMPLOYEE" ||
				filterOption === "DISAPPROVED_EMPLOYEE" ||
				filterOption === "INACTIVE_EMPLOYEE" ||
				filterOption === "SUSPENDED_EMPLOYEE"
			) {
				try {
					const response = await axios.get(
						`${base_url}employees/?created_at_min=${dateFrom}&created_at_max=${dateTo}&f=${searchParams.first}&limit=${searchParams.rows}&offset=${searchParams.first}&status=${filterOptionEmployee}`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					);
					setEmployee(response.data);
					setLoading(false);
					console.log("Employee data:", response.data);
				} catch (error) {
					if (error.response.status === 401) {
						Logout();
						setFetchEmployeeError(error?.response?.data[0]);
						setTimeout(() => {
							setFetchEmployeeError("");
						}, 2000);
						navigate("/login");
					}
					setFetchEmployeeError(error?.response?.data[0]);
					setLoading(false);
					console.error("Error fetching employee data:", fetchEmployeeError);
				}
			}
			if (
				filterOption === "ALL_COMPANY" ||
				filterOption === "PENDING_COMPANY" ||
				filterOption === "ACTIVE_COMPANY" ||
				filterOption === "DISAPPROVED_COMPANY" ||
				filterOption === "INACTIVE_COMPANY" ||
				filterOption === "SUSPENDED_COMPANY"
			) {
				try {
					const response = await axios.get(
						`${base_url}orgs/?created_at_min=${dateFrom}&created_at_max=${dateTo}&f=${searchParams.first}&limit=${searchParams.rows}&offset=${searchParams.first}&status=${filterOptionCompany}`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					);
					setCompany(response.data);
					setLoading(false);
					console.log("Employee data:", response.data);
				} catch (error) {
					if (error.response.status === 401) {
						Logout();
						setFetchCompanyError(error?.response?.data[0]);
						setTimeout(() => {
							setFetchEmployeeError("");
						}, 2000);
						navigate("/login");
					}
					setFetchCompanyError(error?.response?.data[0]);
					setLoading(false);
					console.error("Error fetching Companydata:", fetchCompanyError);
				}
			}
		};

		// 	try {
		// 		const response = await axios.get(
		// 			`${base_url}employees/?created_at_min=${dateFrom}&created_at_max=${dateTo}&f=${searchParams.first}&limit=${searchParams.rows}&offset=${searchParams.first}&status=${filterOptionEmployee}`,
		// 			{
		// 				headers: {
		// 					Authorization: `Bearer ${token}`,
		// 				},
		// 			}
		// 		);
		// 		setEmployee(response.data);
		// 		setPrevFilterOption(filterOption);
		// 		console.log("Employee data:", response.data);
		// 	} catch (error) {
		// 		setFetchEmployeeError(error?.response?.data[0]);
		// 		console.error("Error fetching employee data:", fetchEmployeeError);
		// 	}
		// };
		setPrevFilterOption(filterOption);
		fetchEmployeeByDate();
	}, [dateFrom, dateTo, filterOption, searchParams]);

	// useEffect(() => {
	// 	const fetchCompanyByDate = async () => {
	// 		if (prevFilterOption !== filterOption) {
	// 			setSearchParams({
	// 				...searchParams,
	// 				first: 0,
	// 			});
	// 		}
	// 		try {
	// 			const response = await axios.get(
	// 				`${base_url}orgs/?created_at_min=${dateFrom}&created_at_max=${dateTo}&f=${searchParams.first}&limit=${searchParams.rows}&offset=${searchParams.first}&status=${filterOptionCompany}`,
	// 				{
	// 					headers: {
	// 						Authorization: `Bearer ${token}`,
	// 					},
	// 				}
	// 			);
	// 			setCompany(response.data);
	// 			setPrevFilterOption(filterOption);
	// 			console.log("Employee data:", response.data);
	// 		} catch (error) {
	// 			setFetchCompanyError(error?.response?.data[0]);
	// 			console.error("Error fetching Companydata:", fetchCompanyError);
	// 		}
	// 	};
	// 	fetchCompanyByDate();
	// }, [dateFrom, dateTo, filterOption, searchParams]);

	const handleReport = async (value) => {
		console.log(value);
		setReportForm(value);
		setDateFrom(value.dateFrom);
		setDateTo(value.dateTo);
		const result = await axios
			.get(`${base_url}report/`, value)
			.then((response) => {
				console.log(response);
			})
			.catch((error) => {
				console.log(error);
				setError(error.message);
			});

		console.log(value);
	};

	const displayCompany =
		company?.results?.map((companyList, index) => ({
			url: companyList?.url,
			name: companyList?.name,
			phone: companyList?.phone,
			tin_value: companyList?.tin_value,
			license_number: companyList?.license_number,
			created: companyList?.created_at,
			updated: companyList?.updated_at,
			status: companyList?.status,
		})) || null;

	const displayEmployee =
		employee?.results?.map((employeeeList, index) => ({
			url: employeeeList?.url,
			name: `${employeeeList?.first_name} ${
				employeeeList?.last_name ? employeeeList?.last_name : ""
			}`,
			phone: employeeeList?.phone,
			position: employeeeList?.position,
			gross_salary: employeeeList?.gross_salary,
			created: employeeeList?.created_at,
			updated: employeeeList?.updated_at,
			status: employeeeList?.status,
		})) || null;

	const goNext = () => {
		if (searchParams.first + searchParams.rows < company.count) {
			setSearchParams({
				...searchParams,
				first: searchParams.first + searchParams.rows,
			});
		}
	};

	const goBack = () => {
		if (searchParams.first > 0) {
			setSearchParams({
				...searchParams,
				first: searchParams.first - searchParams.rows,
			});
		}
	};

	const goNextEmployee = () => {
		if (searchParams.first + searchParams.rows < employee.count) {
			setSearchParams({
				...searchParams,
				first: searchParams.first + searchParams.rows,
			});
		}
	};

	const goBackEmployee = () => {
		if (searchParams.first > 0) {
			setSearchParams({
				...searchParams,
				first: searchParams.first - searchParams.rows,
			});
		}
	};

	const includeColumns = [
		"name",
		"phone",
		"tin",
		"account",
		"paid_up_capital",
		"Region",
		"zone",
		"license_number",
		"payday",
		"renewal_status",
		"date_registered",
	];

	const exportToCSV = () => {
		const data = company?.results?.map((companyList) => ({
			name: companyList?.name,
			phone: companyList?.phone,
			tin: companyList?.tin_value,
			account: companyList?.account,
			paid_up_capital: companyList?.paid_up_capital,
			Region: companyList?.region,
			zone: companyList?.zone,
			license_number: companyList?.license_number,
			payday: moment(companyList?.payday).format("YYYY-MM-DD"),
			renewal_status: companyList?.renewal_status,
			status: companyList?.status,
			updated_at: moment(companyList?.updated_at).format("YYYY-MM-DD"),
			date_registered: moment(companyList?.date_registered).format(
				"YYYY-MM-DD"
			),
		}));
		const csvData = data.map((row) =>
			includeColumns.map((column) => row[column]).join(",")
		);
		csvData.unshift(includeColumns.join(","));
		const csvString = csvData.join("\n");
		const a = document.createElement("a");
		a.href = "data:attachment/csv," + csvString;
		a.target = "_blank";
		a.download = "report.csv";
		document.body.appendChild(a);
		a.click();
	};
	return (
		<div className="relative  bg-white">
			<div className="  ">
				<div className=" flex flex-col item-center shadow-kacha-box-shadow items-center  justify-center p-2">
					<img src={peaceLogo} alt="logo" className="h-14 pb-2" />
					<h1 className="text-center font-montserrata text-kacha-text3">
						Report
					</h1>

					<Formik
						initialValues={report}
						validationSchema={registorEmployee}
						onSubmit={(values) => {
							handleReport(values);
						}}
					>
						{(formik) => (
							<Form className="flex flex-col items-left justify-start">
								<div className="mt-5 flex flex-row">
									{/* <div className="flex flex-col">
										<label className="mb-1 text-kacha-text9">Date from:</label>
										<Field
											className="border border-gray-300 p-3 min-w-[300px] rounded-md text-kacha-text9"
											name="dateFrom"
											// as="input"
											type="date"

											// value={report.dateFrom}
											// onChange= {(e) =>  setDateFrom(e.target.value)}
										/>
										{formik.errors.dateFrom && formik.touched.dateFrom && (
											<div className="error text-red-500">
												{formik.errors.dateFrom}
											</div>
										)}
									</div>

									<div className="ml-5 flex flex-col pr-4">
										<label className="mb-1 text-kacha-text9">Date To:</label>
										<Field
											className="border border-gray-300 p-3 min-w-[300px] rounded-md text-kacha-text9"
											name="dateTo"

											type="date"
											// onChange= {(e) => setDateTo(e.target.value)}
										/>
										{formik.errors.dateTo && formik.touched.dateTo && (
											<div className="error text-red-500">
												{formik.errors.dateTo}
											</div>
										)}
									</div> */}
									<div className="flex flex-row justify-center items-center">
										<div className="flex flex-col">
											<label className="mb-1 text-kacha-text9">
												Date from:
											</label>
											<input
												max={currentDate}
												type="date"
												onChange={(e) => setDateFrom(e.target.value)}
												className="border border-gray-300 p-2 rounded-md mr-5 w-64"
											/>
										</div>
										<div className="flex flex-col">
											<label className="mb-1 text-kacha-text9">Date To:</label>
											<input
												max={currentDate}
												type="date"
												onChange={(e) => setDateTo(e.target.value)}
												className="border border-gray-300 p-2 rounded-md w-64"
											/>
										</div>
									</div>

									<div className="mr-5" />
									<div className="mt-s flex flex-col items-center justify-end mr-8 ">
										<label className="text-gray-600 mr-2">Report Type:</label>
										<select
											className="p-2 border border-gray-300 rounded-md w-60 h-12"
											value={filterOption}
											onChange={(e) => {
												setFilterOption(e.target.value);
												if (e.target.value === "ALL_COMPANY") {
													setFilterOptionCompany("");
													setFilterOptionEmployee("X");
												}
												if (e.target.value === "PENDING_COMPANY") {
													setFilterOptionCompany("PENDING");
													setFilterOptionEmployee("X");
												}
												if (e.target.value === "ACTIVE_COMPANY") {
													setFilterOptionCompany("ACTIVE");
													setFilterOptionEmployee("X");
												}
												if (e.target.value === "DISAPPROVED_COMPANY") {
													setFilterOptionCompany("DISAPPROVED");
													setFilterOptionEmployee("X");
												}
												if (e.target.value === "SUSPENDED_COMPANY") {
													setFilterOptionCompany("SUSPENDED");
													setFilterOptionEmployee("X");
												}
												if (e.target.value === "INACTIVE_COMPANY") {
													setFilterOptionCompany("INACTIVE");
													setFilterOptionEmployee("X");
												}
												if (e.target.value === "ALL_EMPLOYEE") {
													setFilterOptionEmployee("");
													setFilterOptionCompany("X");
												}
												if (e.target.value === "PENDING_EMPLOYEE") {
													setFilterOptionEmployee("PENDING");
													setFilterOptionCompany("X");
												}
												if (e.target.value === "ACTIVE_EMPLOYEE") {
													setFilterOptionEmployee("ACTIVE");
													setFilterOptionCompany("X");
												}
												if (e.target.value === "DISAPPROVED_EMPLOYEE") {
													setFilterOptionEmployee("DISAPPROVED");
													setFilterOptionCompany("X");
												}
												if (e.target.value === "INACTIVE_EMPLOYEE") {
													setFilterOptionEmployee("INACTIVE");
													setFilterOptionCompany("X");
												}

												if (e.target.value === "SUSPENDED_EMPLOYEE") {
													setFilterOptionEmployee("SUSPENDED");
													setFilterOptionCompany("X");
												}
											}}
										>
											<option value="ALL_COMPANY">
												Total Registered Companies
											</option>
											<option value="ALL_EMPLOYEE">
												Total Registered Employees
											</option>
											<option value="PENDING_COMPANY">Pending Companies</option>
											<option value="PENDING_EMPLOYEE">
												Pending Employees
											</option>
											<option value="ACTIVE_COMPANY">Active Companies</option>
											<option value="DISAPPROVED_COMPANY">
												Declined Companies
											</option>
											<option value="ACTIVE_EMPLOYEE">Active Employees</option>
											<option value="DISAPPROVED_EMPLOYEE">
												Declined Employees
											</option>
											<option value="SUSPENDED_EMPLOYEE">
												Suspended Employees
											</option>
											<option value="SUSPENDED_COMPANY">
												Suspended Companies
											</option>
											<option value="INACTIVE_EMPLOYEE">
												Inactive Employees
											</option>
											<option value="INACTIVE_COMPANY">
												Inactive Companies
											</option>
										</select>
									</div>
								</div>

								<div className="flex ">
									{/* <div className="mt-5 ml-5 flex  items-start">
										<label className="mb-1 text-kacha-text9">
											Report type:
										</label>
										<Field
											className="border border-gray-300 p-3 min-w-[255px] rounded-md text-kacha-text9"
											name="reportType"
											as="input"
											type="text"
											placeholder="Report type"
										/>
										{formik.errors.reportType && formik.touched.reportType && (
											<div className="error text-red-500">
												{formik.errors.reportType}
											</div>
										)}
									</div> */}

									{error && (
										<div className="mt-5 text-red-500 text-center">
											Please Try Again. {error} !!
										</div>
									)}
									<div className="mt-5" />

									{/* <div className="mt-5 mr-5 flex justify-center">
										<button
											className="bg-kacha-orange-700 text-kacha-text8 min-w-[300px] text-white p-3 rounded-md cursor-pointer"
											type="submit"
										>
											Search
										</button>
									</div> */}
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
			{loading && <Loader />}
			<div className="min-h-[50vh] flex pt-2 flex-col justify-center items-center">
				{(filterOption === "ALL_COMPANY" ||
					filterOption === "PENDING_COMPANY" ||
					filterOption === "ACTIVE_COMPANY" ||
					filterOption === "DISAPPROVED_COMPANY" ||
					filterOption === "INACTIVE_COMPANY" ||
					filterOption === "SUSPENDED_COMPANY") &&
					(fetchCompanyError ? (
						<div>Error fetching company data. Please try Agian.</div>
					) : company?.count === 0 ? (
						<div className="flex flex-col justify-center items-center">
							<div className="flex flex-col justify-center items-center">
								<ExclamationCircleIcon className="h-10 w-10 text-orange-500" />
								<div className="text-center text-2xl text-kacha-orange-700">
									No{" "}
									{filterOption
										.split("_")
										.map(
											(word) =>
												word?.charAt(0)?.toUpperCase() +
												word?.slice(1)?.toLowerCase() +
												" "
										)}{" "}
									Found
								</div>
							</div>
						</div>
					) : (
						<>
							<div className="overflow-x-auto w-full py-8 px-2">
								<div className="flex items-center justify-end mr-8">
									<div className="mb-4 flex items-center justify-end mr-8">
										<input
											className="border border-gray-300 p-2 rounded-md"
											type="text"
											placeholder="Search..."
											value={searchResult}
											onChange={(e) => setSearchResult(e.target.value)}
										/>
									</div>
									<div className="flex items-center justify-end mr-8">
										<button
											className="bg-kacha-orange-700 text-kacha-text8 min-w-[100px] text-white p-3 rounded-md cursor-pointer flex items-center"
											onClick={exportToCSV}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												className="w-6 h-6 mr-2" // Adjust the size as needed
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M5 10l7-7m0 0l7 7m-7-7v18"
												/>
											</svg>
											Export to CSV
										</button>
									</div>
								</div>

								<table className="min-w-full  border-gray-300">
									<thead>
										<tr className="text-left">
											<th className="py-2 px-4 border-b">No</th>
											<th className="py-2 px-4 border-b">Name</th>
											<th className="py-2 px-4 border-b">Phone</th>
											<th className="py-2 px-4 border-b">TIN</th>
											<th className="py-2 px-4 border-b">
												business License No.
											</th>
											<th className="py-2 px-4 border-b">Last Update Date </th>
											<th className="py-2 px-4 border-b">Status</th>
										</tr>
									</thead>
									<tbody className="align-center">
										{/*{
											displayCompany?.filter((companyX) => companyX.created_at >= report.dateFrom && companyX.created <= report.dateTo).map((companyInfo, index) => (
												<tr
													key={index}
													className={index % 2 === 0 ? "bg-gray-100" : ""}
												>
													<td className=" py-2 px-4 border-b">{companyInfo.name}</td>
													<td className="py-2 px-4 border-b">{companyInfo.phone}</td>
													<td className="py-2 px-4 border-b">
														{companyInfo.tin_value}
													</td>
													<td className="py-2 px-4 border-b">
														{companyInfo.created
															? moment(companyInfo.created).format("YYYY-MM-DD")
															: ""}
													</td>
													</tr>
											))
										}
									</tbody>
								</table> */}

										{displayCompany?.map((companyInfo, index) => (
											<tr
												key={index}
												className={index % 2 === 0 ? "bg-gray-100" : ""}
											>
												<td className=" py-2 px-4 border-b">
													{searchParams.first + index + 1}
												</td>
												<td className=" py-2 px-4 border-b">
													{companyInfo.name}
												</td>
												<td className="py-2 px-4 border-b">
													+{companyInfo.phone}
												</td>
												<td className="py-2 px-4 border-b">
													{companyInfo.tin_value}
												</td>
												<td className="py-2 px-4 border-b">
													{" "}
													{companyInfo.license_number}
												</td>
												<td className="py-2 px-4 border-b">
													{companyInfo.updated
														? moment(companyInfo.updated).format("YYYY-MM-DD")
														: ""}
												</td>
												<td className="py-2 px-4 border-b">
													{" "}
													{companyInfo.status &&
														(companyInfo.status === "DISAPPROVED"
															? "Declined"
															: companyInfo?.status.charAt(0).toUpperCase() +
															  companyInfo?.status.slice(1).toLowerCase())}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<div className="flex items-center  justify-between w-md">
								<div className="flex items-center space-x-2">
									<span className="text-gray-600">Items per page: </span>
									<div className="relative inline-block">
										<select
											className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:bg-white"
											value={searchParams.rows}
											onChange={(e) => {
												searchParams.rows = Number(e.target.value);
												setSearchParams({ ...searchParams });
											}}
										>
											<option value="10"> 10</option>
											<option value="20">20</option>
											<option value="50">50</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M10 12.5l-5-5 1.5-1.5L10 9.5l3.5-3.5L15 7z" />
											</svg>
										</div>
									</div>
								</div>
								<span className="text-gray-600 px-10">
									{searchParams.first + 1} -{" "}
									{searchParams.first + searchParams.rows} of{" "}
									{company?.count || 0}
								</span>

								<div className="flex items-center space-x-4">
									<div>
										<CiCircleChevLeft
											className={`${
												searchParams.first === 0
													? "opacity-50 cursor-not-allowed"
													: "cursor-pointer"
											}  h-8 w-8 text-orange-700 font-bold`}
											size={30}
											onClick={goBack}
										/>
										{/* <svg
											xmlns="http://www.w3.org/2000/svg"
											className={`${
												searchParams.first === 0
													? "opacity-50 cursor-not-allowed"
													: ""
											}  h-8 w-8 text-orange-700 font-bold`}
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M15 19l-7-7 7-7"
											/>
										</svg> */}{" "}
									</div>
									<div>
										<CiCircleChevRight
											className={`${
												searchParams.first + searchParams.rows >= company?.count
													? "opacity-50 cursor-not-allowed"
													: "cursor-pointer"
											}  h-8 w-8 text-orange-700 font-bold`}
											size={30}
											onClick={goNext}
										/>
										{/* <svg
											xmlns="http://www.w3.org/2000/svg"
											className={`${
												searchParams.first + searchParams.rows >= company?.count
													? "opacity-50 cursor-not-allowed"
													: ""
											}  h-8 w-8  text-orange-700  font-bold`}
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M9 5l7 7-7 7"
											/>
										</svg> */}
									</div>
								</div>
							</div>
						</>
					))}
				{(filterOption === "ALL_EMPLOYEE" ||
					filterOption === "PENDING_EMPLOYEE" ||
					filterOption === "ACTIVE_EMPLOYEE" ||
					filterOption === "DISAPPROVED_EMPLOYEE" ||
					filterOption === "INACTIVE_EMPLOYEE" ||
					filterOption === "INACTIVE_EMPLOYEE" ||
					filterOption === "SUSPENDED_EMPLOYEE") &&
					(fetchEmployeeError ? (
						<div>Error fetching employee data. Please try Agian.</div>
					) : employee?.count === 0 ? (
						<div className="flex flex-col justify-center items-center">
							<div className="flex flex-col justify-center items-center">
								<ExclamationCircleIcon className="h-10 w-10 text-orange-500" />
								<div className="text-center text-2xl text-kacha-orange-700">
									No{" "}
									{filterOption
										.split("_")
										.map(
											(word) =>
												word?.charAt(0)?.toUpperCase() +
												word?.slice(1)?.toLowerCase() +
												" "
										)}{" "}
									Found
								</div>
							</div>
						</div>
					) : (
						<>
							<div className="overflow-x-auto w-full py-8 px-2">
								<div className="flex items-center justify-end mr-8">
									<div className="mb-4 flex items-center justify-end mr-8">
										<input
											className="border border-gray-300 p-2 rounded-md"
											type="text"
											placeholder="Search..."
											value={searchResult}
											onChange={(e) => setSearchResult(e.target.value)}
										/>
									</div>
									<div className="flex items-center justify-end mr-8">
										<button
											className="bg-kacha-orange-700 text-kacha-text8 min-w-[100px] text-white p-3 rounded-md cursor-pointer flex items-center"
											onClick={exportToCSV}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												className="w-6 h-6 mr-2" // Adjust the size as needed
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M5 10l7-7m0 0l7 7m-7-7v18"
												/>
											</svg>
											Export to CSV
										</button>
									</div>
								</div>
								<table className="min-w-full  border-gray-300">
									<thead>
										<tr className="text-left">
											<th className="py-2 px-4 border-b">No</th>
											<th className="py-2 px-4 border-b">Name</th>
											<th className="py-2 px-4 border-b">Phone</th>
											<th className="py-2 px-4 border-b">Position</th>
											<th className="py-2 px-4 border-b">Gross Salary</th>
											<th className="py-2 px-4 border-b">Last Update Date</th>
											<th className="py-2 px-4 border-b">Status</th>
										</tr>
									</thead>
									<tbody className="align-center">
										{displayEmployee?.map((employeeInfo, index) => (
											<tr
												key={index}
												className={index % 2 === 0 ? "bg-gray-100" : ""}
											>
												<td className=" py-2 px-4 border-b">
													{searchParams.first + index + 1}
												</td>
												<td className=" py-2 px-4 border-b">
													{employeeInfo.name
														.split(" ")
														.map(
															(word) =>
																word?.charAt(0)?.toUpperCase() +
																word?.slice(1)?.toLowerCase()
														)
														.join(" ")}
												</td>
												<td className="py-2 px-4 border-b">
													+{employeeInfo.phone}
												</td>

												<td className="py-2 px-4 border-b">
													{
														employeeInfo.position &&
															employeeInfo?.position
																.split(" ")
																.map(
																	(word) =>
																		word?.charAt(0)?.toUpperCase() +
																		word?.slice(1)?.toLowerCase()
																)
																.join(" ")
														// employeeInfo.position.charAt(0).toUpperCase() + employeeInfo.position.slice(1).toLowerCase()
													}
												</td>
												<td className="py-2 px-4 border-b">
													{Number(employeeInfo.gross_salary).toLocaleString(
														"en-US",
														{ style: "currency", currency: "ETB" }
													)}
												</td>
												<td className="py-2 px-4 border-b">
													{employeeInfo.updated
														? moment(employeeInfo.updated).format("YYYY-MM-DD")
														: ""}
												</td>
												<td className="py-2 px-4 border-b">
													{employeeInfo.status &&
														(employeeInfo?.status === "DISAPPROVED"
															? "Declined"
															: employeeInfo?.status.charAt(0).toUpperCase() +
															  employeeInfo?.status.slice(1).toLowerCase())}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<div className="flex items-center  justify-between w-md">
								<div className="flex items-center space-x-2">
									<span className="text-gray-600">Items per page: </span>
									<div className="relative inline-block">
										<select
											className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:bg-white"
											value={searchParams.rows}
											onChange={(e) => {
												searchParams.rows = Number(e.target.value);
												setSearchParams({ ...searchParams });
											}}
										>
											<option value="10"> 10</option>
											<option value="20">20</option>
											<option value="50">50</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M10 12.5l-5-5 1.5-1.5L10 9.5l3.5-3.5L15 7z" />
											</svg>
										</div>
									</div>
								</div>

								<span className="text-gray-600 px-10">
									{searchParams.first + 1} -{" "}
									{searchParams.first + searchParams.rows} of{" "}
									{employee?.count || 0}
								</span>

								<div className="flex items-center space-x-4">
									<div>
										<CiCircleChevLeft
											className={`${
												searchParams.first === 0
													? "opacity-50 cursor-not-allowed"
													: "cursor-pointer"
											}  h-8 w-8 text-orange-700 font-bold`}
											size={30}
											onClick={goBackEmployee}
										/>
										{/* <svg
											xmlns="http://www.w3.org/2000/svg"
											className={`${
												searchParams.first === 0
													? "opacity-50 cursor-not-allowed"
													: ""
											}  h-8 w-8 text-orange-700 font-bold`}
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M15 19l-7-7 7-7"
											/>
										</svg> */}{" "}
									</div>
									<div>
										<CiCircleChevRight
											className={`${
												searchParams.first + searchParams.rows >=
												employee?.count
													? "opacity-50 cursor-not-allowed"
													: "cursor-pointer"
											}  h-8 w-8 text-orange-700 font-bold`}
											size={30}
											onClick={goNextEmployee}
										/>
										{/* <svg
											xmlns="http://www.w3.org/2000/svg"
											className={`${
												searchParams.first + searchParams.rows >=
												employee?.count
													? "opacity-50 cursor-not-allowed"
													: ""
											}  h-8 w-8  text-orange-700  font-bold`}
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M9 5l7 7-7 7"
											/>
										</svg> */}
									</div>
								</div>
							</div>
						</>
					))}
			</div>
		</div>
	);
};

export default RegistorEmployee;
